<accessibility>
  <div if="{!sidebarIsHidden() && props.sidebarPosition !== 'hide'}">
    <div>
      <div class="accessibility-icon-container">
        <div
          class="tp-accessibility2"
          if="{props.accessibility_icon}"
          onclick="{openACC}"
        >
          <img src="{props.accessibility_icon}" />
        </div>
        <div class="tp-accessibility2-text">Accessibility</div>
        <div class="tp-accessibility2-actions">
          <img
            data-bs-toggle="modal"
            data-bs-target="#translateModal"
            class="access-video"
            src="{window.tamkin_src_base+'/images/access-video.svg'}"
            alt=""
          />
          <span class="mx-2"></span>
          <img
            class="tp-accessibility2-sidebar-open access-pointer"
            src="{window.tamkin_src_base+'/images/access-pointer.svg'}"
            alt=""
          />
        </div>
      </div>
    </div>

    <mini-widget-accessibility-sidebar
      accessibility_settings="{ props.accessibility_settings }"
    >
    </mini-widget-accessibility-sidebar>
    <div id="sidebody"></div>

    <translation></translation>

    <!-- page structure modal -->
    <div
      class="modal fade"
      id="pageStructureModal"
      tabindex="-1"
      aria-labelledby="pageStructureModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header tp-modal-header">
            <div class="pt-3" id="translationModalModalLabel">
              <p class="font-18 font-w600" style="color: white">
                Page Structure
              </p>
            </div>
            <button type="button" data-bs-dismiss="modal" aria-label="Close">
              <img
                src="{window.tamkin_src_base+'/images/icon-close-sidebar.svg'}"
                alt="Close"
              />
            </button>
          </div>
          <div class="py-3">
            <ul
              class="nav nav-tabs w-100 justify-content-around"
              id="pageStructureTabs"
              role="tablist"
            >
              <li class="nav-item col-4" role="presentation">
                <button
                  class="nav-link active"
                  id="headings-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#headings"
                  type="button"
                  role="tab"
                  aria-controls="headings"
                  aria-selected="true"
                >
                  Headings
                </button>
              </li>
              <li class="nav-item col-4" role="presentation">
                <button
                  class="nav-link"
                  id="landmarks-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#landmarks"
                  type="button"
                  role="tab"
                  aria-controls="landmarks"
                  aria-selected="false"
                >
                  Landmarks
                </button>
              </li>
              <li class="nav-item col-4" role="presentation">
                <button
                  class="nav-link"
                  id="links-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#links"
                  type="button"
                  role="tab"
                  aria-controls="links"
                  aria-selected="false"
                >
                  Links
                </button>
              </li>
            </ul>
          </div>
          <div class="modal-body tab-content" id="pageStructureTabContent">
            <div
              class="tab-pane fade show active"
              id="headings"
              role="tabpanel"
              aria-labelledby="headings-tab"
            >
              <ul class="list-unstyled page-structure-list" id="headingsList">
                <!-- Headings will be dynamically inserted here -->
              </ul>
            </div>
            <div
              class="tab-pane fade"
              id="landmarks"
              role="tabpanel"
              aria-labelledby="landmarks-tab"
            >
              <ul class="list-unstyled page-structure-list" id="landmarksList">
                <!-- Landmarks will be dynamically inserted here -->
              </ul>
            </div>
            <div
              class="tab-pane fade"
              id="links"
              role="tabpanel"
              aria-labelledby="links-tab"
            >
              <ul class="list-unstyled page-structure-list" id="linksList">
                <!-- Links will be dynamically inserted here -->
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- end page structure modal -->

    <!-- dictionary modal  -->
    <dictionary-modal></dictionary-modal>
  </div>

  <!-- <script src="./player_files/accessibility/accessibility.js"></script> -->
  <script>
    // this puts svg images inside file3D/images. see webpack.config.js
    const importAllImages = (requireContext) =>
      requireContext.keys().map(requireContext);
    const images = importAllImages(
      require.context(
        "/src/player_files/accessibility/img",
        false,
        /\.(png|jpe?g|svg)$/
      )
    );

    if (!customElements.get("lottie-player")) {
      import("@lottiefiles/lottie-player");
    }
    import loader from "/src/player_files/images/h.svg";
    import handload from "/src/player_files/json/handload.json";
    import hearts from "/src/player_files/json/hearts.json";
    import loading from "/src/player_files/json/loading.json";
    import twohand from "/src/player_files/json/twohand.json";

    import "/src/player_files/accessibility/bootstrap.min.js";
    // import "/src/player_files/accessibility/mini_widget/accessibility.js";
    import "/src/player_files/accessibility/mini_widget/accessibility.css";

    import DictionaryModal from "/src/accessibility/shared/dictionary-modal.riot";
    import TaminSDK from "/src/tamkin-sdk-only-player.riot";
    import AccessibilitySidebar from "/src/accessibility/mini_widget/accessibility-sidebar.riot";
    import Translation from "/src/accessibility/translation.riot";
    window.tamkin_src_base = "";
    // if (process.env.NODE_ENV === "production") {
    //     window.tamkin_src_base = "https://cdn.tamkin.app";
    // }
    import fileAudio from "/src/player_files/audio/open_audio.mp3";
    const sound = window.tamkin_src_base + fileAudio;

    export default {
      components: {
        "tamkin-sdk-only-player": TaminSDK,
        "mini-widget-accessibility-sidebar": AccessibilitySidebar,
        translation: Translation,
        "dictionary-modal": DictionaryModal,
      },
      state: {
        // accessibility_settings: {},
      },

      openACC() {
        $(".tp-accessibility2").css("display", "none");
        document
          .querySelector("#tamkinPlayerApp #tp-accessibility2-sidebar")
          .classList.add("active");
        const soundeffect = localStorage.getItem("enableSoundEffect");
        const audio = new Audio(sound);
        if (soundeffect == 1) {
          audio.play();
        }
      },

      onMounted(props, state) {
        document
          .querySelector("#tamkinPlayerApp .tp-accessibility2")
          .addEventListener("mouseenter", function () {
            if (!window.accessibility_actions_opened) {
              document
                .querySelector("#tamkinPlayerApp .tp-accessibility2-text")
                .classList.add("active");
            }
          });

        document
          .querySelector("#tamkinPlayerApp .tp-accessibility2")
          .addEventListener("mouseleave", function () {
            document
              .querySelector("#tamkinPlayerApp .tp-accessibility2-text")
              .classList.remove("active");
          });
      },
      onUpdated(props, state) {},
      sidebarIsHidden() {
        const hideUntil = localStorage.getItem("tpAccHideUntil");
        const hide = localStorage.getItem("tpSidebarPosition");
        if (hide === "hide") {
          return true;
        }

        if (!hideUntil) {
          return false;
        } else {
          if (new Date() < new Date(hideUntil)) {
            return true;
          } else {
            localStorage.removeItem("tpAccHideUntil");
            return false;
          }
        }
      },
    };
  </script>

  <style>
    /*
        some styles fot accessibility are in pricipalApp.riot because not working here any styles
        */
  </style>
</accessibility>
