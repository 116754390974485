<voice-navigation>
    <div id="voice-nav-modal">
        <div class="voice-nav-header d-flex align-items-center justify-content-between">
            <div class=" d-flex align-items-center justify-content-between" style="gap: 25px;">
                <button id="openlisten" onclick={initRec}>
                    <img width="40" height="40" src="{window.tamkin_src_base+'/images/mic.svg'}" alt="Close">
                </button>
                <p id="titleModal" class="titleModal {currentLang== 'ar'? '' : 'notranslate'}">
                    <span>{state.listening ? 'Tamkin is listening' : 'Tamkin is paused'}</span>
                </p>

            </div>
            <p id="lisetning_text" class="{currentLang== 'ar'? '' : 'notranslate'}" style="display: none;">listening</p>


            <div class=" d-flex justify-content-between align-items-center" style="gap: 15px;">
                <p if="{state.text === 'Tamkin is paused' && state.paused == 1}"
                    class="{currentLang== 'ar'? '' : 'notranslate'}" id="pleasestart">Say
                    <b class="">"Start"</b>
                    or click on microphone
                    to continue!
                </p>


                <svg if="{state.showDetails==false}" onclick="{updateShowDetails}" xmlns="http://www.w3.org/2000/svg"
                    width="32" height="32" viewBox="0 0 32 32" fill="none">
                    <g clip-path="url(#clip0_3_9)">
                        <path
                            d="M31.5 16C31.5 7.43959 24.5604 0.5 16 0.5C7.43959 0.5 0.5 7.43959 0.5 16C0.5 24.5604 7.43959 31.5 16 31.5C24.5604 31.5 31.5 24.5604 31.5 16Z"
                            fill="var(--tp-primary)" stroke="#E0E5F3" />
                        <path
                            d="M14.8359 8.85434C14.8359 8.3825 15.2184 8 15.6903 8C16.1621 8 16.5446 8.3825 16.5446 8.85434V23.1457C16.5446 23.6175 16.1621 24 15.6903 24C15.2184 24 14.8359 23.6175 14.8359 23.1457V8.85434Z"
                            fill="white" />
                        <path
                            d="M8 16.0002C8 15.5093 8.39797 15.1113 8.88889 15.1113H22.4892C22.9801 15.1113 23.3781 15.5093 23.3781 16.0002C23.3781 16.4911 22.9801 16.8891 22.4892 16.8891H8.88889C8.39797 16.8891 8 16.4911 8 16.0002Z"
                            fill="white" />
                    </g>
                    <defs>
                        <clipPath id="clip0_3_9">
                            <rect width="32" height="32" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
                <svg onclick="{updateHideDetails}" if="{state.showDetails==true}" xmlns="http://www.w3.org/2000/svg"
                    width="32" height="32" viewBox="0 0 32 32" fill="none">
                    <g clip-path="url(#clip0_4_13)">
                        <path
                            d="M31.5 16C31.5 7.43959 24.5604 0.5 16 0.5C7.43959 0.5 0.5 7.43959 0.5 16C0.5 24.5604 7.43959 31.5 16 31.5C24.5604 31.5 31.5 24.5604 31.5 16Z"
                            fill="var(--tp-primary)" stroke="#E0E5F3" />
                        <path
                            d="M8 16.0002C8 15.5093 8.39797 15.1113 8.88889 15.1113H22.4892C22.9801 15.1113 23.3781 15.5093 23.3781 16.0002C23.3781 16.4911 22.9801 16.8891 22.4892 16.8891H8.88889C8.39797 16.8891 8 16.4911 8 16.0002Z"
                            fill="white" />
                    </g>
                    <defs>
                        <clipPath id="clip0_4_13">
                            <rect width="32" height="32" fill="white" />
                        </clipPath>
                    </defs>
                </svg>

                <img src="{window.tamkin_src_base+'/images/closebtn.svg'}" onclick="{closeVoiceNavigation}" alt="Close">
            </div>
        </div>
        <hr if="{state.showDetails==true}" style="border: 1px solid #A6A6A6;" />
        <div class="loading {currentLang== 'ar'? '' : 'notranslate'}" id="loading">
            <div class="tp-spinner"></div>
            <h3>Searching </h3>
            <p>Please wait while we retrieve the definition for you...</p>
        </div>
        <div class="voice-nav-body notranslate" if="{state.showDetails==true}">
            <div each="{command in state.commands}" class="command-item" key="{command.title}">
                <div>
                    <span class="title-bold">{command.description}</span>

                </div>
                <div style="display: flex;gap: 10px;flex-wrap: wrap;margin: 10px 0px;">
                    <div class="command-item" each="{item in command.words_list}">{item.word}</div>
                </div>

            </div>
        </div>
    </div>


    <script>
        const importAllImages = (requireContext) => requireContext.keys().map(requireContext);
        const images = importAllImages(require.context('/src/player_files/accessibility/img', false, /\.(png|jpe?g|svg)$/));

        export default {
            state: {
                text: "",
                listening: false,
                showDetails: false,
                commands: [],
                recognition: !navigator.userAgent.includes("Firefox") ? new webkitSpeechRecognition() : null,
                getstart: 0,
                paused: 0,
                currentLang: localStorage.getItem('lang')
            },
            onMounted(state, props) {
                $('#voice-nav-modal #loading').hide();
                window.closeVoiceNav = this.closeVoiceNavigation.bind(this);
                window.showNumbers = this.showNumbers.bind(this);
            },



            initRec() {
                if (this.state.recognition) {
                    this.state.text = 'Tamkin is listening';
                    document.getElementById("titleModal").innerText = 'Tamkin is listening';
                    this.state.paused = 0;
                    this.state.listening = !this.state.listening;

                    if (this.state.getstart == 0) {
                        this.state.recognition.start();
                    }

                    this.update();
                    if (this.state.listening) {
                        const textSpeech = $("#lisetning_text").text();
                        const speech = new SpeechSynthesisUtterance(textSpeech);
                        speech.lang = `${localStorage.getItem("lang")}-EG`;
                        speech.rate = 1.0;
                        window.speechSynthesis.speak(speech);

                        speech.onend = () => {
                            window.speechSynthesis.cancel();
                        };

                        this.state.getstart = 1;
                        this.showNumbers();

                        this.state.recognition.lang = `${localStorage.getItem("lang")}-EG`;
                        this.state.recognition.interimResults = true;

                        this.state.recognition.onresult = (event) => {
                            const transcript = event.results[event.results.length - 1][0].transcript;
                            this.state.text = transcript;
                        };

                        this.state.recognition.onerror = (event) => {
                            console.error("Error:", event.error);
                            if (event.error === 'no-speech') {
                                this.state.listening = false;
                                this.state.paused = 1;
                                this.state.text = 'Tamkin is paused';
                                document.getElementById("titleModal").innerText = 'Tamkin is paused';

                                $("#openlisten").removeClass("active");
                                this.update();
                            }

                        };

                        this.state.recognition.onend = () => {
                            if (this.state.text && this.state.listening) {
                                if (document.getElementById("titleModal").innerText !== this.state.text) {
                                    if (this.state.paused == 0) {
                                        console.log("another");
                                        document.getElementById("titleModal").innerText = this.state.text;
                                        this.handleCommand(this.state.text);
                                    }
                                }
                                this.state.recognition.start();
                            } else {
                                console.log("elsee");
                                this.handleStartCommand(this.state.text)
                            }

                        };
                        $("#openlisten").addClass("active");
                    } else {
                        this.stopVoiceNavigation();
                    }
                }
            },
            handleStartCommand(command) {
                if (command.toLowerCase() === 'start' || command.toLowerCase() === 'ابدا' || command.toLowerCase() === 'أبدأ'
                    || command.toLowerCase() === 'ابدأ' || command.toLowerCase() === 'يبدأ' || command.toLowerCase() === 'يبدا') {
                    this.startVoiceNavigation();
                    this.state.recognition.start();
                }
            },
            handleCommand(command) {
                if (command === "help me" || command === "show commands" || command === "please help" ||
                    command === "available commands" || command === "list commands" || command === "help"
                    || command === "ساعدني" || command === "ساعدني من فضلك" || command === "اظهر الاوامر" || command === "الاوامر المتاحة"
                    || command === "قائمة الاوامر" || command === "مساعدة" || command === "الاوامر المتاحه"
                    || command === "قائمه الاوامر" || command === "مساعده" || command === "أظهر الاوامر"
                ) {
                    this.state.showDetails = true;
                    this.update();
                    this.getCommands();
                } else if (command === "hide help" || command === "hide commands" || command === "اخفاء المساعدة"
                    || command === "اخفاء المساعده" || command === "اخفاء الاوامر" || command === "إخفاء الاوامر"
                    || command === "إخفاء المساعده"
                ) {
                    this.state.showDetails = false;
                    this.update();
                }
                else if (command === "clear input" || command === "erase" ||
                    command === "delete" || command === "remove" ||
                    command === "حذف" || command === "مسح المدخلات" ||
                    command === "مسح" || command === "أزل" ||
                    command === "ازل" || command === "إزل"
                ) {
                    document.querySelectorAll("input").forEach((el) => {
                        el.value = ""
                    })
                } else if (command === "refresh page" || command === "reload page" ||
                    command === "اعد تحميل الصفحة" || command === "اعد تحميل الصفحه" ||
                    command === "أعد تحميل الصفحه" || command === "أعد تحميل الصفحة" ||
                    command === "تحديث الصفحة" || command === "تحديث الصفحه"

                ) {
                    window.location.reload();
                } else if (command === "scroll to top" || command === "go to top" || command === "اذهب الى الاعلى"
                    || command === "إذهب الى الاعلى" || command === "اذهب الى الأعلي" || command === "أذهب الى الأعلي"
                    || command === "أذهب الى الاعلى" || command === "انتقل إلى الأعلى"
                    || command === "أنتقل إلى الأعلى" || command === "إنتقل إلى الأعلى" || command === "انتقل إلى الاعلي"
                ) {
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    });
                } else if (command === "scroll to bottom" || command === "go to bottom" || command === "اذهب الى الأسفل"
                    || command === "إذهب الى الاسفل" || command === "اذهب الى الاسفل" || command === "أذهب الى الأسفل"
                    || command === "أذهب الى الاسفل" || command === "انتقل إلى الأسفل"
                    || command === "أنتقل إلى الأسفل" || command === "إنتقل إلى الأسفل" || command === "انتقل إلى الاسفل") {
                    window.scrollTo({
                        top: document.body.scrollHeight,
                        behavior: 'smooth'
                    });
                }
                else if (command === "scroll down" || command === "down" ||
                    command === "page down" || command === "go down" || command === "اسفل"
                    || command === "أسفل" || command === "إسفل" || command === "انتقل إلى الأسفل"
                    || command === "إنتقل إلى الأسفل" || command === "أنتقل إلى الأسفل" || command === "انتقل ال الاسفل" ||
                    command === "الصفحة لأسفل" || command === "الصفحه لأسفل" || command === "الصفحة لاسفل" || command === "انزل" ||
                    command === "أنزل" || command === "إنزل"
                ) {
                    window.scrollBy({
                        top: 150,
                        behavior: 'smooth'
                    });
                }
                else if (command === "scroll up" || command === "up" || command === "page up" ||
                    command === "go up" || command === "اعلى" || command === "اعلي" || command === "أعلي" || command === "انتقل إلى الأعلى"
                    || command === "أنتقل إلى الأعلى" || command === "إنتقل إلى الأعلى" || command === "انتقل إلى الاعلي" ||
                    command === "الصفحة لأعلى" || command === "الصفحه لاعلي" || command === "الصفحه لأعلى"
                    || command === "إصعد" || command === "اصعد" || command === "أصعد"
                ) {
                    window.scrollBy({
                        top: -150,
                        behavior: 'smooth'
                    });
                } else if (command === 'stop voice navigation' || command === 'stop' ||
                    command === "إيقاف التوجية الصوتي" || command === "ايقاف التوجية الصوتي" ||
                    command === "أيقاف التوجية الصوتي" || command === "إيقاف التوجية الصوتى" ||
                    command === "إيقاف التوجيه الصوتي" || command === "ايقاف التوجيه الصوتي" ||
                    command === "ايقاف التوجيه الصوتى" || command === 'ايقاف' || command === 'إيقاف'
                    || command === 'أيقاف'
                ) {
                    this.stopVoiceNavigation();

                } else if (command === 'hide widget' || command === 'close widget' ||
                    command === "اغلاق الاداة" || command === "إغلاق الاداة" ||
                    command === "اغلاق الاداه" || command === "إغلاق الاداه" ||
                    command === "اخفاء الاداة" || command === "إخفاء الاداة" ||
                    command === "اخفاء الاداه" || command === "إخفاء الاداه"
                ) {
                    $("#tp-accessibility-sidebar").removeClass("active")
                } else if (command === 'move up' || command === 'up' ||
                    command === 'تحريك الي الاعلي' || command === 'تحريك إلى الاعلي' ||
                    command === 'تحريك الي الأعلى' || command === 'تحريك ألي الاعلي' ||
                    command === 'تحريك إلى الاعلي' || command === 'اعلى' ||
                    command === 'اعلي' || command === 'أعلى' || command === 'أعلي'
                ) {
                    window.scrollBy({
                        top: -150,
                        behavior: 'smooth'
                    });
                } else if (command === 'move down' || command === 'down' ||
                    command === 'تحريك الي الاسفل' || command === 'تحريك إلى الاسفل' ||
                    command === 'تحريك الي الأسفل' || command === 'تحريك ألي الاسفل' ||
                    command === 'تحريك إلي الاسفل' || command === 'اسفل' ||
                    command === 'أسفل'
                ) {
                    window.scrollBy({
                        top: 150,
                        behavior: 'smooth'
                    });
                } else if (command === 'move left' || command === 'left' ||
                    command === 'يسار' || command === "تحريك الي اليسار" ||
                    command === "تحريك إلى اليسار" || command === "تحريك إلي اليسار" ||
                    command === "تحريك الى اليسار"
                ) {
                    window.scrollBy({
                        left: -150,
                        behavior: 'smooth'
                    });
                } else if (command === 'move right' || command === 'right' ||
                    command === 'يمين' || command === "تحريك الي اليمين" ||
                    command === "تحريك إلى اليمين" || command === "تحريك إلي اليمين" ||
                    command === "تحريك الى اليمين"
                ) {
                    window.scrollBy({
                        left: 150,
                        behavior: 'smooth'
                    });
                } else if (command === 'exit' || command === 'خروج') {
                    this.closeVoiceNavigation()
                } else if (command === 'show numbers' || command === 'display numbers' || command === 'إظهار الأرقام'
                    || command === 'اظهار الأرقام' || command === 'إظهار الارقام' || command === 'اظهار الارقام'
                    || command === 'عرض الأرقام' || command === 'عرض الارقام'
                ) {
                    this.showNumbers()
                } else if (command === 'hide numbers' || command === "إخفاء الأرقام" ||
                    command === 'اخفاء الأرقام' || command === 'اخفاء الارقام' ||
                    command === 'أخفاء الارقام' || command === 'إخفاء الارقام'
                ) {
                    this.hideNumnbers()
                } else if ((command.toLowerCase().startsWith('click') || command.toLowerCase().startsWith('go')
                    || command.toLowerCase().startsWith('press') || command.toLowerCase().startsWith('open')
                    && /\d/.test(command)) || (/[\d\u0660-\u0669]/.test(command) && command.startsWith('انقر') || command.startsWith('أنقر')
                        || command.startsWith('إنقر') || command.startsWith('اذهب') || command.startsWith('إذهب')
                        || command.startsWith('أذهب') || command.startsWith('افتح') || command.startsWith('إفتح')
                        || command.startsWith('أفتح') || command.startsWith('اضغط') || command.startsWith('إضغط')
                        || command.startsWith('أضغط')
                    )) {
                    const links = document.querySelectorAll('a:not(#tamkinPlayerApp *), button:not(#tamkinPlayerApp *), input[type="submit"]:not(#tamkinPlayerApp *)');
                    links.forEach((link, index) => {
                        link.setAttribute('data-index', index + 1);
                    });

                    const number = parseInt(command.split(" ")[1], 10);

                    if (!isNaN(number)) {
                        const element = document.querySelector(`[data-index="${number}"]`);

                        if (element) {
                            element.click();
                        } else {
                            console.log(`No element found with index ${number}`);
                        }
                    }
                }
                else if ((command.toLowerCase().startsWith('select') || command.toLowerCase().startsWith('focus') ||
                    command.toLowerCase().startsWith('show') && /\d/.test(command)) || (/[\d\u0660-\u0669]/.test(command) && command.startsWith('حدد') || command.startsWith('تركيز')
                        || command.startsWith('إظهر') || command.startsWith('أظهر') || command.startsWith('اظهر')
                    )) {
                    const links = document.querySelectorAll('a:not(#tamkinPlayerApp *), button:not(#tamkinPlayerApp *), input[type="submit"]:not(#tamkinPlayerApp *)');
                    links.forEach((link, index) => {
                        link.setAttribute('data-index', index + 1);
                    });

                    const number = parseInt(command.split(" ")[1], 10);

                    if (!isNaN(number)) {
                        const element = document.querySelector(`[data-index="${number}"]`);

                        if (element) {
                            element.focus();
                        } else {
                            console.log(`No element found with index ${number}`);
                        }
                    }
                } else if (/\d /.test(command) || /[\d\u0660-\u0669]/.test(command)) {
                    const links = document.querySelectorAll('a:not(#tamkinPlayerApp *), button:not(#tamkinPlayerApp *), input[type="submit"]:not(#tamkinPlayerApp *)');
                    links.forEach((link, index) => {
                        link.setAttribute('data-index', index + 1);
                    });

                    const number = parseInt(command, 10);

                    if (!isNaN(number)) {
                        const element = document.querySelector(`[data-index="${number}"]`);

                        if (element) {
                            element.click();
                            element.focus();
                        } else {
                            console.log(`No element found with index ${number}`);
                        }
                    }
                } else if (command === 'tab' || command === 'next' || command === 'انتقل' || command === 'إنتقل'
                    || command === 'أنتقل' || command === 'التالى' || command === 'التالي'
                ) {
                    const focusableElements = 'a:not(#tamkinPlayerApp *), button, input, select, textarea, [tabindex]:not([tabindex="-1"])';
                    const elements = Array.from(document.querySelectorAll(focusableElements));
                    const currentIndex = elements.indexOf(document.activeElement);

                    let nextIndex = currentIndex + 1;
                    if (nextIndex >= elements.length) {
                        nextIndex = 0;
                    }

                    elements[nextIndex].focus();
                } else if (command === 'tab back' || command === 'back' || command === 'previous'
                    || command === 'رجوع' || command === 'السابق' || command === 'اضغط للخلف'
                    || command === 'إضغط للخلف' || command === 'أضغط للخلف'

                ) {
                    const focusableElements = 'a, button, input, select, textarea, [tabindex]:not([tabindex="-1"])';
                    const elements = Array.from(document.querySelectorAll(focusableElements));
                    const currentIndex = elements.indexOf(document.activeElement);

                    let previousIndex = currentIndex - 1;
                    if (previousIndex < 0) {
                        previousIndex = elements.length - 1;
                    }
                    elements[previousIndex].focus();
                } else if (command === 'enter' || command === 'أدخل' || command === 'ادخل'
                    || command === 'إدخل'
                ) {
                    const activeElement = document.activeElement;
                    if (activeElement) {
                        const enterEvent = new KeyboardEvent('keydown', {
                            key: 'Enter',
                            keyCode: 13,
                            code: 'Enter',
                            which: 13,
                            bubbles: true,
                        });
                        activeElement.dispatchEvent(enterEvent);
                        const clickEvent = new MouseEvent('click', {
                            bubbles: true,
                            cancelable: true,
                            view: window
                        });

                        activeElement.dispatchEvent(clickEvent);
                    }
                } else {
                    return;
                }
            },
            closeVoiceNavigation() {
                $("#voice-nav-modal").removeClass("active");
                this.state.recognition.stop();
                this.hideNumnbers();

                this.update();
                this.state.listening = false;
                this.update();
                $("#openlisten").removeClass("active");
                $(".tp-accessibility4").css("display", 'block')
                localStorage.removeItem('acc-addons-main-menu-voice-navigation')
            },
            stopVoiceNavigation() {
                this.state.listening = false;
                this.state.paused = 1;
                this.state.text = 'Tamkin is paused';
                document.getElementById("titleModal").innerText = 'Tamkin is paused';
                $("#openlisten").removeClass("active");
                this.update();
            },
            startVoiceNavigation() {
                this.state.listening = true;
                this.state.paused = 0;
                this.state.text = 'Tamkin is listening';
                document.getElementById("titleModal").innerText = 'Tamkin is listening';
                $("#openlisten").addClass("active");
                this.update();
            },
            updateShowDetails(payload) {
                this.state.showDetails = true;
                this.update();
                this.getCommands();
            },
            updateHideDetails(payload) {
                this.state.showDetails = false;
                this.update();
            },

            showNumbers() {
                $('a:not(#tamkinPlayerApp *), button:not(#tamkinPlayerApp *), input[type="submit"]:not(#tamkinPlayerApp *)').each(function (index) {
                    if ($(this).css('position') === 'fixed' || $(this).css('position') === 'static' || $(this).css('position') === 'absolute') {
                        $(this).find('.tp-tooltip-wrapper').remove();
                        $(this).append(`<span class='tp-tooltip-wrapper' style='position:relative'>
                            <span class="tp-num_tooltip">${index + 1}</span>
                        </span>`);
                    } else {
                        $(this).css('position', 'relative');
                        $(this).find('.tp-num_tooltip').remove();

                        $(this).append(`<span class="tp-num_tooltip">${index + 1}</span>`);
                    }
                    $('<style>')
                        .prop('type', 'text/css')
                        .html(`
                        .tp-num_tooltip {
                            visibility: visible;
                            background-color: black;
                            color: #fff !important;
                            text-align: center;
                            border-radius: 5px;
                            padding: 5px 10px;
                            position: absolute !important;
                            z-index: 9999999;
                            top: 100%; 
                            left: 60%;
                            margin-left: -30px; 
                            font-size: 12px;
                            font-weight: 500 !important;
                            opacity: 1;
                            transition: opacity 0.3s;
                        }
                            .tp-num_tooltip::after {
                                content: "";
                                position: absolute;
                                bottom: 100%; 
                                left: 50%;
                                margin-left: -5px;
                                z-index: 9999999;
                                border-width: 5px;
                                border-style: solid;
                                border-color: transparent transparent black transparent;
                            }
                    `)
                        .appendTo('head');
                });
            },

            hideNumnbers() {
                $('a:not(#tamkinPlayerApp *), button:not(#tamkinPlayerApp *), input[type="submit"]:not(#tamkinPlayerApp *)').each(function () {
                    $(this).find('.tp-num_tooltip').remove();
                    $(this).find('.tp-tooltip-wrapper').remove();
                });
            },
            getCommands() {
                this.state.commands = []
                $('#voice-nav-modal #loading').show();
                $('#voice-nav-modal .voice-nav-body').hide();
                this.update();
                const lang = localStorage.getItem("lang") === 'ar' ? localStorage.getItem("lang") : 'en'
                fetch(`https://api.tamkin.app/v1/api/Widget/VoiceCommands?lang=${lang}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    // body: JSON.stringify({

                    // })
                })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error(`HTTP error! Status: ${response.status}`);
                        }

                        return response.json();
                    })
                    .then(data => {

                        if (data?.data) {
                            $('#voice-nav-modal #loading').hide();
                            $('#voice-nav-modal .voice-nav-body').show();
                            this.state.commands = data.data;
                            this.update();
                        }

                    })
                    .catch(error => {
                        $('#voice-nav-modal #loading').hide();
                        $('#voice-nav-modal .voice-nav-body').show();
                        console.error('Fetch error:', error);
                    });

            },
        }
    </script>
    <style>
        #voice-nav-modal {
            position: fixed;
            bottom: -100%;
            transition: all 0.5s;
            left: 50%;
            transform: translateX(-50%);
            z-index: 3000;
            padding: 14px;
            background-color: #F2F2F2;
            box-shadow: 0px 4px 8px 0px #00000014;
            border-radius: 8px;
            max-width: 792px;
            width: 80%;
        }

        #voice-nav-modal.active {
            bottom: 35px;
        }

        #voice-nav-modal img,
        #voice-nav-modal svg {
            cursor: pointer;
        }

        .voice-nav-header .titleModal {
            font-size: 16px;
            font-weight: 600;
            color: #1E1E1E;
            margin: 0px !important;
            transition: all 0.5s;
            animation: typing 3.5s steps(40, end);
        }

        @keyframes typing {
            from {
                width: 0
            }

            to {
                width: 100%
            }
        }

        .voice-nav-body {
            transition: all 0.5s;
            max-height: 300px;
            overflow-y: auto;
            scrollbar-width: thin;
        }

        .command-item .title-bold {
            font-weight: 600;
            font-size: 16px;
            color: #000;
        }

        .command-item .title-thin {
            font-weight: 600;
            font-size: 16px;
            color: #A6A6A6;
        }

        .command-item .command-item {
            padding: 8px 12px;
            border: 2px solid var(--tp-primary);
            font-weight: 600;
            font-size: 14px;
            color: var(--tp-primary);
            border-radius: 8px;
        }

        @keyframes shadowWaveAnimation {
            0% {
                box-shadow: 0 0 0 rgba(53, 192, 180, 0);
            }

            50% {
                box-shadow: 0 0 10px 7px rgba(var(--tp-primary), 0.8);
            }

            100% {
                box-shadow: 0 0 0 rgba(53, 192, 180, 0);
            }
        }

        #openlisten {
            border-radius: 50%;
            width: 50px;
            height: 50px;
            min-width: 50px;
            min-height: 50px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        #openlisten.active {
            animation: shadowWaveAnimation 2s ease infinite;
            animation-direction: normal;
            animation-fill-mode: none;
            animation-play-state: running;
        }

        @keyframes pulseAnimation {
            0% {
                transform: scale(1);
                opacity: 1;
            }

            50% {
                transform: scale(1.25);
                opacity: 0.5;
            }

            100% {
                transform: scale(1.5);
                opacity: 0;
            }
        }

        #openlisten.active:before {
            content: "";
            animation: pulseAnimation 1.05s ease-out 1s infinite;
            border-radius: 50%;
            position: absolute;
            z-index: -1;
            border: 1px solid var(--tp-primary);
            height: 50px;
            width: 50px;
            min-height: 50px;
            min-width: 50px;
            left: 0%;
            top: 0%;

        }

        #pleasestart {
            color: #333;
            font-size: 13px;
            font-weight: 500;
            margin-top: 15px;
            margin-bottom: 10px;
        }

        .tp-spinner {
            width: 33px;
            height: 33px;
            border: 3.91px solid transparent;
            border-top-color: var(--tp-primary);
            border-right-color: var(--tp-primary);
            border-radius: 50%;
            animation: spin 1s linear infinite;
            background-origin: border-box;
            background-clip: border-box;
        }

        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(360deg);
            }
        }

        .loading {
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            justify-content: start;
            align-items: center;
            gap: 8px;
        }

        .loading h3 {
            font-size: 13px;
            font-weight: 600;
            line-height: 19.5px;
            letter-spacing: 0.30000001192092896px;
            text-align: center;
        }

        .loading p {
            font-size: 10px;
            font-weight: 400;
            line-height: 15px;
            letter-spacing: 0.30000001192092896px;
            text-align: center;
        }
    </style>
</voice-navigation>