<accessibility-profiles>
    <div class="bg-white rounded-4 mx-2 mt-2">
        <a class="btn px-3 py-2 d-flex align-items-center justify-content-between" onclick="{toggleProfile}">
            <div class="d-flex">
                <div class="rounded-circle tp-icon tp-bg-primary d-flex align-items-center justify-content-center">
                    <img style="margin-bottom: 3px; width: 23px"
                        src="{window.tamkin_src_base+'/images/accessibility-profiles.svg'}" alt="">
                </div>
                <span class="px-2 font-16 font-w400 mb-0 align-content-center">Accessibility Profiles</span>
            </div>
            <div>
                <img class="accordion-arrow" src="{window.tamkin_src_base+'/images/arrow-right.svg'}" alt="arrow">
            </div>
        </a>
    </div>
    <div id="collapseProfile" class="collapse rounded-4 bg-white py-3 px-2 mx-2 mt-2">
        <div class="d-flex flex-wrap">
            <div each="{profile in state.profiles }" key="{profile.name}"
                class="col-6 profile-item {profile.active ? 'active' : ''}" id="profile-{profile.name}"
                onclick="{() => activateProfile(profile)}">
                <div class="rounded-4 p-2 d-flex align-items-center">
                    <div class="rounded-circle bg-white"> <!-- tp-icon -->
                        <img class="imggray" width="40" src="{loadFile(profile.image)}" alt="{profile.title}">
                    </div>
                    <span class="px-2 font-14 font-w400 mb-0 align-content-center">{profile.title}</span>
                </div>
            </div>
        </div>
    </div>
    <script>
        export default {
            triggerEvent(event, data = null) {
                window.EventBus.dispatch(event, data);
            },
            state: {
                profiles: [],
                features: [
                    { id: 'acc-addons-main-menu-tooltip', activeStatesCount: 1 },
                    { id: 'acc-addons-main-menu-page-structure', activeStatesCount: 1 },
                    { id: 'acc-addons-main-menu-pause-animation', activeStatesCount: 1 },
                    { id: 'acc-addons-main-menu-dyslexia', activeStatesCount: 2 },
                    { id: 'acc-addons-main-menu-cursor', activeStatesCount: 4 },
                    { id: 'acc-addons-main-menu-saturation', activeStatesCount: 3 },
                    { id: 'acc-addons-main-menu-contrast', activeStatesCount: 3 },
                    { id: 'acc-addons-main-menu-text-spacing', activeStatesCount: 3 },
                    { id: 'acc-addons-main-menu-line-height', activeStatesCount: 3 },
                    { id: 'acc-addons-main-menu-text-align', activeStatesCount: 4 },
                    { id: 'acc-addons-main-menu-bigger-text', activeStatesCount: 4 },
                    { id: 'acc-addons-main-menu-highlight-links', activeStatesCount: 1 },
                    { id: 'acc-addons-main-menu-hide-images', activeStatesCount: 1 },
                ],
            },
            onBeforeMount(props, state) {
                window.accProfiles = [
                    {
                        name: 'acc-addons-accessibility-profiles-adhd',
                        title: 'ADHD',
                        image: './images/profile-adhd.svg',
                        active: false,
                        features: [
                            { id: 'acc-addons-main-menu-pause-animation', activeState: 1 },
                            { id: 'acc-addons-main-menu-saturation', activeState: 1 },
                            { id: 'acc-addons-main-menu-cursor', activeState: 3 },
                        ]
                    },
                    {
                        name: 'acc-addons-accessibility-profiles-blind',
                        title: 'Blind',
                        image: './images/profile-blind.svg',
                        active: false,
                        features: [
                            { id: 'acc-addons-main-menu-screen-reader', activeState: 1 },
                        ]
                    },
                    {
                        name: 'acc-addons-accessibility-profiles-color-blind',
                        title: 'Color Blind',
                        image: './images/profile-color-blind.svg',
                        active: false,
                        features: [
                            { id: 'acc-addons-main-menu-smart-contrast', activeState: 1 },
                            { id: 'acc-addons-main-menu-saturation', activeState: 2 },
                        ]
                    },
                    {
                        name: 'acc-addons-accessibility-profiles-dyslexia-profile',
                        title: 'Dyslexia',
                        image: './images/profile-df.svg',
                        active: false,
                        features: [
                            { id: 'acc-addons-main-menu-pause-animation', activeState: 1 },
                            { id: 'acc-addons-main-menu-dyslexia', activeState: 1 },
                        ]
                    },
                    {
                        name: 'acc-addons-accessibility-profiles-visually-impaired',
                        title: 'Visually Impaired',
                        image: './images/profile-visually-impaired.svg',
                        active: false,
                        features: [
                            { id: 'acc-addons-main-menu-bigger-text', activeState: 1 },
                            { id: 'acc-addons-main-menu-pause-animation', activeState: 1 },
                            { id: 'acc-addons-main-menu-dyslexia', activeState: 2 },
                            { id: 'acc-addons-main-menu-cursor', activeState: 1 },
                            { id: 'acc-addons-main-menu-tooltip', activeState: 1 },
                            { id: 'acc-addons-main-menu-saturation', activeState: 2 },
                        ]
                    },
                    {
                        name: 'acc-addons-accessibility-profiles-cognitive-and-learning',
                        title: 'Cognitive & Learning',
                        image: './images/profile-cognitive.svg',
                        active: false,
                        features: [
                            { id: 'acc-addons-main-menu-smart-contrast', activeState: 1 },
                            { id: 'acc-addons-main-menu-bigger-text', activeState: 1 },
                            { id: 'acc-addons-main-menu-pause-animation', activeState: 1 },
                            { id: 'acc-addons-main-menu-cursor', activeState: 4 },
                            { id: 'acc-addons-main-menu-tooltip', activeState: 1 },
                        ]
                    },
                    {
                        name: 'acc-addons-accessibility-profiles-seizure-and-epileptic',
                        title: 'Seizure & Epileptic',
                        image: './images/profile-seizure.svg',
                        active: false,
                        features: [
                            { id: 'acc-addons-main-menu-pause-animation', activeState: 1 },
                            { id: 'acc-addons-main-menu-saturation', activeState: 1 },
                        ]
                    },
                    {
                        name: 'acc-addons-accessibility-profiles-motor-impaired',
                        title: 'Motor Impaired',
                        image: './images/profile-motor-impaired.svg',
                        active: false,
                        features: [
                            { id: 'acc-addons-main-menu-pause-animation', activeState: 1 },
                            { id: 'acc-addons-main-menu-tooltip', activeState: 1 },
                        ]
                    },
                ];

                if (props.accessabilityProfiles) {
                    let profilesToEnable = [];
                    for (let i = 0; i < window.accProfiles.length; i++) {
                        let profile = window.accProfiles[i];

                        let enabledProfile = props.accessabilityProfiles.find(f => f.name == profile.name);

                        if (enabledProfile) {
                            profile.title = enabledProfile.label;
                            profile.image = enabledProfile.icon;
                            profile.sort = enabledProfile.sort;
                            profilesToEnable.push(profile);
                        }
                    }
                    this.state.profiles = profilesToEnable?.sort((a, b) => a.sort - b.sort);
                }
            },

            toggleProfile(e){
                $(e.target).toggleClass('appear')
                $('#collapseProfile').slideToggle(300);
            },
            activeProfile() {
                return this.state.profiles.find(profile => profile.active)
            },
            loadFile(path) {
                return 'https://tamkin.app' + path;
            },
            resetProfiles() {
                this.state.profiles.map(profile => profile.active = false)
                this.state.profiles.map((profile) => {
                    localStorage.removeItem(profile.name)
                })
                this.update();
                this.triggerEvent('resetAccessibility');
            },

            sendStatus(fauture, enable) {
                fetch('https://api.tamkin.app/v1/api/Ai/increaseAccessibility', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        fauture: fauture,
                        enable: enable
                    })
                })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error(`HTTP error! Status: ${response.status}`);
                        }
                        return response.json();
                    })
                    .then(data => {
                    })
                    .catch(error => {
                        console.error('Fetch error:', error);
                    });
            },
            activateProfile(selectedProfile, istriggered = false) {
                // deactivate others first if clicking a not active profile
                if (!selectedProfile.active)
                    this.state.profiles.map(profile => profile.active = false);
                // if clicking an active profile, toggle its state or if it's not active, activate it
                if (!istriggered) {
                    selectedProfile.active = !selectedProfile.active;
                }

                // reset all first
                this.triggerEvent('resetAccessibility');
                // // the same function of accessibility-controls.riot of reset
                if (selectedProfile.active) {
                    localStorage.setItem(selectedProfile.name, JSON.stringify(selectedProfile))
                    selectedProfile.features.forEach(feature => {
                        // trigger click feature.actiedState times
                        for (let i = 1; i <= feature.activeState; i++) {
                            $('#' + feature.id).trigger('click');
                        }
                    })
                } else {
                    localStorage.removeItem(selectedProfile.name)
                    selectedProfile.features.map((feature) => {
                        localStorage.removeItem(feature.id)
                    })

                }

                this.sendStatus(selectedProfile.name, selectedProfile.active ? 1 : 0)
                this.update()
            },

            onMounted(props, state) {
     

                document.querySelectorAll('[data-bs-toggle="collapse"]').forEach((button) => {
                    button.addEventListener('click', (event) => {
                        console.log("event",event.target);
                        
                        // if(event.target.classList.contains('show')){

                        // }
                    });
                });

                

                window.resetProfiles = this.resetProfiles.bind(this);
                const updatedProfiles = [];
                this.state.profiles.forEach(profile => {
                    try {
                        const item = JSON.parse(localStorage.getItem(profile.name));
                        if (item) {
                            updatedProfiles.push(item);
                            if (item.active == true) {
                                this.activateProfile(item, true)
                            } else {
                                profile.features.forEach((feature) => {
                                    localStorage.removeItem(feature.id)
                                })
                                localStorage.removeItem(profile.name)
                            }

                        } else {
                            updatedProfiles.push(profile);
                        }
                    } catch (error) {
                        console.error("Error processing feature:", error);
                    }
                })
                this.update({ profiles: updatedProfiles });
            }
        }
    </script>

    <style>
        #collapseProfile .profile-item {
            cursor: pointer;
            margin-bottom: .4rem;
            padding-left: .2rem;
            padding-right: .2rem;
        }

        #collapseProfile .profile-item:nth-last-child(1),
        #collapseProfile .profile-item:nth-last-child(2) {
            margin-bottom: 0;
        }

        #collapseProfile .profile-item>div {
            background-color: #F7F7F7;
        }

        #collapseProfile .profile-item.active>div {
            background-color: var(--tp-primary);
            color: white;
        }

        #collapseProfile .profile-item .tp-icon {
            flex: 40%;
        }

        #collapseProfile .profile-item .tp-icon+span {
            flex: 99%;
        }

        #collapseProfile .profile-item>div {
            border: 2px solid transparent;
        }

        #collapseProfile .profile-item>div:hover {
            border: 2px solid var(--tp-primary);
        }
    </style>
</accessibility-profiles>