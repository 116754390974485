<accessibility-controls>
    <div class="rounded-4 mb-3">
        <div class="circle-container" id="circle-container">
            <div style="position: relative;width: 100%;">
                <!-- dynamic -->
                <div each="{ (feature, index) in state.features}"
                    class="accessibility-item {feature.activeState ? 'active' : ''}" key="{feature.id}"
                    id="{feature.id}" onclick={handleFeature} style="{getStyle(index)}">
                    <div class="inner-item rounded-4 d-flex flex-column align-items-center">
                        <div class="tp-wrapper-icon" style="{getImageStyle(index)}">
                            <img class="feature-img" style="{feature.states[feature.activeState].imgStyle}"
                                src="{window.tamkin_src_base+feature.states[feature.activeState].image}" alt="">
                        </div>
                    </div>
                </div>
                <div class="close-btn-wrapper">
                    <a id="tp-accessibility4-sidebar-close" onclick={closeWidget}
                        class="tp-accessibility4-sidebar-close rounded-circle" href="javascript:void(0)">
                        <svg class="nonehovered" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 6L6 18" stroke="#585B5B" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" />
                            <path d="M6 6L18 18" stroke="#585B5B" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" />
                        </svg>
                        <svg class="hovered" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 6L6 18" stroke="white" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" />
                            <path d="M6 6L18 18" stroke="white" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" />
                        </svg>
                    </a>
                </div>
            </div>
        </div>
    </div>

    <voice-navigation></voice-navigation>

    <p id="readerDisabled" style="display: none;">Tamkeen screen reader mode is disabled.</p>
    <p id="slowMode" style="display: none;">Tamkeen screen reader slow mode is enabled.</p>
    <p id="speedMode" style="display: none;">Tamkeen screen reader speed mode is enabled.</p>
    <p id="normalMode" style="display: none;">Tamkeen screen reader normal mode is enabled.</p>


    <div class="modal fade " id="DictionaryModal" tabindex="0" aria-labelledby="DictionaryModalLabel"
        aria-hidden="false">
        <div class="modal-dialog modal-dialog-centered rounded-2">
            <div class="modal-content" style="position: relative;">
                <div class="modal-header bg-white tp-modal-header" style="border-radius:12px">
                    <button class="modal_close_dic" type="button" onclick="{closeDictionaryModal}" aria-label="Close">
                        <svg class="nonehovered" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 6L6 18" stroke="#FFF" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" />
                            <path d="M6 6L18 18" stroke="#FFF" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" />
                        </svg>
                    </button>
                </div>

                <div class="modal-body bg-white"
                    style="border-radius:12px;max-height: 90vh;overflow-y: auto;scrollbar-width: thin;">
                    <div style="position: relative;">
                        <input type="text" oninput="{sendDataDictionary}" class="form-control h-50px input-border px-4 rounded-4 
                             ui-keyboard-input ui-widget-content ui-corner-all" id="dictionaryInput"
                            placeholder="Search" aria-haspopup="true" role="textbox">
                        <img style="position: absolute;top: 15px;right: 15px;"
                            src="{window.tamkin_src_base+'/images/icon-search.svg'}" alt="search">
                    </div>

                    <div id="dictionarybody" class="mt-2">

                    </div>
                    <div id="dictionfooter" class="dictionfooter mt-2 d-flex align-items-center" style="gap: 20px;">

                    </div>
                </div>
            </div>
        </div>
    </div>



    <script>
        const importAllImages = (requireContext) => requireContext.keys().map(requireContext);
        const images = importAllImages(require.context('/src/player_files/accessibility/img', false, /\.(png|jpe?g|svg)$/));
        import { Color, Solver } from '/src/player_files/accessibility/image_color.js';

        import tinycolor from "tinycolor2";
        import voiceNavigation from '/src/accessibility/shared/voice-navigation.riot';
        window.tamkin_src_base = "";
        // if (process.env.NODE_ENV === "production") {
        //     window.tamkin_src_base = "https://cdn.tamkin.app";
        // }
        import fileAudio from "/src/player_files/audio/open_audio.mp3";
        const sound = window.tamkin_src_base + fileAudio;

        export default {
            components: {
                'voice-navigation': voiceNavigation,
            },
            state: {
                features: [],
            },
            closeWidget() {
                $("#tamkinPlayerApp .tp-accessibility4-sidebar").removeClass("active");
                $("#tamkinPlayerApp #circle-container").removeClass("active");
                $(".tp-accessibility4").css("display", 'block')
                const soundeffect = localStorage.getItem("enableSoundEffect");
                const audio = new Audio(sound);
                if (soundeffect == 1) {
                    audio.play();
                }
            },
            sendStatus(fauture, enable) {
                fetch('https://api.tamkin.app/v1/api/Ai/increaseAccessibility', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        fauture: fauture,
                        enable: enable
                    })
                })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error(`HTTP error! Status: ${response.status}`);
                        }
                        return response.json();
                    })
                    .then(data => {
                    })
                    .catch(error => {
                        console.error('Fetch error:', error);
                    });
            },
            handleFeature(e) {
                var feature;
                let sendBool = true;
                if (e.detail && e.detail.feature) {
                    feature = e.detail.feature;
                } else {
                    const featureId = e.currentTarget.id;
                    feature = this.state.features.find(f => f.id === featureId);
                    feature.activeState = (feature.activeState + 1) % feature.states.length
                }
                if (e.detail && e.detail.sendStatistic) {
                    sendBool = false
                }
                if (sendBool && (feature.activeState == 1 || feature.activeState == 0)) {
                    this.sendStatus(feature.id, feature.activeState)
                }
                localStorage.setItem(feature.id, JSON.stringify(feature))

                switch (feature.id) {
                    case 'acc-addons-main-menu-dyslexia':
                        this.dyslexiaFriendly(e, feature)
                        break;
                    case 'acc-addons-main-menu-pause-animation':
                        this.pauseAnimation(e, feature)
                        break;
                    case 'acc-addons-main-menu-bigger-text':
                        this.biggerText(e, feature)
                        break;
                    case 'acc-addons-main-menu-highlight-links':
                        this.highlightLinks(e, feature)
                        break;
                    case 'acc-addons-main-menu-hide-images':
                        this.hideImages(e, feature)
                        break;
                    case 'acc-addons-main-menu-text-align':
                        this.textAlign(e, feature)
                        break;
                    case 'acc-addons-main-menu-text-spacing':
                        this.textSpacing(e, feature)
                        break;
                    case 'acc-addons-main-menu-line-height':
                        this.lineHeight(e, feature)
                        break;
                    case 'acc-addons-main-menu-page-structure':
                        this.pageStructure(e, feature)
                        break;
                    case 'acc-addons-main-menu-saturation':
                        this.saturation(e, feature)
                        break;
                    case 'acc-addons-main-menu-cursor':
                        this.cursor(e, feature)
                        break;
                    case 'acc-addons-main-menu-contrast':
                        this.contrast(e, feature)
                        break;
                    case 'acc-addons-main-menu-smart-contrast':
                        this.smartContrast(e, feature)
                        break;
                    case 'acc-addons-main-menu-tooltip':
                        this.tooltip(e, feature)
                        break;
                    case 'acc-addons-main-menu-voice-navigation':
                        this.voiceNavigation(e, feature)
                        break;
                    case 'acc-addons-main-menu-screen-reader':
                        this.screenReader(e, feature)
                        break;
                    case 'acc-addons-main-menu-dictionary':
                        this.dictionary(e, feature)
                        break;
                    case 'acc-addons-main-menu-reading-mode':
                        this.readingMode(e, feature)
                        break;
                    case 'acc-addons-main-menu-tamkin-player':
                        this.tamkinPlayer(e, feature)
                        break;
                    case 'acc-addons-main-menu-media-player':
                        this.mediaPlayer(e, feature)
                        break;
                    default:
                        break;
                }
                this.update()
            },
            deactivateMediaPlayerFeature(e, feature) {
                feature.activeState = 0;
                this.update();
            },
            getStyle(index) {
                const angle = (360 / this.state.features.length) * index;
                return `transform: rotate(${angle}deg) translate(100px);`;
            },
            getImageStyle(index) {
                const angle = (360 / this.state.features.length) * index;
                return `transform: rotate(-${angle}deg);`; // Reverse rotation for the image
            },
            mediaPlayer(e, feature) {
                let modal = window.translateModal[0];
                // deactivate media player acc feature when closing the modal
                if (!modal.listenerBound) {
                    modal.addEventListener('hide.bs.modal', (e) => this.deactivateMediaPlayerFeature(e, feature));
                    modal.listenerBound = true;  // Set a flag indicating the listener is bound
                }

                let translateModalBs = new bootstrap.Modal(modal, {});
                if (feature.activeState) {
                    this.closeWidget();
                    translateModalBs.show();
                } else {
                    window.translateModal.find('[data-bs-dismiss="modal"]').trigger('click');
                }
            },
            tamkinPlayerOpen() {
                return localStorage.getItem('tamkinPlayerOpen')
            },
            tamkinPlayer(e, feature) {
                if (feature.activeState) {
                    this.closeWidget();
                    window.initiatePlayer();
                } else {
                    window.closePlayer();
                }
            },
            resetAccessibilitySettings() {
                // this.update({features: window.accFeatures});
                // or
                this.state.features.map(feature => feature.activeState = 0)
                this.update();
                // remove all classes from the dom
                let allAddedClasses = this.state.features.map(feature =>
                    feature.states.map(state => state.addedClass.split(' ')) // Split multiple classes into arrays
                ).reduce((acc, classes) => acc.concat(...classes), []).filter(className => className !== ""); // Flatten the array of arrays
                allAddedClasses = Array.from(new Set(allAddedClasses)); // Remove duplicates
                this.removeClassesFromDom(allAddedClasses);
                // reset cursor guide and mask
                $(document).off('mousemove', this.readingMaskListener);
                $(document).off('mousemove', this.readingGuideListener);
                if (window.$topMask) window.$topMask.remove();
                if (window.$bottomMask) window.$bottomMask.remove();
                if (window.$readingGuide) window.$readingGuide.remove();
                // reset reading mode
                this.resetReadingMode();


            },
            removeClassesFromDom(classes) {
                let addedClassesSelector = classes.map(className => '.' + className).join(', ');
                let addedClassesJoined = classes.join(' ');
                $(addedClassesSelector).removeClass(addedClassesJoined);
            },
            hexToRgb(hex) {
                const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
                hex = hex.replace(shorthandRegex, (m, r, g, b) => {
                    return r + r + g + g + b + b;
                });

                const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
                return result
                    ? [
                        parseInt(result[1], 16),
                        parseInt(result[2], 16),
                        parseInt(result[3], 16),
                    ]
                    : null;
            },
            applyFilters() {
                const hex = document.getElementById('tamkinPlayerApp').style.getPropertyValue('--tp-primary');
                const rgb = this.hexToRgb(hex);
                let fin;
                if (rgb && rgb.length === 3) {
                    const color = new Color(rgb[0], rgb[1], rgb[2]);
                    const solver = new Solver(color);
                    const result = solver.solve();

                    fin = result.filter;
                }
                return fin;
            },
            onBeforeMount(props, state) {
                window.accFeatures = [
                    {
                        id: 'acc-addons-main-menu-tamkin-player', activeState: 0, states: [
                            { title: 'Tamkin Player', image: '/images/tamkin-player-0.svg', addedClass: '', },
                            { title: 'Tamkin Player', imgStyle: this.applyFilters(), image: '/images/tamkin-player-0.svg', addedClass: '', },
                        ]
                    },
                    {
                        id: 'acc-addons-main-menu-media-player', activeState: 0, states: [
                            { title: 'Media Player', image: '/images/media-player-0.svg', addedClass: '', },
                            { title: 'Media Player', imgStyle: this.applyFilters(), image: '/images/media-player-0.svg', addedClass: '', },
                        ]
                    },
                    {
                        id: 'acc-addons-main-menu-screen-reader', activeState: 0, states: [
                            { title: 'Screen Reader', image: '/images/screen-reader-0.svg', addedClass: '', },
                            { title: 'Read Normal', imgStyle: this.applyFilters(), image: '/images/screen-reader-1.svg', addedClass: '', },
                            { title: 'Read Fast', imgStyle: this.applyFilters(), image: '/images/screen-reader-2.svg', addedClass: '', },
                            { title: 'Read Slow', imgStyle: this.applyFilters(), image: '/images/screen-reader-3.svg', addedClass: '', },
                        ]
                    },
                    {
                        id: 'acc-addons-main-menu-voice-navigation', activeState: 0, states: [
                            { title: 'Voice Navigation', image: '/images/voice-navigation-0.svg', addedClass: '', },
                            { title: 'Voice Navigation', imgStyle: this.applyFilters(), image: '/images/voice-navigation-0.svg', addedClass: '', },
                        ]
                    },
                    {
                        id: 'acc-addons-main-menu-dictionary', activeState: 0, states: [
                            { title: 'Dictionary', image: '/images/dictionary-0.svg', addedClass: '', },
                            { title: 'Dictionary', imgStyle: this.applyFilters(), image: '/images/dictionary-0.svg', addedClass: '', },
                        ]
                    },
                    {
                        id: 'acc-addons-main-menu-contrast', activeState: 0, states: [
                            { title: 'Contrast +', image: '/images/contrast-0.svg', addedClass: '', },
                            { title: 'Invert Colors', image: '/images/contrast-1.svg', addedClass: 'tp-contrast-1', },
                            { title: 'Dark Contrast', image: '/images/contrast-2.svg', addedClass: 'tp-contrast-2', },
                            { title: 'Light Contrast', image: '/images/contrast-3.svg', addedClass: 'tp-contrast-3', },
                        ]
                    },
                    {
                        id: 'acc-addons-main-menu-smart-contrast', activeState: 0, states: [
                            { title: 'Smart Contrast', image: '/images/smart-contrast-0.svg', addedClass: '', },
                            { title: 'Smart Contrast', imgStyle: this.applyFilters(), image: '/images/smart-contrast-0.svg', addedClass: '', },
                        ]
                    },
                    {
                        id: 'acc-addons-main-menu-tooltip', activeState: 0, states: [
                            { title: 'Tooltip', image: '/images/tooltip-0.svg', addedClass: '', },
                            { title: 'Tooltip', imgStyle: this.applyFilters(), image: '/images/tooltip-0.svg', addedClass: '', },
                        ]
                    },
                    {
                        id: 'acc-addons-main-menu-page-structure', activeState: 0, states: [
                            { title: 'Page Structure', image: '/images/page-structure-0.svg', addedClass: '', },
                            { title: 'Page Structure', imgStyle: this.applyFilters(), image: '/images/page-structure-0.svg', addedClass: '', },
                        ]
                    },
                    {
                        id: 'acc-addons-main-menu-pause-animation', activeState: 0, states: [
                            { title: 'Pause Animation', image: '/images/pause-animation-0.svg', addedClass: '', },
                            { title: 'Play Animation', imgStyle: this.applyFilters(), image: '/images/pause-animation-1.svg', addedClass: 'tp-pause-animation-1', },
                        ]
                    },
                    {
                        id: 'acc-addons-main-menu-dyslexia', activeState: 0, states: [
                            { title: 'Dyslexia Friendly', image: '/images/df-0.svg', addedClass: '', },
                            { title: 'Dyslexia Friendly', imgStyle: this.applyFilters(), image: '/images/df-1.svg', addedClass: 'tp-dyslexia-friendly-1', },
                            { title: 'Legible Fonts', imgStyle: this.applyFilters(), image: '/images/df-2.svg', addedClass: 'tp-dyslexia-friendly-2', },
                        ]
                    },
                    {
                        id: 'acc-addons-main-menu-cursor', activeState: 0, states: [
                            { title: 'Cursor', image: '/images/cursor-0.svg', addedClass: '', },
                            { title: 'White Big Cursor', imgStyle: this.applyFilters(), image: '/images/cursor-1.svg', addedClass: 'tp-cursor-1', },
                            { title: 'Black Big Cursor', imgStyle: this.applyFilters(), image: '/images/cursor-2.svg', addedClass: 'tp-cursor-2', },
                            { title: 'Reading Mask', imgStyle: this.applyFilters(), image: '/images/cursor-3.svg', addedClass: '', },
                            { title: 'Reading Guide', imgStyle: this.applyFilters(), image: '/images/cursor-4.svg', addedClass: '', },
                        ]
                    },
                    {
                        id: 'acc-addons-main-menu-saturation', activeState: 0, states: [
                            { title: 'Saturation', image: '/images/saturation-0.svg', addedClass: '', },
                            { title: 'Low Saturation', image: '/images/saturation-1.svg', addedClass: 'tp-saturation-1', },
                            { title: 'High Saturation', image: '/images/saturation-2.svg', addedClass: 'tp-saturation-2', },
                            { title: 'Desaturate', image: '/images/saturation-3.svg', addedClass: 'tp-saturation-3', },
                        ]
                    },
                    {
                        id: 'acc-addons-main-menu-text-spacing', activeState: 0, states: [
                            { title: 'Text Spacing', image: '/images/text-spacing-0.svg', addedClass: '', },
                            { title: 'Light Spacing', imgStyle: this.applyFilters(), image: '/images/text-spacing-1.svg', addedClass: 'tp-spacing-1', },
                            { title: 'Moderate Spacing', imgStyle: this.applyFilters(), image: '/images/text-spacing-2.svg', addedClass: 'tp-spacing-2', },
                            { title: 'Heavy Spacing', imgStyle: this.applyFilters(), image: '/images/text-spacing-3.svg', addedClass: 'tp-spacing-3', },
                        ]
                    },
                    {
                        id: 'acc-addons-main-menu-line-height', activeState: 0, states: [
                            { title: 'Line Height', image: '/images/line-height-0.svg', addedClass: '', },
                            { title: 'Line Height(1.5x)', imgStyle: this.applyFilters(), image: '/images/line-height-1.svg', addedClass: 'tp-line-height-1', },
                            { title: 'Line Height(1.75x)', imgStyle: this.applyFilters(), image: '/images/line-height-2.svg', addedClass: 'tp-line-height-2', },
                            { title: 'Line Height(2x)', imgStyle: this.applyFilters(), image: '/images/line-height-3.svg', addedClass: 'tp-line-height-3', },
                        ]
                    },
                    {
                        id: 'acc-addons-main-menu-text-align', activeState: 0, states: [
                            { title: 'Text Align', image: '/images/text-align-0.svg', addedClass: '', },
                            { title: 'Left Align', imgStyle: this.applyFilters(), image: '/images/text-align-1.svg', addedClass: 'tp-class-added tp-text-align-1', },
                            { title: 'Right Align', imgStyle: this.applyFilters(), image: '/images/text-align-2.svg', addedClass: 'tp-class-added tp-text-align-2', },
                            { title: 'Center Align', imgStyle: this.applyFilters(), image: '/images/text-align-3.svg', addedClass: 'tp-class-added tp-text-align-3', },
                            { title: 'Justify Align', imgStyle: this.applyFilters(), image: '/images/text-align-4.svg', addedClass: 'tp-class-added tp-text-align-4', },
                        ]
                    },
                    {
                        id: 'acc-addons-main-menu-bigger-text', activeState: 0, states: [
                            { title: 'Bigger Text', image: '/images/bigger-text-0.svg', addedClass: '', },
                            { title: 'Bigger Text', imgStyle: this.applyFilters(), image: '/images/bigger-text-1.svg', addedClass: 'tp-bigger-text-1', },
                            { title: 'Bigger Text', imgStyle: this.applyFilters(), image: '/images/bigger-text-1.svg', addedClass: 'tp-bigger-text-2', },
                            { title: 'Bigger Text', imgStyle: this.applyFilters(), image: '/images/bigger-text-1.svg', addedClass: 'tp-bigger-text-3', },
                            { title: 'Bigger Text', imgStyle: this.applyFilters(), image: '/images/bigger-text-1.svg', addedClass: 'tp-bigger-text-4', },
                        ]
                    },
                    {
                        id: 'acc-addons-main-menu-highlight-links', activeState: 0, states: [
                            { title: 'Highlight Links', image: '/images/highlight-links-0.svg', addedClass: '', },
                            { title: 'Highlight Links', imgStyle: this.applyFilters(), image: '/images/highlight-links-0.svg', addedClass: 'tp-highlight-links-1', },
                        ]
                    },
                    {
                        id: 'acc-addons-main-menu-hide-images', activeState: 0, states: [
                            { title: 'Hide Images', image: '/images/hide-images-0.svg', addedClass: '', },
                            { title: 'Hide Images', imgStyle: this.applyFilters(), image: '/images/hide-images-0.svg', addedClass: 'tp-hide-images-1', },
                        ]
                    },
                    {
                        id: 'acc-addons-main-menu-reading-mode', activeState: 0, states: [
                            { title: 'Reading Mode', image: '/images/reading-mode-0.svg', addedClass: '', },
                            { title: 'Light Mode', imgStyle: this.applyFilters(), image: '/images/reading-mode-0.svg', addedClass: '', },
                            { title: 'Dark Mode', imgStyle: this.applyFilters(), image: '/images/reading-mode-0.svg', addedClass: 'tp-dark-reading-mode', },
                        ]
                    },
                ];

                if (props.enabledFeatures) {
                    let featuresToEnable = [];
                    for (let i = 0; i < window.accFeatures.length; i++) {
                        let feature = window.accFeatures[i];
                        // get enabled features where name = feature.id
                        let enabledFeature = props.enabledFeatures.find(f => f.name == feature.id);

                        if (enabledFeature) {
                            const isFirefox = navigator.userAgent.includes("Firefox");
                            const isVoiceNavigation = enabledFeature.name === 'acc-addons-main-menu-voice-navigation';
                            if (isFirefox && isVoiceNavigation) {

                            } else {
                                feature.states[0].title = enabledFeature.label;
                                feature.sort = enabledFeature.sort;
                                featuresToEnable.push(feature);
                            }
                        }

                    }

                    this.state.features = featuresToEnable?.sort((a, b) => a.sort - b.sort).slice(0, 5);
                }

                // Event bus
                // made to allow accessibility-profile to call the resetAccessibilitySettings function present on accessibility-controls.riot
                window.EventBus = {
                    events: {},
                    dispatch(event, data) {
                        if (!this.events[event]) return; // No subscribers
                        this.events[event].forEach(callback => callback(data));
                    },
                    subscribe(event, callback) {
                        if (!this.events[event]) this.events[event] = []; // New event
                        this.events[event].push(callback);
                    }
                };
                // End Event Bus

                window.globalAffectedElDirect = 'body > *:not(tamkin-player-sdk, tamkin-player-sdk *, #tamkinPlayerApp, #tamkinPlayerApp *, style, script, link)'
                window.globalAffectedEl = 'body *:not(tamkin-player-sdk, tamkin-player-sdk *, #tamkinPlayerApp, #tamkinPlayerApp *, style, script, link)'
            },
            onBeforeUnmount() {
                if (this.observer) {
                    this.observer.disconnect();
                }
            },
            onMounted(props, state) {

                document.querySelectorAll("body > *:not(#tamkinPlayerApp):not(tamkin-player-sdk):not(#tamkinPlayerApp *):not(tamkin-player-sdk *)")
                    .forEach(element => {
                        element.addEventListener("click", () => {
                            const sidebar = document.getElementById("tp-accessibility4-sidebar");
                            if (sidebar && sidebar.classList.contains("active")) {
                                this.closeWidget(); // Execute the closeAcc method
                            }
                        });

                    });

                const targetNode = this.$('#voice-nav-modal');
                const observer = new MutationObserver((mutationsList) => {
                    for (const mutation of mutationsList) {
                        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                            if (mutation.target.classList.contains('active')) {
                                console.log('Class "active" added or present on #divid');
                            } else {
                                const feature = this.state.features.filter((el) => {
                                    return el.id === "acc-addons-main-menu-voice-navigation";
                                })[0];

                                if (feature) {
                                    feature.activeState = 0;
                                    this.sendStatus(feature.id, feature.activeState)
                                }
                                this.update();
                            }
                        }
                    }
                });
                observer.observe(targetNode, { attributes: true });
                this.observer = observer;

                // activate and deactivate tamkinPlayer feature if player is opened or closed without using acc sidebar
                var $this = this
                document.addEventListener('tamkinPlayerStateChanged', function (e) {

                    // let open = e.detail.state;
                    // let tamkinPlayerAccFeature = $this.state.features.find(feature => feature.id === 'acc-addons-main-menu-tamkin-player');

                    // if (open) {
                    //     tamkinPlayerAccFeature.activeState = 1;
                    // } else {
                    //     tamkinPlayerAccFeature.activeState = 0;
                    // }
                    $this.update();
                });
                // event subscription
                window.EventBus.subscribe('resetAccessibility', this.resetAccessibilitySettings.bind(this));
                // page structure
                $(document).ready(function () {
                    window.pageStructureModal = document.getElementById('pageStructureModal');
                    window.pageStructureModalBs = new bootstrap.Modal(window.pageStructureModal, {});
                    // deactivate page structure feature on modal hide
                    window.pageStructureModal.addEventListener('hide.bs.modal', function () {
                        $('#acc-addons-main-menu-page-structure').trigger('click');
                    })

                    // Function to handle scrolling to clicked item
                    function scrollToItem(selector) {
                        $('html, body').animate({
                            scrollTop: $(selector).offset().top
                        }, 500);
                    }
                    // Handle clicking on headings
                    $(document).on('click', '#headingsList li', function () {
                        let text = $(this).find('a').data('text');
                        let tagName = $(this).find('a').data('tagname');
                        scrollToItem(tagName + ':contains("' + text + '")'); // Scroll to matching heading
                        window.pageStructureModalBs.hide();
                    });
                    // Handle clicking on landmarks
                    $(document).on('click', '#landmarksList li', function () {
                        let text = $(this).find('a').data('text');
                        let tagName = $(this).find('a').data('tagname');
                        scrollToItem(tagName + ':contains("' + text + '")'); // Scroll to matching landmark
                        window.pageStructureModalBs.hide();
                    });

                    $this.updateDataFromLocalStorage();
                });
                // end page structure

                $(function () {
                    $(window.globalAffectedEl).each(function () {
                        let element = this;
                        let $element = $(this);

                        // for line-height and reading mode
                        if (element.childNodes.length === 1 && element.childNodes[0].nodeType === Node.TEXT_NODE) {
                            $(element).addClass('tp-text-only');
                        }
                        // for text-align to add proper styles based on display property
                        let style = window.getComputedStyle(element);
                        let flexDirection = style.getPropertyValue('flex-direction');
                        let display = style.getPropertyValue('display');
                        if (display == 'flex' && (flexDirection != 'column' && flexDirection != 'column-reverse')) {
                            $element.addClass('tp-text-align-plus-justify');
                        }
                    });
                });

                // open accessibility menu ctrl+u
                $(document).on('keydown', function (event) {
                    if (event.ctrlKey && event.key.toLowerCase() === 'u') {
                        event.preventDefault();
                        window.toggleAccessibility();
                    }
                });

                // TOOLTIP
                function tpTooltipInit($this) {
                    $('body').append('<div class="tp-tooltip-container"></div>');
                    var $tooltip = $('.tp-tooltip-container');
                    // Function to update and position the tooltip
                    function updateTooltip(text, x, y) {
                        $tooltip.text(text).css({
                            'left': x + 20 + 'px', // Offset by 20px from cursor
                            'top': y + 20 + 'px'
                        }).show();
                    }
                    var tooltipFeature = $this.state.features.find(feature => feature.id === 'acc-addons-main-menu-tooltip');
                    if (tooltipFeature) {
                        $('[aria-label], [aria-labelledby], [aria-describedby], [alt], [title]').hover(function (event) {
                            if (!tooltipFeature.activeState) return;
                            // Check if the attribute has a value
                            var attrValue = $(this).attr('aria-label') || $(this).attr('aria-labelledby') || $(this).attr('aria-describedby') || $(this).attr('alt') || $(this).attr('title');
                            if (attrValue) {
                                updateTooltip(attrValue, event.pageX, event.pageY);
                            }
                        }, function () {
                            if (!tooltipFeature.activeState) return;
                            $tooltip.hide(); // Hide tooltip when not hovering
                        });
                    }
                }
                tpTooltipInit(this);


                setInterval(() => {
                    this.state.features.forEach(feature => {
                        if (feature.activeState > 0 && feature.id !== "acc-addons-main-menu-screen-reader"
                            && feature.id !== "acc-addons-main-menu-dictionary"
                        ) {
                            if (feature.id === 'acc-addons-main-menu-voice-navigation') {
                                window.showNumbers();
                            } else {
                                const idElement = document.getElementById(feature.id);
                                const event = new CustomEvent('click', {
                                    detail: {
                                        feature: feature,
                                        sendStatistic: true
                                    },
                                    bubbles: true,
                                    cancelable: true
                                });
                                idElement.dispatchEvent(event);
                            }


                        }
                    });
                }, 500);

            },
            updateDataFromLocalStorage() {
                const updatedFeatures = [];
                this.state.features.forEach(feature => {
                    try {
                        const item = JSON.parse(localStorage.getItem(feature.id));
                        if (item) {
                            const parsedItem = item;
                            updatedFeatures.push(parsedItem);
                            const idElement = document.getElementById(parsedItem.id)
                            if (idElement && parsedItem.activeState > 0) {
                                const event = new CustomEvent('click', {
                                    detail: {
                                        feature: parsedItem,
                                    },
                                    bubbles: true,
                                    cancelable: true
                                });
                                idElement.dispatchEvent(event);
                            }

                        } else {
                            updatedFeatures.push(feature);
                        }
                    } catch (error) {
                        console.error("Error processing feature:", feature, error);
                    }
                });
                this.update({ features: updatedFeatures });
            },
            onUpdated(props, state) {
                let hasActiveFeature = state.features.some(feature => feature.activeState);
                if (hasActiveFeature)
                    $('.accessibility-icon-container .tp-accessibility4').addClass('active');
                else
                    $('.accessibility-icon-container .tp-accessibility4').removeClass('active');
            },
            resetAndAddNewClasses($affectedEl, feature) {
                // remove all classes (reset)
                let addedClasses = this.resetOldClasses(feature);
                // add classes based on activeState which refers to the index of the state (represents the suitable class)
                if (feature.activeState) {
                    let addedClass = feature.states[feature.activeState].addedClass;
                    addedClass && $affectedEl.addClass(addedClass);
                }
            },
            // return possible added classes
            resetOldClasses(feature) {
                // let addedClasses = feature.states.filter(state => state.addedClass).map(state => state.addedClass);
                // Extract and flatten all addedClass values, excluding empty strings and split multiple classes
                let addedClasses = feature.states
                    .map(state => state.addedClass) // Extract the addedClass strings
                    .filter(addedClass => addedClass) // Filter out empty strings
                    .flatMap(addedClass => addedClass.split(' ')); // Split multiple classes and flatten

                // Optionally, remove duplicates
                addedClasses = Array.from(new Set(addedClasses));
                this.removeClassesFromDom(addedClasses);

                return addedClasses;
            },
            speak(text, rate, element = '') {
                document.querySelectorAll("*").forEach((el) => {
                    el.classList.remove("tp-lang-highlight")
                })
                window.speechSynthesis.cancel();
                const validRate = isFinite(parseFloat(rate)) ? parseFloat(rate) : 1.0;
                const speech = new SpeechSynthesisUtterance(text);
                speech.lang = `${localStorage.getItem("screen_reader_lang") || 'en'}-EG`;
                speech.rate = validRate;

                if (element) {
                    element.classList.add("tp-lang-highlight")
                }

                window.speechSynthesis.speak(speech);

                speech.onend = () => {
                    if (element) {
                        element.classList.remove("tp-lang-highlight")
                    }
                };

            },
            handleFocus(event, rate) {
                document.querySelectorAll("*").forEach((el) => {
                    el.classList.remove("tp-lang-highlight")
                })
                const text = event.target.textContent || event.target.value || event.target.alt;
                const element = event.target;
                if (text) {
                    console.log("this.state.screen_reader_lang", this.state.screen_reader_lang);

                    // this.speak(text, rate, event.target);
                    window.speechSynthesis.cancel();

                    const validRate = isFinite(parseFloat(rate)) ? parseFloat(rate) : 1.0;
                    const speech = new SpeechSynthesisUtterance(text);
                    speech.lang = `${localStorage.getItem("screen_reader_lang") ?? 'en'}-EG`;
                    speech.rate = validRate;

                    if (element) {
                        element.classList.add("tp-lang-highlight")
                    }

                    window.speechSynthesis.speak(speech);
                    speech.onend = () => {
                        if (element) {
                            element.classList.remove("tp-lang-highlight")
                        }
                    };

                }
            },
            getAuoLang() {
                fetch('https://api.tamkin.app/v1/api/Widget/LanguageDetector', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        text: document.title || document.querySelector('meta[name="description"]')?.content ||
                            document.querySelector('h1')?.textContent || document.querySelector('h2')?.textContent ||
                            document.querySelector('div')?.textContent
                    })
                })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error(`HTTP error! Status: ${response.status}`);
                        }
                        return response.json();
                    })
                    .then(data => {
                        localStorage.setItem("screen_reader_lang", data.data ?? 'en')

                    })
                    .catch(error => {
                        console.error('Fetch error:', error);
                    });
            },

            async screenReader(n, feature) {
                await this.getAuoLang();
                const isFireFox = navigator.userAgent.includes("Firefox");
                const checkVoiceActive = this.state.features.find(el => el.id === "acc-addons-main-menu-voice-navigation")
                if (!isFireFox) {
                    if (checkVoiceActive.activeState == 0) {
                        if (feature.activeState == 0) {
                            document.querySelectorAll('*').forEach(element => {
                                element.removeEventListener('click', this.handleFocus.bind(this));
                            });

                            window.speechSynthesis.cancel();
                            this.speak($("#readerDisabled").text(), 1.0);
                        }
                        else if (feature.activeState == 1) {
                            document.querySelectorAll('*').forEach(element => {
                                element.addEventListener('click', (e) => {
                                    if (e.target != n.target && feature.activeState == 1) {
                                        this.handleFocus(e, 1.0);
                                    } else {
                                        if (feature.activeState == 1) {

                                            this.speak($("#normalMode").text(), 1.0);
                                        }
                                    }
                                });
                            });
                        } else if (feature.activeState == 2) {

                            document.querySelectorAll('*').forEach(element => {
                                element.addEventListener('click', (e) => {
                                    if (e.target != n.target && feature.activeState == 2) {
                                        this.handleFocus(e, 2.0);
                                    } else {
                                        if (feature.activeState == 2) {
                                            this.speak($("#speedMode").text(), 2.0);
                                        }
                                    }
                                });
                            });

                        } else if (feature.activeState == 3) {
                            console.log("false");
                            document.querySelectorAll('*').forEach(element => {
                                element.addEventListener('click', (e) => {
                                    if (e.target != n.target && feature.activeState == 3) {
                                        this.handleFocus(e, 0.5);
                                    }
                                    if (e.target == n.target && feature.activeState == 3) {
                                        this.speak($("#slowMode").text(), 0.5);
                                    }
                                });
                            });

                        } else {
                            document.querySelectorAll('*').forEach(element => {
                                element.removeEventListener('click', this.handleFocus.bind(this));
                            });
                            window.speechSynthesis.cancel();
                            this.speak($("#readerDisabled").text(), 1.0);
                        }
                    } else {
                        feature.activeState = 0;
                        this.update();
                    }
                } else {
                    if (feature.activeState == 0) {
                        document.querySelectorAll('*').forEach(element => {
                            element.removeEventListener('click', this.handleFocus.bind(this));
                        });

                        window.speechSynthesis.cancel();
                        this.speak($("#readerDisabled").text(), 1.0);
                    }
                    else if (feature.activeState == 1) {
                        document.querySelectorAll('*').forEach(element => {
                            element.addEventListener('click', (e) => {
                                if (e.target != n.target && feature.activeState == 1) {
                                    this.handleFocus(e, 1.0);
                                } else {
                                    if (feature.activeState == 1) {

                                        this.speak($("#normalMode").text(), 1.0);
                                    }
                                }
                            });
                        });
                    } else if (feature.activeState == 2) {

                        document.querySelectorAll('*').forEach(element => {
                            element.addEventListener('click', (e) => {
                                if (e.target != n.target && feature.activeState == 2) {
                                    this.handleFocus(e, 2.0);
                                } else {
                                    if (feature.activeState == 2) {
                                        this.speak($("#speedMode").text(), 2.0);
                                    }
                                }
                            });
                        });

                    } else if (feature.activeState == 3) {
                        console.log("false");
                        document.querySelectorAll('*').forEach(element => {
                            element.addEventListener('click', (e) => {
                                if (e.target != n.target && feature.activeState == 3) {
                                    this.handleFocus(e, 0.5);
                                }
                                if (e.target == n.target && feature.activeState == 3) {
                                    this.speak($("#slowMode").text(), 0.5);
                                }
                            });
                        });

                    } else {
                        document.querySelectorAll('*').forEach(element => {
                            element.removeEventListener('click', this.handleFocus.bind(this));
                        });
                        window.speechSynthesis.cancel();
                        this.speak($("#readerDisabled").text(), 1.0);
                    }
                }
            },

            voiceNavigation(e, feature) {
                if (feature.activeState == 1) {
                    $("#voice-nav-modal").addClass("active");
                    let hasTriggered = false;
                    if (!hasTriggered) {
                        $("#openlisten").trigger('click');
                        hasTriggered = true;
                    }
                    $('#tamkinPlayerApp #tp-accessibility-sidebar').removeClass('active');
                } else {
                    window.closeVoiceNav();
                }
                this.update()
            },
            closeDictionaryModal() {
                window.DictionaryModalBs.hide();
                window.speechSynthesis.cancel();
                $('#DictionaryModal #dictionarybody').empty();
                this.update();
            },
            dictionary(e, feature) {
                window.DictionaryModal = document.getElementById('DictionaryModal');
                window.DictionaryModalBs = new bootstrap.Modal(window.DictionaryModal, {});


                function getSelectedWords(event) {
                    let range;

                    // Cross-browser support for caret range
                    if (typeof document.caretRangeFromPoint === "function") {
                        range = document.caretRangeFromPoint(event.clientX, event.clientY);
                    } else if (typeof document.caretPositionFromPoint === "function") {
                        const caretPosition = document.caretPositionFromPoint(event.clientX, event.clientY);
                        if (caretPosition) {
                            range = document.createRange();
                            range.setStart(caretPosition.offsetNode, caretPosition.offset);
                            range.setEnd(caretPosition.offsetNode, caretPosition.offset);
                        }
                    }

                    if (!range || !range.startContainer || range.startContainer.nodeType !== Node.TEXT_NODE) {
                        return null;
                    }

                    const text = range.startContainer.textContent;
                    const offset = range.startOffset;

                    let start = offset;
                    let end = offset;

                    // Expand range to find the word boundaries
                    while (start > 0 && !/\s/.test(text[start - 1])) {
                        start--;
                    }
                    while (end < text.length && !/\s/.test(text[end])) {
                        end++;
                    }

                    const selectedWords = text.substring(start, end).trim();
                    return selectedWords;
                }

                if (feature.activeState) {
                    document.querySelectorAll('*').forEach(element => {
                        element.addEventListener('dblclick', (e) => {

                            const specificElement = document.querySelector('#dictionarybody');
                            const stop1 = document.querySelector(".readBtn");
                            const stop2 = document.querySelector(".spillBtn");
                            const noDatah3 = document.querySelector("#loading h3");
                            const noDatap = document.querySelector("#loading p");
                            const loaderh3 = document.querySelector("#no_data h3");
                            const loaderp = document.querySelector("#no_data p");

                            if (feature.activeState) {
                                if (e.target.closest('#DictionaryModal')) {
                                    return false
                                }
                                if (e.target === specificElement || e.target === stop1 ||
                                    e.target === stop2 || e.target === noDatah3 || e.target === noDatap ||
                                    e.target === loaderh3 || e.target === loaderp) {

                                    return false;
                                } else {
                                    $('#DictionaryModal #dictionarybody').empty();

                                    $('#DictionaryModal #dictionarybody').hide();
                                    $('#DictionaryModal #no_data').hide();
                                    $('#DictionaryModal #loading').show();

                                    const selectedText = getSelectedWords(e);
                                    if (selectedText) {
                                        document.getElementById("dictionaryInput").value = selectedText;

                                        fetch('https://api.tamkin.app/v1/api/Widget/LanguageDictionary', {
                                            method: 'POST',
                                            headers: {
                                                'Content-Type': 'application/json',
                                            },
                                            body: JSON.stringify({
                                                lang: localStorage.getItem("lang"),
                                                text: selectedText
                                            })
                                        })
                                            .then(response => {
                                                if (!response.ok) {
                                                    throw new Error(`HTTP error! Status: ${response.status}`);
                                                }
                                                return response.json();
                                            })
                                            .then(data => {

                                                if (data?.data) {
                                                    $('#DictionaryModal #dictionarybody').show();
                                                    $('#DictionaryModal #loading').hide();
                                                    // $('#DictionaryModal #dictionarybody').append(`<div class="difenition"><span class="wordDif">${selectedText}: </span>${data.data}</div>`);
                                                    $(".readBtn").on('click', () => {
                                                        this.speak(data?.data, 1.0);
                                                    });

                                                    const text = data.data;
                                                    const pageSize = 320;
                                                    let currentPage = 1;

                                                    const pages = Math.ceil(text.length / pageSize);
                                                    const textContainer = document.getElementById('dictionarybody');
                                                    const pageNumbers = document.getElementById('page-numbers');
                                                    const prevButton = document.getElementById('prev');
                                                    const nextButton = document.getElementById('next');

                                                    if (!textContainer || !pageNumbers || !prevButton || !nextButton) {
                                                        return;
                                                    }

                                                    function createPage(pageNumber) {
                                                        const start = (pageNumber - 1) * pageSize;
                                                        const end = Math.min(start + pageSize, text.length);
                                                        return text.slice(start, end);
                                                    }

                                                    function showPage(pageNumber) {
                                                        if (pageNumber < 1 || pageNumber > pages) return;
                                                        textContainer.textContent = createPage(pageNumber);
                                                        currentPage = pageNumber;
                                                        updatePagination();
                                                    }

                                                    function updatePagination() {
                                                        pageNumbers.innerHTML = '';

                                                        const maxVisiblePages = 5;
                                                        const startPage = Math.max(1, currentPage - 2);
                                                        const endPage = Math.min(pages, currentPage + 2);

                                                        if (startPage > 1) {
                                                            addPageButton(1);
                                                            if (startPage > 2) {
                                                                addEllipsis();
                                                            }
                                                        }

                                                        for (let i = startPage; i <= endPage; i++) {
                                                            addPageButton(i);
                                                        }

                                                        if (endPage < pages) {
                                                            if (endPage < pages - 1) {
                                                                addEllipsis();
                                                            }
                                                            addPageButton(pages);
                                                        }

                                                        prevButton.disabled = currentPage === 1;
                                                        nextButton.disabled = currentPage === pages;
                                                    }

                                                    function addPageButton(pageNumber) {
                                                        const button = document.createElement('button');
                                                        button.textContent = pageNumber;
                                                        button.onclick = () => showPage(pageNumber);
                                                        if (pageNumber === currentPage) {
                                                            button.classList.add("active")
                                                        }
                                                        pageNumbers.appendChild(button);
                                                    }

                                                    function addEllipsis() {
                                                        const ellipsis = document.createElement('span');
                                                        ellipsis.textContent = '...';
                                                        pageNumbers.appendChild(ellipsis);
                                                    }

                                                    prevButton.onclick = () => showPage(currentPage - 1);
                                                    nextButton.onclick = () => showPage(currentPage + 1);

                                                    showPage(currentPage);

                                                    this.update()
                                                } else {
                                                    $(".readBtn").off('click', this.speak(data?.data, 1.0));
                                                    $('#DictionaryModal #dictionarybody').hide();
                                                    $('#DictionaryModal #loading').hide();
                                                    $('#DictionaryModal #no_data').show();
                                                }

                                            })

                                            .catch(error => {
                                                console.error('Fetch error:', error);
                                            });

                                        window.speechSynthesis.cancel();
                                        this.closeWidget();
                                        window.DictionaryModalBs.show();
                                    }

                                }
                            }
                        })
                    })
                } else {
                    this.closeDictionaryModal()
                }
            },
            smartContrast(e, feature) {
                function suggestNewColor(element, activeState) {
                    const style = getComputedStyle(element);
                    const color = tinycolor(style.color);
                    let bgcolor = tinycolor(style.backgroundColor);
                    const oldcolor = element.dataset.originalColor || style.color;

                    if (!bgcolor.isValid() || bgcolor.getAlpha() === 0) {
                        bgcolor = tinycolor('#ffffff');
                    }

                    const readable = tinycolor.isReadable(color, bgcolor, {
                        level: 'AA',
                    });

                    if (activeState === 1 && !readable) {
                        if (!element.dataset.originalColor) {
                            element.dataset.originalColor = oldcolor;
                        }
                        element.style.color = tinycolor(color).darken(60).toString();
                    } else if (activeState === 0 && element.dataset.originalColor) {
                        element.style.color = element.dataset.originalColor;
                        delete element.dataset.originalColor;
                    }
                }

                const elements = document.querySelectorAll('body *:not([hidden]):not([type="hidden"]):not(tamkin-player-sdk):not(tamkin-player-sdk *)' +
                    ':not(#tamkinPlayerApp):not(#tamkinPlayerApp *)')
                elements.forEach((element) => {
                    if (element.offsetParent !== null) { // Check if the element is visible
                        suggestNewColor(element, feature.activeState);
                    }
                });
            },
            tooltip(e, feature) {
            },
            readingMode(e, feature) {
                let container = $('#reading-mode-container');
                if (!container.length) {
                    $('body').append('<div id="reading-mode-container"><div id="reading-mode-inner"></div></div>');
                    container = $('#reading-mode-container');
                }
                let containerInner = container.find('#reading-mode-inner');
                if (feature.activeState) {
                    if (feature.activeState < 2) {
                        containerInner.empty();
                        containerInner.append(`<h1 style="border-bottom: 1px solid #888;margin-bottom:50px;padding-bottom: 10px;">${document.title}</h1>`);
                        $('.tp-text-only, img').not('a,button,li,i,header *,footer *,nav *, aside *,#tamkinPlayerApp *, #tamkinPlayerApp, tamkin-player-sdk, tamkin-player-sdk *').each(function () {
                            containerInner.append($(this).clone());
                        });
                        $('body > *:not(#reading-mode-container, tamkin-player-sdk, tamkin-player-sdk *, #tamkinPlayerApp, #tamkinPlayerApp *, style, script, link)').addClass('reading-mode-d-none');
                        container.show();
                    } else {
                        // just add dark mode if state is 2
                        container.addClass(feature.states[feature.activeState].addedClass);
                    }
                } else {
                    this.resetReadingMode();
                }
            },
            resetReadingMode() {
                $('.reading-mode-d-none').removeClass('reading-mode-d-none');
                let container = $('#reading-mode-container');
                if (container.length) {
                    container.remove();
                }
            },
            pageStructureHandler() {
                // Clear previous content
                $('#headingsList').empty();
                $('#landmarksList').empty();
                $('#linksList').empty();

                // Get all headings and append to the modal
                $(':not(#tamkinPlayerApp *):header').each(function (index) {
                    let tagName = $(this).prop('tagName');
                    let text = $(this).text();
                    let $headingItem = $(`<li class="heading-link-container">
                    <a href="javascript:void(0)" class="heading-link" data-text="${text}" data-tagname="${tagName.toLowerCase()}">
                        <span class="heading-icon">${tagName}</span> <span class="heading-title">${text}</span>
                    </a>
                    </li>`);
                    $('#headingsList').append($headingItem);
                });

                // Get all landmarks and append to the modal
                $('main, header, footer, nav, section, article, aside, form').not('#tamkinPlayerApp *').each(function (index) {
                    let tagName = $(this).prop('tagName').toLowerCase();
                    let tagNameFormatted = tagName[0].toUpperCase() + tagName.slice(1).toLowerCase();
                    let text = $(this).text();
                    let textFormatted = text.trim().substring(0, 50);
                    textFormatted = textFormatted ? textFormatted[0].toUpperCase() + textFormatted.slice(1).toLowerCase() : textFormatted;
                    let $landmarkItem = $(`<li class="heading-link-container">
                        <a href="javascript:void(0)" class="heading-link" data-text="${text}" data-tagname="${tagName.toLowerCase()}">
                            <img class="link-heading-icon" src="${window.tamkin_src_base}/images/landmark.svg" /> <span class="heading-title">${tagNameFormatted}: ${textFormatted}</span>
                        </a>
                        </li>`);
                    $('#landmarksList').append($landmarkItem);
                });
                // Get all links and append to the modal
                $('a:not(#tamkinPlayerApp *)').each(function (index) {
                    var href = $(this).attr('href');
                    var text = $(this).text();
                    var target = $(this).attr('target');
                    var targetAttr = target ? `target="${target}"` : '';
                    $('#linksList').append(`<li class="heading-link-container">
                        <a href="${href}" class="heading-link" ${targetAttr}>
                            <img class="link-heading-icon" src="${window.tamkin_src_base}/images/link.svg" /> <span class="heading-title">${text}</span>
                        </a>
                    </li>`);
                });

                // Show the modal
                window.pageStructureModalBs.show();
            },
            pageStructure(e, feature) {
                let $el = $('#tamkinPlayerApp #acc-addons-main-menu-page-structure');
                if (feature.activeState) {
                    this.pageStructureHandler();
                }
            },
            pauseAnimation(e, feature) {
                let $affectedEl = $('*')
                this.resetAndAddNewClasses($affectedEl, feature)
                if (feature.activeState) {
                    // Pause all videos and audios
                    $('video, audio').each(function () {
                        this.pause();
                    });
                    // Pause Lottie animations if they exist
                    if (window.lottieAnimations) {
                        window.lottieAnimations.forEach(animation => animation.pause());
                    }
                    // Stop WOW.js animations if WOW is used
                    if (window.wow) {
                        $('.wow').css('visibility', 'hidden'); // Hide all WOW elements
                        window.wow.stop(); // Stop WOW.js from revealing new animations
                    }
                }
            },
            dyslexiaFriendly(e, feature) {
                let $affectedEl = $(window.globalAffectedEl)
                this.resetAndAddNewClasses($affectedEl, feature)
            },
            cursor(e, feature) {
                let $affectedEl = $('html')
                // reset
                $(document).off('mousemove', this.readingMaskListener);
                $(document).off('mousemove', this.readingGuideListener);
                if (window.$topMask) window.$topMask.remove();
                if (window.$bottomMask) window.$bottomMask.remove();
                if (window.$readingGuide) window.$readingGuide.remove();
                this.resetAndAddNewClasses($affectedEl, feature)
                switch (feature.activeState) {
                    case 3:
                        this.readingMask();
                        break;
                    case 4:
                        this.readingGuide();
                        break;
                }
            },
            readingMaskListener(e) {
                let maskHeight = 100;  // Height of the unmasked area
                let topMaskHeight = e.clientY - maskHeight / 2;
                let bottomMaskHeight = window.innerHeight - e.clientY - maskHeight / 2;
                window.$topMask.css({
                    'height': `${Math.max(0, topMaskHeight)}px`,
                    'bottom': `${e.clientY + maskHeight / 2}px`
                });
                window.$bottomMask.css({
                    'height': `${Math.max(0, bottomMaskHeight)}px`,
                    'top': `${e.clientY + maskHeight / 2}px`
                });
            },
            readingMask() {
                window.$topMask = $('.tp-reading-mask#top-mask');
                window.$bottomMask = $('.tp-reading-mask#bottom-mask');
                if (!window.$topMask.length || !window.$bottomMask.length) {
                    window.$topMask = $('<div id="top-mask" class="tp-reading-mask"></div>');
                    $bottomMask = $('<div id="bottom-mask" class="tp-reading-mask"></div>');
                    $('body').append(window.$topMask, window.$bottomMask);
                }
                $(document).mousemove(this.readingMaskListener);
            },
            readingGuideListener(e) {
                var guideWidth = window.$readingGuide.width();
                var windowWidth = $(window).width();
                var guideLeft = Math.max(0, Math.min(windowWidth - guideWidth, e.clientX - guideWidth / 2));

                window.$readingGuide.css({
                    'top': `${e.clientY - 20}px`,
                    'left': `${guideLeft}px`
                });
                window.$readingGuideMarker.css({
                    'top': (e.clientY - 20 + 4) + 'px', // Position above the guide
                    'left': `${e.clientX}px`,
                });

            },
            readingGuide() {
                window.$readingGuide = $('.tp-reading-guide#tp-reading-guide');
                if (!window.$readingGuide.length) {
                    window.$readingGuide = $('<div id="tp-reading-guide" class="tp-reading-guide"><div class="marker"></div></div>');
                    $('body').append(window.$readingGuide);
                }
                window.$readingGuideMarker = $('.tp-reading-guide#tp-reading-guide .marker');
                $(document).mousemove(this.readingGuideListener);
            },
            saturation(e, feature) {
                let $affectedEl = $('html').not('#tamkinPlayerApp').not('#tamkinPlayerApp *');
                this.resetAndAddNewClasses($affectedEl, feature)
            },
            contrast(e, feature) {
                let $affectedEl = $('body *').not('#tamkinPlayerApp').not('#tamkinPlayerApp *')
                let $invertColorAffectedEl = $('html').not('#tamkinPlayerApp').not('#tamkinPlayerApp *');
                let addedClasses = this.resetOldClasses(feature)

                let classIndex = feature.activeState - 1;

                if (feature.activeState == 1) {
                    $('#tamkinPlayerApp .tp-accessibility-sidebar').css('transition', '0s')
                    $invertColorAffectedEl.addClass(addedClasses[classIndex]);
                } else if (feature.activeState > 1) {
                    $('#tamkinPlayerApp .tp-accessibility-sidebar').css('transition', '0s')
                    $affectedEl.addClass(addedClasses[classIndex]);
                }
                setTimeout(() => {
                    $('#tamkinPlayerApp .tp-accessibility-sidebar').css('transition', '0.5s')
                }, 500);
            },
            textSpacing(e, feature) {
                let $affectedEl = $(window.globalAffectedEl)
                this.resetAndAddNewClasses($affectedEl, feature)
            },
            lineHeight(e, feature) {
                let $affectedEl = $(window.globalAffectedEl)
                this.resetAndAddNewClasses($affectedEl, feature)
            },
            textAlign(e, feature) {
                const globalAffectedEl = 'body *:not(tamkin-player-sdk, tamkin-player-sdk *, #tamkinPlayerApp, #tamkinPlayerApp *, style, script, link ,button)'

                let $affectedEl = $(globalAffectedEl)
                this.resetAndAddNewClasses($affectedEl, feature)
            },
            biggerText(e, feature) {
                let $affectedEl = $(window.globalAffectedElDirect)
                this.resetAndAddNewClasses($affectedEl, feature)
            },
            highlightLinks(e, feature) {
                const $affectedEl = $(window.globalAffectedElDirect);
                const links = $affectedEl.find('a');
                links.each(function () {
                    const $link = $(this);
                    const elements = document.querySelectorAll('.hidden, [style*="display: none"], [style="display: none"]');

                    elements.forEach(element => {
                        const links = element.querySelectorAll('a');
                        links.forEach(link => {
                            if (feature.activeState === 1) {
                                link.classList.add('highlighted-link');
                            } else {
                                link.classList.remove('highlighted-link');
                            }
                        });
                    });

                    if (feature.activeState === 1) {
                        $link.addClass('highlighted-link');
                        $link.find('*').addClass('highlighted-link');
                    } else {
                        $link.removeClass('highlighted-link');
                        $link.find('*').removeClass('highlighted-link');
                    }

                });

            },


            hideImages(e, feature) {
                const globalAffectedEl = 'body *:not(tamkin-player-sdk, tamkin-player-sdk *, #tamkinPlayerApp, #tamkinPlayerApp *, style, script, link)';
                const imgElements = document.querySelectorAll(`${globalAffectedEl} img, ${globalAffectedEl} svg`);
                const elementsWithBgImage = Array.from(document.querySelectorAll(globalAffectedEl)).filter(element => {
                    const bgImage = window.getComputedStyle(element).backgroundImage;
                    return bgImage && bgImage.includes('url(');
                });

                let $affectedEl = Array.from(imgElements).concat(elementsWithBgImage);
                this.resetAndAddNewClasses($($affectedEl), feature)
            },
            detectLanguage(text) {
                const arabicRegex = /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDCF\uFDF0-\uFDFF\uFE70-\uFEFF]/;
                return arabicRegex.test(text) ? 'ar' : 'en';
            },
            readAloud() {
                const text = document.getElementById('sample-text').textContent;
                const language = this.detectLanguage(text);
                const speech = new SpeechSynthesisUtterance(text);

                // Fetch voices and set the appropriate one based on detected language.
                window.speechSynthesis.onvoiceschanged = function () {
                    const voices = window.speechSynthesis.getVoices();
                    const voice = voices.find(voice => voice.lang.startsWith(language));
                    if (voice) {
                        speech.voice = voice;
                    } else {
                        console.log('No voice available for detected language: ' + language);
                    }
                    window.speechSynthesis.speak(speech);
                };

                // Ensure voices are loaded.
                if (speechSynthesis.getVoices().length === 0) {
                    window.speechSynthesis.speak(new SpeechSynthesisUtterance('')); // trigger voice loading
                } else {
                    window.speechSynthesis.onvoiceschanged();
                }
            },
        }
    </script>

    <style>
        .circle-container {
            position: absolute;
            width: 175px;
            height: 175px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            bottom: -100%;
            left: -100%;
            transition: all 0.5s ease;
        }

        .accessibility-item {
            position: absolute;
            width: 50px;
            height: 50px;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            transform-origin: 150px 150px;
        }

        .accessibility-item .inner-item .tp-wrapper-icon {
            background-color: #F7F7F7;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }



        .accessibility-item.active>div:before {
            content: "";
            position: absolute;
            z-index: 4000;
            top: 0;
            right: 14px;
            background-color: var(--tp-primary);
            width: 8px;
            height: 8px;
            text-align: center;
            color: #fff;
            border-radius: 50%;
        }

        .accessibility-item .feature-levels {
            position: absolute;
            bottom: 3px;
        }

        .accessibility-item .feature-levels>div {
            display: none;
        }

        .accessibility-item.active .feature-levels>div {
            /* padding-top: 12px;*/
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 80px;
        }

        #tp-feature-text-spacing.accessibility-item.active .feature-levels>div,
        #tp-feature-line-height.accessibility-item.active .feature-levels>div,
        #tp-feature-saturation.accessibility-item.active .feature-levels>div,
        #tp-feature-contrast.accessibility-item.active .feature-levels>div {
            width: 60px;
        }

        #tp-feature-dyslexia-friendly.accessibility-item.active .feature-levels>div,
        #tp-feature-reading-mode.accessibility-item.active .feature-levels>div {
            width: 40px;
        }

        .accessibility-item.active .feature-levels>div span {
            background: var(--tp-inactive);
            width: 15%;
            height: 5px;
            border-radius: 10px;
        }

        .accessibility-item.active .feature-levels>div span.active {
            width: 40%;
            background-color: var(--tp-primary);
        }

        #tp-feature-dyslexia-friendly.accessibility-item.active .feature-levels>div span.active,
        #tp-feature-reading-mode.accessibility-item.active .feature-levels>div span.active {
            width: 70%;
        }

        .form-control:focus {
            color: #212529;
            background-color: #fff;
            border-color: var(--tp-primary);
            border: 2px solid var(--tp-primary) !important;
            outline: 0;
            box-shadow: none !important;
        }

        #DictionaryModal .modal_close_dic {
            position: absolute;
            top: -35px;
            right: -35px;
            height: 35px;
            width: 35px;
            background-color: var(--tp-primary);
            border-radius: 50%;
        }

        .dictionfooter .readBtn {
            box-shadow: 0px 0px 6.5px 0px #A4F6EF96;
            border-radius: 10px !important;
            color: #000;
            padding: 10px 20px;
            font-weight: 600;
            font-size: 16px;
            display: flex;
            justify-content: space-between;
            gap: 15px;
            align-items: center;
        }

        .dictionfooter .readBtn .inner {
            width: 35px;
            height: 35px;
            display: block;
            background-color: #DAF3F1;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .dictionfooter .spillBtn {
            /* background: linear-gradient(180deg, #2DADA3 0%, #71DAD2 100%); */
            background-color: var(--tp-primary);
            border-radius: 10px !important;
            color: #fff;
            padding: 10px 20px;
            font-weight: 600;
            font-size: 16px;
            display: flex;
            justify-content: space-between;
            gap: 15px;
            align-items: center;
        }

        .dictionfooter .spillBtn .inner {
            width: 35px;
            height: 35px;
            display: block;
            background-color: #fff;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    </style>
</accessibility-controls>