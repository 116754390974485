<accessibility-sidebar>

    <div class="tp-accessibility-sidebar no-style-scroll" id="tp-accessibility-sidebar">

        <!-- header -->
        <div
            class="tp-accessibility-sidebar-header text-white border-bottom p-4 d-flex align-items-center justify-content-between">
            <p class="font-16 font-w600 mb-0">
                Accessibility Menu (CTRL+U)
            </p>
            <a class="tp-accessibility-sidebar-close rounded-circle d-block" href="javascript:void(0)"
                onclick="{closeAcc}">
                <img src="{window.tamkin_src_base+'/images/icon-close-sidebar.svg'}" alt="">
            </a>
        </div>

        <div id="skeleton" class="skeleton p-3">
            <div class="placeholder rounded-2 w-100 mt-3" style="height: 50px;"></div>
            <div class="placeholder rounded-2 w-100 mt-3" style="height: 50px;"></div>
            <div class="row mx-1 mt-2">
                <div class="col-6 px-1">
                    <div class="placeholder rounded-2 w-100 mt-3" style="height: 100px;"></div>
                </div>
                <div class="col-6 px-1">
                    <div class="placeholder rounded-2 w-100 mt-3" style="height: 100px;"></div>
                </div>
                <div class="col-6 px-1">
                    <div class="placeholder rounded-2 w-100 mt-3" style="height: 100px;"></div>
                </div>
                <div class="col-6 px-1">
                    <div class="placeholder rounded-2 w-100 mt-3" style="height: 100px;"></div>
                </div>
                <div class="col-6 px-1">
                    <div class="placeholder rounded-2 w-100 mt-3" style="height: 100px;"></div>
                </div>
                <div class="col-6 px-1">
                    <div class="placeholder rounded-2 w-100 mt-3" style="height: 100px;"></div>
                </div>
                <div class="col-6 px-1">
                    <div class="placeholder rounded-2 w-100 mt-3" style="height: 100px;"></div>
                </div>
                <div class="col-6 px-1">
                    <div class="placeholder rounded-2 w-100 mt-3" style="height: 100px;"></div>
                </div>
                <div class="col-6 px-1">
                    <div class="placeholder rounded-2 w-100 mt-3" style="height: 100px;"></div>
                </div>
                <div class="col-6 px-1">
                    <div class="placeholder rounded-2 w-100 mt-3" style="height: 100px;"></div>
                </div>
                <div class="col-6 px-1">
                    <div class="placeholder rounded-2 w-100 mt-3" style="height: 100px;"></div>
                </div>
                <div class="col-6 px-1">
                    <div class="placeholder rounded-2 w-100 mt-3" style="height: 100px;"></div>
                </div>
                <div class="col-6 px-1">
                    <div class="placeholder rounded-2 w-100 mt-3" style="height: 100px;"></div>
                </div>
                <div class="col-6 px-1">
                    <div class="placeholder rounded-2 w-100 mt-3" style="height: 100px;"></div>
                </div>
                <div class="col-6 px-1">
                    <div class="placeholder rounded-2 w-100 mt-3" style="height: 100px;"></div>
                </div>
                <div class="col-6 px-1">
                    <div class="placeholder rounded-2 w-100 mt-3" style="height: 100px;"></div>
                </div>
                <div class="col-6 px-1">
                    <div class="placeholder rounded-2 w-100 mt-3" style="height: 100px;"></div>
                </div>
                <div class="col-6 px-1">
                    <div class="placeholder rounded-2 w-100 mt-3" style="height: 100px;"></div>
                </div>
                <div class="col-6 px-1">
                    <div class="placeholder rounded-2 w-100 mt-3" style="height: 100px;"></div>
                </div>
                <div class="col-6 px-1">
                    <div class="placeholder rounded-2 w-100 mt-3" style="height: 100px;"></div>
                </div>
                <div class="col-6 px-1">
                    <div class="placeholder rounded-2 w-100 mt-3" style="height: 100px;"></div>
                </div>
                <div class="col-6 px-1">
                    <div class="placeholder rounded-2 w-100 mt-3" style="height: 100px;"></div>
                </div>
            </div>

        </div>



        <!-- body -->
        <div class="tp-accessibility-sidebar-body tp-style-scroll">
            <div class=" mt-2">
                <div id="tp-accordion">
                    <!-- languages   -->
                    <div if='{languageTranslationActive()}' class="bg-white mx-2 rounded-4">
                        <a class="btn px-3 py-2 d-flex align-items-center justify-content-between" id="g-lang-active"
                            onclick="{toggleLanguage}">
                            <div class="notranslate d-flex g-lang-inside">

                                <div class="rounded-circle tp-icon text-center" if="{state.langModeIcon == 'option2'}">
                                    <span>{(activeLang()).language_code_display}</span>
                                </div>
                                <div class="rounded-circle text-center" if="{state.langModeIcon == 'option1'}">
                                    <img width="30" height="26" src="{loadFile(activeLang()?.image)}" alt="">
                                </div>
                                <div if="{state.langModeIcon == 'option3'}"
                                    class="rounded-circle iconcodelang tp-icon text-center d-flex align-items-center justify-content-center">
                                    <img width="26" height="26" src="{window.tamkin_src_base+'/images/ground.svg'}"
                                        alt="">
                                </div>
                                <span
                                    class="px-2 font-16 font-w400 mb-0 align-content-center">{(activeLang()).language_name}</span>
                            </div>

                            <div>
                                <img class="accordion-arrow" src="{window.tamkin_src_base+'/images/arrow-right.svg'}"
                                    alt="arrow">
                            </div>
                        </a>
                    </div>
                    <div if='{languageTranslationActive()}' id="collapseLang"
                        style="max-height: 350px;overflow-y: auto;"
                        class="no-style-scroll collapse rounded-4 bg-white py-3 mt-2 mx-2"
                        data-bs-parent="#tp-accordion">

                        <div class="form-group mb-3 px-3" id="tp-search-gp">
                            <label for="tp-search"><img class="h-25px"
                                    src="{window.tamkin_src_base+'/images/icon-search.svg'}" alt="search"></label>
                            <input oninput="{searchLanguages}" type="text" id="tp-search"
                                class="form-control rounded-4 ps-5 h-50px" placeholder="Search by Keyword...">
                            <span if="{ document.getElementById('tp-search').value.length> 0}"
                                onclick="{(e) => removeSearchLanguageTrim(e)}" id="tp-search-remove">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18 6L6 18" stroke="#585B5B" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    <path d="M6 6L18 18" stroke="#585B5B" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                </svg>
                            </span>
                        </div>
                        <hr class="mb-2">
                        <div class="list-group list-group-flush notranslate">
                            <a each="{(lang, index) in state.filteredLanguages}" id="g-lang-{lang.language_code}"
                                key="{lang.language_code}" href="javascript:void(0)"
                                onclick="{(e) => changeLanguage(e, lang)}"
                                class="{(activeLang())?.language_code && lang.language_code==(activeLang()).language_code?'active':''} px-4 
                                list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                                <div class="d-flex g-lang-inside">
                                    <div class="rounded-circle tp-icon text-center"
                                        if="{state.langModeIcon == 'option2'}">
                                        <span>{lang.language_code_display}</span>
                                    </div>
                                    <div class="rounded-circle text-center" if="{state.langModeIcon == 'option1'}">
                                        <img width="30" height="26" src="{loadFile(lang.image)}" alt="">
                                    </div>
                                    <div if="{state.langModeIcon == 'option3'}"
                                        class="rounded-circle iconcodelang tp-icon text-center d-flex align-items-center justify-content-center">
                                        <img width="26" height="26" src="{window.tamkin_src_base+'/images/ground.svg'}"
                                            alt="">
                                    </div>

                                    <span
                                        class="px-2 font-16 font-w400 mb-0 align-content-center">{lang.language_name}</span>
                                </div>
                            </a>
                        </div>
                    </div>
                    <!-- profiles -->
                    <accessibility-profiles accessabilityProfiles="{state.accessabilityProfiles}"
                        if='{accessibilityProfilesActive()}'></accessibility-profiles>
                </div>
                <!-- XL -->
                <div if='{oversizedWidgetActive()}' class="bg-white rounded-4 mx-2 mt-2">
                    <div class="px-3 py-2 d-flex align-items-center justify-content-between">
                        <span class="px-2 font-16 font-w400 mb-0 align-content-center">XL oversized widget</span>
                        <ul class="nav nav-pills" id="xlTabs" style="flex-wrap: nowrap" role="tablist">
                            <li class="nav-item">
                                <a class="text-center px-0 nav-link active" style="width: 50px" onclick="{oversized}"
                                    data-bs-toggle="pill" href="#no">No</a>
                            </li>
                            <li class="nav-item">
                                <a class="text-center px-0 nav-link" style="width: 50px" onclick="{oversized}"
                                    data-bs-toggle="pill" href="#yes">Yes</a>
                            </li>
                        </ul>
                    </div>
                </div>

                <accessibility-controls widget_type="full_widget" if='{state.accessabilityFeatures.length}'
                    enabledFeatures="{state.accessabilityFeatures}" enabledFeaturesNames="{state.enabledFeaturesNames}"
                    sidebarPosition="{state.sidebarPosition}" cols="{state.gridColumns}">
                </accessibility-controls>

                <div if="{widgetPositionActive()}" class="mt-2 mx-2">
                    <div id="tp-accordion-position">
                        <div class="bg-white rounded-4">
                            <a id="hideshow" onclick="{(e) => HidePostion(e)}"
                                class="collapse-position-btn btn px-3 py-2 d-flex align-items-center justify-content-between">
                                <div class="d-flex">
                                    <div
                                        class="rounded-circle tp-icon tp-bg-primary text-white d-flex align-items-center justify-content-center">
                                        <img src="{window.tamkin_src_base+'/images/settings.svg'}" alt="">
                                    </div>
                                    <span class="px-2 font-16 font-w400 mb-0 align-content-center">
                                        Move / Hide Accessibility
                                    </span>
                                </div>
                                <div>
                                    <img class="accordion-arrow"
                                        src="{window.tamkin_src_base+'/images/arrow-right.svg'}" alt="arrow">
                                </div>
                            </a>
                        </div>
                        <div id="collapsePosition" class="mt-2 collapse rounded-4 bg-white py-3"
                            data-bs-parent="#tp-accordion-position">
                            <div class="list-group list-group-flush">
                                <label if="{hideItemFromeMoveHide('Right-Side')}" for="rightCheck"
                                    class="d-block px-4 list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                                    <div class="d-flex">
                                        <span class="px-2 font-16 font-w400 mb-0 align-content-center">Right Side</span>
                                    </div>
                                    <div class="form-check">
                                        <input onchange="{sidebarPosition}"
                                            checked="{ !sidebarIsLeft() ? 'checked' : false}" name="acc-position"
                                            class="form-check-input" type="radio" value="right" id="rightCheck">
                                    </div>
                                </label>
                                <label if="{hideItemFromeMoveHide('Left-Side')}" for="leftCheck"
                                    class="d-block px-4 list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                                    <div class="d-flex">
                                        <span class="px-2 font-16 font-w400 mb-0 align-content-center">Left Side</span>
                                    </div>
                                    <div class="form-check">
                                        <input onchange="{sidebarPosition}"
                                            checked="{ sidebarIsLeft() ? 'checked' : false}" name="acc-position"
                                            class="form-check-input" type="radio" value="left" id="leftCheck">
                                    </div>
                                </label>
                                <label if="{hideItemFromeMoveHide('Hide')}" for="hideCheck"
                                    class="d-block px-4 list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                                    <div class="d-flex">
                                        <span class="px-2 font-16 font-w400 mb-0 align-content-center">Hide</span>
                                    </div>
                                    <div class="form-check">
                                        <input onchange="{sidebarPosition}" name="acc-position" class="form-check-input"
                                            type="radio" value="hide" id="hideCheck">
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div if="{state.sidebarPosition === 'hide'}" id="tp-accordion-hide mt-3">
                        <div id="collapseHide" class="collapse show rounded-4 bg-white py-3"
                            data-bs-parent="#tp-accordion-hide">
                            <div class="list-group list-group-flush">
                                <label class="d-flex d-block px-4 pb-2 align-items-center">
                                    <span class="px-2 font-16 font-w500 mb-0 align-content-center">Hide Period</span>
                                </label>
                                <label for="sessionCheck"
                                    class="cursor-pointer d-block px-4 list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                                    <div class="d-flex">
                                        <span class="px-2 font-16 font-w400 mb-0 align-content-center">Current
                                            Session</span>
                                    </div>
                                    <div class="form-check">
                                        <input onchange="{sidebarHide}" name="acc-hide" class="form-check-input"
                                            type="radio" value="session" id="sessionCheck">
                                    </div>
                                </label>
                                <label for="dayCheck"
                                    class="cursor-pointer d-block px-4 list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                                    <div class="d-flex">
                                        <span class="px-2 font-16 font-w400 mb-0 align-content-center">One Day</span>
                                    </div>
                                    <div class="form-check">
                                        <input onchange="{sidebarHide}" name="acc-hide" class="form-check-input"
                                            type="radio" value="day" id="dayCheck">
                                    </div>
                                </label>
                                <label for="weekCheck"
                                    class="cursor-pointer d-block px-4 list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                                    <div class="d-flex">
                                        <span class="px-2 font-16 font-w400 mb-0 align-content-center">One Week</span>
                                    </div>
                                    <div class="form-check">
                                        <input onchange="{sidebarHide}" name="acc-hide" class="form-check-input"
                                            type="radio" value="week" id="weekCheck">
                                    </div>
                                </label>
                                <label for="monthCheck"
                                    class="cursor-pointer d-block px-4 list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                                    <div class="d-flex">
                                        <span class="px-2 font-16 font-w400 mb-0 align-content-center">One Month</span>
                                    </div>
                                    <div class="form-check">
                                        <input onchange="{sidebarHide}" name="acc-hide" class="form-check-input"
                                            type="radio" value="month" id="monthCheck">
                                    </div>
                                </label>
                                <label for="alwaysCheck"
                                    class="cursor-pointer d-block px-4 list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                                    <div class="d-flex">
                                        <span class="px-2 font-16 font-w400 mb-0 align-content-center">Always</span>
                                    </div>
                                    <div class="form-check">
                                        <input onchange="{sidebarHide}" name="acc-hide" class="form-check-input"
                                            type="radio" value="always" id="alwaysCheck">
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- footer -->
        <div class="tp-accessibility-sidebar-footer mt-2" 
        style={`background-image:url(${window.tamkin_src_base}/images/acc-side-footer-bg.svg)`}>
            <div class="d-flex align-items-center justify-content-between h-100 py-2 px-3">
                <a if="{state.showLogo}" class="d-block" href="{props.accessibility_settings?.accessibility_settings?.tamkin_url}"
                    target="_blank">
                    <img style="width: 70px;" src="{window.tamkin_src_base+'/images/acc-side-footer-logo.svg'}" alt="Tamkin">
                </a>
                <div class="text-center"  if="{state.showSupport}">
                    <p class="mb-1 font-14 font-w400">Report a problem</p>
                    <a style="padding: 1px 10px !important;"
                        href="mailto:{props.accessibility_settings?.accessibility_settings?.support_email}"
                        class="btn btn-primary tp-bg-primary border-0 px-0 btn-sm d-flex align-items-center justify-content-center">
                        Report
                    </a>
                </div>
            </div>
        </div>
    </div>

    <script>
        window.tamkin_src_base = "";
        import fileAudio from "/src/player_files/audio/open_audio.mp3";
        const sound = window.tamkin_src_base + fileAudio;
        import AccessibilityControls from '/src/accessibility/full_widget/accessibility-controls.riot';
        import AccessibilityProfiles from '/src/accessibility/full_widget/accessibility-profiles.riot';
        export default {
            components: {
                'accessibility-controls': AccessibilityControls,
                'accessibility-profiles': AccessibilityProfiles
            },
            state: {
                sidebarPosition: '',
                enabledFeatures: [],
                enabledFeaturesNames: [],
                defaultLang: {},
                languages: [],
                filteredLanguages: [],
                accessabilityFeatures: [],
                languageFeature: {},
                activeLanguageIcon: '',
                accessabilityProfiles: [],
                langModeIcon: 'option2',
                gridColumns: 'col-6',
                makeHighlight: true,
                showLogo: true,
                showSupport: false,
            },
            toggleLanguage(e) {
                $(e.target).toggleClass('appear')
                $('#collapseLang').slideToggle(300);
            },
            togglePosition(e) {
                $(e.target).toggleClass('appear')
                $('#collapsePosition').slideToggle(300);
            },
            closeAcc() {
                document.querySelector('#tamkinPlayerApp #tp-accessibility-sidebar').classList.remove('active');
                const soundeffect = localStorage.getItem("enableSoundEffect");
                const audio = new Audio(sound);
                if (soundeffect == 1) {
                    audio.play();
                }
            },
            sendStatus(fauture, enable, value) {
                fetch('https://api.tamkin.app/v1/api/Ai/increaseAccessibility', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        fauture: fauture,
                        enable: enable,
                        value: value
                    })
                })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error(`HTTP error! Status: ${response.status}`);
                        }
                        return response.json();
                    })
                    .then(data => {
                    })
                    .catch(error => {
                        console.error('Fetch error:', error);
                    });
            },
            widgetPositionActive() {
                const activeOr = this.state.enabledFeatures.
                    find(feature => feature.name === "acc-customize-accessibility-mode")
                    ?.features.find(el => el.name == "acc-customize-accessibility-mode-move-/-hide-accessibility-button");

                if (activeOr?.active == 1 && activeOr?.value == 1) {
                    return true
                }
            },
            hideItemFromeMoveHide(title) {
                const activeOr = this.state.enabledFeatures.
                    find(feature => feature.name === "acc-customize-accessibility-mode")
                    ?.features.find(el => el.name == "acc-customize-accessibility-mode-move-/-hide-accessibility")
                    ?.tamkin_option_item_values.find(el => el.title === title);

                if (activeOr?.active == 1) {
                    return true
                }
            },

            accessibilityProfilesActive() {
                const activeOr = this.state.enabledFeatures.
                    find(feature => feature.name === "acc-customize-widget-customization-")
                    ?.features.find(el => el.name == "acc-customize-widget-customization--accessibility-profiles");
                if (this.isFeatureActive('acc-addons-accessibility-profiles') && activeOr?.active == 1 && activeOr?.value == 1) {
                    return true
                } else {
                    return false
                }
            },
            oversizedWidgetActive() {
                return this.isFeatureActive('acc-customize-widget-customization-')
            },
            languageTranslationActive() {
                if (this.state.languageFeature.length) {
                    const inOrout = this.state.languageFeature.find((feature) => feature.name === "acc-customize-translations-button-translation-button-as-default-button");

                    const activeor = this.state.languageFeature.find((feature) => feature.name === 'acc-customize-translations-button-enable-live-site-translations-button');
                    if (activeor?.value == 1 && activeor?.active == 1 && inOrout?.is_selected == 1) {
                        return true
                    }
                }
            },
            isFeatureActive(featureName) {
                const matchingFeature = this.state.enabledFeatures.find(feature => feature.name === featureName);
                const val = matchingFeature?.active == 1 ? true : false;
                return val
            },
            activeLang() {
                let activeLang;
                if (localStorage.getItem('selectedlangbyuser')) {
                    activeLang = JSON.parse(localStorage.getItem('selectedlangbyuser'))
                } else {
                    activeLang = this.state.filteredLanguages.find(lang => lang.active);
                }
                return activeLang ?? this.state.defaultLang
            },


            onBeforeUnMount() {
                localStorage.removeItem("lang")
            },

            async getAccData() {
                await fetch('https://api.tamkin.app/v1/api/Widget/GetAccessibility')
                    .then(response => {
                        if (!response.ok)
                            throw new Error(`HTTP error! Status: ${response.status}`);
                        return response.json();
                    })
                    .then(data => {
                        this.update({
                            languages: data.data.languages,
                            filteredLanguages: data.data.languages,
                            enabledFeatures: data.data.features,
                            enabledFeaturesNames: data.data.features.find(feature => feature.name === 'acc-addons-main-menu')?.features.map(feature => feature.name),
                            languageFeature: data.data.features.find(feature => feature.name === 'acc-customize-translations-button')?.features,
                            sidebarPosition: data.data.features.find(feature => feature.name === 'acc-customize-accessibility-mode')?.features[0]?.value,
                            accessabilityProfiles: data.data.features.find(feature => feature.name === "acc-addons-accessibility-profiles").features?.filter(el => {
                                if (el.value == 1 && el.active == 1) {
                                    return el
                                }
                            }),
                            accessabilityFeatures: data.data.features.find(feature => feature.name === 'acc-addons-main-menu')?.features?.filter(el => {
                                if (el.value == 1 && el.active == 1) {
                                    return el
                                }
                            }),
                        });

                        this.state.defaultLang = this.props.activeLanguage

                        const isShowLogo = this.state.enabledFeatures.
                            find(feature => feature.name === "acc-customize-menu-customization")
                            ?.features.find(el => el.name == "acc-customize-menu-customization-hide-logo")
                        if(isShowLogo.value == 1 && isShowLogo.active == 1){
                            this.state.showLogo = true
                        }
                        const isShowSupport = this.state.enabledFeatures.
                            find(feature => feature.name === "acc-customize-menu-customization")
                            ?.features.find(el => el.name == "acc-customize-menu-customization-hide-support")
                        if(isShowSupport.value == 1 && isShowSupport.active == 1){
                            this.state.showSupport = true
                        }
                        // lang mode icon 
                        const inOrout = this.state.languageFeature.find((feature) => feature.name === "acc-customize-translations-button-translation-button-as-default-button");
                        this.update({
                            langModeIcon: inOrout?.value
                        });

                        if (localStorage.getItem('user_oversized')) {
                            const item = localStorage.getItem('user_oversized');
                            if (item == 'yes') {
                                $('.nav-link[href="#yes"]').addClass('active')
                                $('#tamkinPlayerApp #tp-accessibility-sidebar').addClass('oversized');
                                $('.nav-link[href="#no"]').removeClass('active')
                            } else {
                                $('.nav-link[href="#no"]').addClass('active')
                                $('.nav-link[href="#yes"]').removeClass('active')
                                $('#tamkinPlayerApp #tp-accessibility-sidebar').removeClass('oversized');
                            }
                        }
                        else {
                            // oversized
                            const oversized = this.state.enabledFeatures.
                                find(feature => feature.name === "acc-customize-widget-customization-")
                                ?.features.find(el => el.name == "acc-customize-widget-customization--oversized-widget")
                            if (oversized.active == 1 && oversized.value == 1) {
                                $('.nav-link[href="#yes"]').addClass('active')
                                $('#tamkinPlayerApp #tp-accessibility-sidebar').addClass('oversized');
                                $('.nav-link[href="#no"]').removeClass('active')
                            } else {
                                $('.nav-link[href="#no"]').addClass('active')
                                $('.nav-link[href="#yes"]').removeClass('active')
                                $('#tamkinPlayerApp #tp-accessibility-sidebar').removeClass('oversized');
                            }
                        }


                        // 3 colmns
                        const columns = this.state.enabledFeatures.
                            find(feature => feature.name === "acc-customize-widget-customization-")
                            ?.features.find(el => el.name == "acc-customize-widget-customization--3-column-layout-widget")
                        if (columns.active == 1 && columns.value == 1) {
                            this.state.gridColumns = 'col-4'
                            this.update();
                        }

                        this.update();

                        const checkHighlight = data.data.features.find(feature => feature.name === 'acc-customize-language')?.features
                            .find(el => el.name === 'acc-customize-language-show-language-selector-on-the-widget');

                        if (checkHighlight?.active == 1 && checkHighlight?.value == 1) {
                            this.update({ makeHighlight: true })
                        } else {
                            this.update({ makeHighlight: false })
                        }

                    })
                    .catch(error => { });
            },

            async onMounted(props, state) {
                window.changeLanguageInside = this.changeLanguage.bind(this)
                await this.getAccData();
                document.querySelectorAll("body > *:not(#tamkinPlayerApp):not(tamkin-player-sdk):not(#tamkinPlayerApp *):not(tamkin-player-sdk *)")
                    .forEach(element => {
                        element.addEventListener("click", () => {
                            const sidebar = document.getElementById("tp-accessibility-sidebar");
                            if (sidebar && sidebar.classList.contains("active")) {
                                this.closeAcc();
                            }
                        });

                    });

                // fix: when opening the last accordion collapse in the sidebar it doesn't trigger scrolling to see its contents
                $('#tamkinPlayerApp #tp-accessibility-sidebar .collapse-position-btn').on('click', function (e) {
                    let $sidebarBody = $('#tamkinPlayerApp #tp-accessibility-sidebar .tp-accessibility-sidebar-body');
                    // $sidebarBody.scrollTop($sidebarBody.prop("scrollHeight"));
                    $sidebarBody.animate({
                        scrollTop: $sidebarBody.prop("scrollHeight")
                    }, 500);
                });


            },
            activateLanguage(active_lang) {
                // check lang_name is string or object
                let activeLanguage;
                if (typeof active_lang == 'string') {
                    activeLanguage = this.state.languages.find(l => l.language_code == active_lang);
                } else {
                    activeLanguage = active_lang;
                }

                // set lang as active and deactivate others
                this.state.filteredLanguages.forEach(function (l) { if (l.active) { l.active = false } });
                if (activeLanguage) {
                    activeLanguage.active = true;
                }


                this.update()

                // clone the active language html to inside #g-lang-active (accordion btn)
                let activeLangHTML = $("#g-lang-" + activeLanguage.language_code + " .g-lang-inside").html()
                $("#g-lang-active .g-lang-inside").html(activeLangHTML);

                // close the language accordion
                if ($("#collapseLang").hasClass('show')) {
                    $("#collapseLang").removeClass('show')
                    $("#g-lang-active").attr('aria-expanded', 'false').removeClass('collapsed');
                }

                // rtl sidebar
                setTimeout(() => {
                    if ($('html').hasClass('translated-rtl')) {
                        $('#tamkinPlayerApp #tp-accessibility-sidebar').addClass('rtl');
                        $('#tp-accessibility-lang').addClass('rtl');
                        $('#tamkinPlayerApp #pageStructureModal').addClass('rtl');
                        $('#tamkinPlayerApp #DictionaryModal').addClass('rtl');
                    } else {
                        $('#tamkinPlayerApp #tp-accessibility-sidebar').removeClass('rtl');
                        $('#tp-accessibility-lang').removeClass('rtl');
                        $('#tamkinPlayerApp #pageStructureModal').removeClass('rtl');
                        $('#tamkinPlayerApp #DictionaryModal').removeClass('rtl');
                    }
                }, 1000);

            },
            onUpdated(props, state) {
            },
            searchLanguages(e) {
                let search = $(e.target).val();
                this.update({ filteredLanguages: this.state.languages.filter(lang => lang.language_name.toLowerCase().includes(search.toLowerCase())) });
            },
            removeSearchLanguageTrim(e) {
                $("#tp-search").val('')
                this.update({ filteredLanguages: this.state.languages })
            },

            changeLanguage(e, lang) {
                // console.log("mmmmm",lang);
                
                if(lang.language_code == 'ar' || lang.language_code == 'en'){
                    $('#acc-addons-main-menu-voice-navigation').show()
                }else {
                    $('#acc-addons-main-menu-voice-navigation').hide()
                }
                localStorage.setItem('selectedlangbyuser', JSON.stringify(lang))
                localStorage.setItem("screen_reader_lang", lang.language_code ?? 'en')
                this.sendStatus('acc-customize-language-list-of-languages', 1, lang.language_code)

                if (lang.language_code === localStorage.getItem('initialLang')) {
                    window.fnGoogleDisable()
                } else {

                    window.fnTrans(lang.language_code)
                    $('body *:not(div.notranslate *)').each(function () {
                        if ($(this).children().length === 0 && $(this).text().trim() !== '') {
                            $(this).addClass('tp-lang-highlight');
                        }
                    });
                    // sometimes this doesn't work, so i try to trigger it again depending on html tag has eigher 'translated-ltr' or 'translated-rtl'
                    setTimeout(() => {
                        $('.tp-lang-highlight').removeClass('tp-lang-highlight');
                        if (!$('html').hasClass('translated-rtl') && !$('html').hasClass('translated-ltr')) {
                            $(e.target).trigger('click');
                        }
                    }, 1500);

                }

                this.activateLanguage(lang);
            },
            loadFile(path) {
                return 'https://tamkin.app' + path;
            },
            oversized(e) {
                if ($('.nav-link[href="#yes"]').hasClass('active')) {
                    localStorage.setItem('user_oversized', 'yes')
                    $('#tamkinPlayerApp #tp-accessibility-sidebar').addClass('oversized');
                    this.sendStatus('acc-customize-widget-customization--oversized-widget', 1, true)

                } else if ($('.nav-link[href="#no"]').hasClass('active')) {
                    localStorage.setItem('user_oversized', 'no')
                    $('#tamkinPlayerApp #tp-accessibility-sidebar').removeClass('oversized');
                    this.sendStatus('acc-customize-widget-customization--oversized-widget', 1, false)
                }
            },
            sidebarPosition(e) {
                if ($(e.target).attr('value') == 'left') {
                    this.update({ sidebarPosition: 'left' })
                    localStorage.setItem('tpSidebarPosition', 'left');
                    this.setSidebarPositionDom('left');
                    this.sendStatus('acc-customize-accessibility-mode-move-/-hide-accessibility', 1, 'left')

                } else if ($(e.target).attr('value') == 'right') {
                    this.update({ sidebarPosition: 'right' })
                    localStorage.setItem('tpSidebarPosition', 'right');
                    this.setSidebarPositionDom('right');
                    this.sendStatus('acc-customize-accessibility-mode-move-/-hide-accessibility', 1, 'right')
                } else if ($(e.target).attr('value') == 'hide') {
                    this.update({ sidebarPosition: 'hide' })
                    this.sendStatus('acc-customize-accessibility-mode-move-/-hide-accessibility', 1, 'hide')
                    $("#tamkinPlayerApp .tp-accessibility-sidebar-body").animate({
                        scrollTop: "+=200px"
                    }, 100);
                }
            },
            setSidebarPositionDom(position = 'right') {
                if (position === 'left') {
                    $('#tamkinPlayerApp .accessibility-icon-container-lang').addClass('ltr');
                    $('#tamkinPlayerApp .tp-accessibility-lang').addClass('ltr');
                    $('#tamkinPlayerApp #tp-accessibility-sidebar').addClass('ltr');
                    $('#tamkinPlayerApp .accessibility-icon-container-widget1').addClass('ltr');
                    $('#tamkinPlayerApp .tp-lang-modal-icon').css('left', 0);
                    $('#tamkinPlayerApp .accessibility-icon-container-widget1.ltr .tp-accessibility').css('left', 0);
                    $('#tamkinPlayerApp .tp-lang-modal-icon').css('right', 'auto');
                    $('#tamkinPlayerApp .accessibility-icon-container-widget1.ltr .tp-accessibility').css('right', 'auto');
                } else {
                    $('#tamkinPlayerApp #tp-accessibility-sidebar').removeClass('ltr');
                    $('#tamkinPlayerApp .accessibility-icon-container-widget1').removeClass('ltr');
                    $('#tamkinPlayerApp .tp-lang-modal-icon').css('right', 0);
                    $('#tamkinPlayerApp .accessibility-icon-container-widget1 .tp-accessibility').css('right', 0);
                    $('#tamkinPlayerApp .tp-lang-modal-icon').css('left', 'auto');
                    $('#tamkinPlayerApp .accessibility-icon-container-widget1 .tp-accessibility').css('left', 'auto');
                }
            },
            sidebarHide(e) {
                let duration = $(e.target).val();
                if (duration === 'always') {
                    localStorage.setItem('tpAccHideUntil', 'forever');
                } else {
                    const now = new Date();
                    let tpAccHideUntil = new Date();
                    switch (duration) {
                        case 'session':
                            tpAccHideUntil.setTime(now.getTime() + 30 * 60 * 1000); // 3600000 ms = 1 hour
                            window.location.reload();
                            break;
                        case 'day':
                            tpAccHideUntil.setDate(now.getDate() + 1);
                            break;
                        case 'week':
                            tpAccHideUntil.setDate(now.getDate() + 7);
                            break;
                        case 'month':
                            tpAccHideUntil.setMonth(now.getMonth() + 1);
                            break;
                    }
                    localStorage.setItem('tpAccHideUntil', tpAccHideUntil.toString());
                }

                this.update();

                $('accessibility > div').fadeOut().delay(2000).remove();
            },
            sidebarIsHidden() {
                const hideUntil = localStorage.getItem('tpAccHideUntil');
                const hide = localStorage.getItem('tpSidebarPosition')
                if (hide === 'hide') {
                    return true
                }

                if (!hideUntil) {
                    return false;
                } else {
                    if (new Date() < new Date(hideUntil)) {
                        return true;
                    } else {
                        localStorage.removeItem('tpAccHideUntil');
                        return false;
                    }
                }
            },
            sidebarIsLeft() {
                return this.state.sidebarPosition === 'left' || localStorage.getItem('tpSidebarPosition') === 'left';
            },
            HidePostion(e) {
                this.togglePosition(e)
                // $("#tamkinPlayerApp .tp-accessibility-sidebar-body").animate({
                //     scrollTop: "+=200px"
                // }, 100);
            }

        }
    </script>

    <style>

    </style>
</accessibility-sidebar>