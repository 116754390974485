<principle-component>

  <div id="tamkinPlayerApp">
    <main-player> </main-player>
  </div>


  <script>
    import main from './main.riot';
    export default {
      components: {
        'main-player': main,
      },
      state: {
      },
      onUpdated() {
      },

    }
  </script>


</principle-component>