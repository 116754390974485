import "./src/player_files/bootstrap-scoped.css";
import "@riotjs/hot-reload";

window.tamkin_src_base = "";
if (process.env.NODE_ENV === "production") {
  window.tamkin_src_base = "https://cdn.tamkin.app";
}

import * as riot from "riot";
import prin from "./src/principalApp.riot";
riot.register("tamkin-player-sdk", prin);
riot.mount("tamkin-player-sdk");
