<main-component>
    <div>
        <div class="player-icon-container" if="{state.enablePlayer == true}">
            <div class="tp-hero-talk">
                <img src={loader} id="image_pricipal">
            </div>
            <div class="tp-hero-talk-text">
                { (state.player_settings?.icon_text) }
            </div>
        </div>
      <!-- <div class="notranslate">
        {state.enableWidegt} 
        {state.enablePlayer} 
      </div> -->
       
       <!-- { state.playerContrastIconReplaced }  -->
       <!-- {state.playerContrast }  -->
       <!-- {state.playerContrastIconInit} -->
        <div class="tp-talk-card" id="player-card" if="{state.enablePlayer == true}">
            <img style="{applyFilters()}" width="25" src="{window.tamkin_src_base+'/images/close.svg'}"
                class="icon-close" id="icon-close-main">

            <div class="talk-content">
                <div id="loader" class="when-empty-card">
                    <lottie-player src={createBlob(state.handload)} background="transparent" speed="1" direction="1"
                        mode="normal" loop autoplay></lottie-player>
                    <div class="text-develop-container">
                        <a target="_blank" href={state.player_settings.developed_by_url} class="text-develop">{
                            (state.player_settings?.developed_by_text) } <img width="15" height="15"
                                style="{applyFilters()} ;margin-top: 3px"
                                src={loadFile(state.player_settings.button_tamkin_icon)}>
                        </a>
                    </div>
                </div>
                <div id="loader-hand">
                    <lottie-player src={createBlob(state.twohand)} background="transparent" speed="1" direction="1"
                        mode="normal" loop autoplay></lottie-player>
                </div>
                <div class="man-container">
                    <tamkin-sdk
                        if="{state.playerBackground && state.playerContrastIconReplaced && state.playerContrast  && state.playerContrastIconInit}"
                        playerBackground="{state.playerBackground}" playerContrast="{state.playerContrast}"
                        playerContrastIconReplaced="{state.playerContrastIconReplaced}"
                        playerContrastIconInit="{state.playerContrastIconInit}"
                        playerHighlight="{state.playerHighlight}" charachterType="{state.charachterType}">
                    </tamkin-sdk>
                </div>
                <div class="btns-action" id="links-container">
                    <div id="loader-get-translation" class="">
                        <lottie-player src={createBlob(state.loading)} background="transparent" speed="1" direction="1"
                            mode="normal" loop autoplay></lottie-player>
                    </div>

                    <span class="menu-link just-a-placeholder"></span>

                    <span class="menu-link btn-play {state.loading_settings ? 'tp-loader-circle' : ''}" id="playButt" >
                        <img id="init_icon" if="{state.player_settings.play_grey_icon}" src={loadFile(state.player_settings.play_grey_icon)}>
                        <img style="{applyFilters()}" id="play_icon"
                            src={loadFile(state.player_settings.play_grey_icon)}>
                        <img style="{applyFilters()}" id="pause_icon" src={loadFile(state.player_settings.pause_icon)}>
                    </span>
                    <span class="menu-link btn-rate-translate {state.loading_settings ? 'tp-loader-circle' : ''}">
                        <img id="icon-rate-before" if="{state.player_settings?.rate_grey_icon}" src={loadFile(state.player_settings?.rate_grey_icon)}>
                        <img id="icon-rate-after" src={loadFile(state.player_settings.rate_icon)}
                            style="{applyFilters()}">
                    </span>
                    <span class="menu-link btn-change-speed">
                        <span>1.0</span>
                    </span>
                    <span class="menu-link btn-tutorial {state.loading_settings ? 'tp-loader-circle' : ''}">
                        <img id="open-tutorial" if="{state.player_settings.learn_icon}" src={loadFile(state.player_settings.learn_icon)} class="tamkin-logo"
                            style="{applyFilters()}">
                        <img id="close-tutorial" src={loadFile(state.player_settings.close_icon)}
                            style="{applyFilters()}">
                    </span>
                    <span class="menu-link btn-settings {state.loading_settings ? 'tp-loader-circle' : ''}">
                        <img id="open-settings" if="{state.player_settings.settings_icon}" src={loadFile(state.player_settings.settings_icon)}
                            style="{applyFilters()}">
                        <img id="close-settings" src={loadFile(state.player_settings.close_icon)}
                            style="{applyFilters()}">
                    </span>
                </div>
                <div class="btns-config" id="config-btns-id" style="display: none;">
                    <span class="menu-link just-a-placeholder"></span>
                    <span class="menu-link">
                        <img style="{applyFilters()}" src={loadFile(state.player_settings.contrast_day_icon)}>
                    </span>
                    <span class="menu-link">
                        <img style="{applyFilters()}" src={loadFile(state.player_settings.view_icon)}>
                    </span>
                    <span class="menu-link">
                        <img style="{applyFilters()}" src={loadFile(state.player_settings.position_icon)}>
                    </span>
                    <span class="menu-link">
                        <img style="{applyFilters()}" src={loadFile(state.player_settings.keyboard_icon)}>
                    </span>
                </div>

                <div class="btns-info" id="info-btns-id" style="display: none;">
                    <span class="menu-link just-a-placeholder"></span>
                    <span class="menu-link">
                        <img style="{applyFilters()}" src={loadFile(state.player_settings.tutorial_icon)}>
                    </span>
                    <span class="menu-link">
                        <img style="{applyFilters()}" src={loadFile(state.player_settings.about_icon)}>
                    </span>
                </div>

                <div class="change-speed-card animated">
                    { ("Speed:") } <br> <span></span>
                </div>
                <div class="tutorial-card animated" id="tutorial-card-id" style="display: none;">
                    <div class="translate-item" id="tutorial">
                        <div class="icon"><img style="{applyFilters()}"
                                src={loadFile(state.player_settings.tutorial_icon)}></div>
                        { (state.player_settings?.tutorial_text) }
                    </div>
                    <div class="translate-item" id="about">
                        <div class="icon"><img height="25" style="{applyFilters()}"
                                src={loadFile(state.player_settings.about_icon)}></div>
                        { (state.player_settings?.about_text) }
                    </div>
                </div>
          
                <div class="settings-card animated" id="settings-card-id" style="display: none;">
                    <div class="settings-item" id="set-contrast" if="{state.enableContrast}">
                        <div class="icon">
                            <img style="{applyFilters()}" src={loadFile(state.playerContrastIconInit)}>
                        </div>
                        Contrast
                    </div>
                    <div class="settings-item" id="set-background" if="{state.enableBackground}">
                        <div class="icon">
                            <img style="{applyFilters()}" src={loadFile(state.playerBackgroundIcon)}>
                        </div>
                        View
                    </div>
                    <div class="settings-item" id="set-position" if="{state.enablePosition}">
                        <div class="icon">
                            <img style="{applyFilters()}" src={loadFile(state.playerModeIconLeft)}>
                        </div>
                        Position
                    </div>
                    <div class="settings-item" id="set-keyboard" if="{state.enableKeyboard}">
                        <input type="text" style="display: none;" id="tamkinInitialKb">
                        <div class="icon">
                            <img style="{applyFilters()}" src={loadFile(state.player_settings?.keyboard_icon)}>
                        </div>
                        Keyboard
                    </div>

                </div>

                <div class="change-background-card animated"></div>

            </div>
            <div class="about-card animated" id="about-content" style="display: none;">
                <img width="25" style="{applyFilters()}" src="{window.tamkin_src_base+'/images/close.svg'}"
                    class="icon-close" id="icon-close-about">
                <div class="icon">
                    <img style="{applyFilters()}" src={loadFile(state.player_settings.about_top_icon)}
                        class="tamkin-logo">
                </div>
                <h3 class="text-main">{ (state.player_settings?.about_title) }</h3>
                <p>{ (state.player_settings?.about_description) }</p>
                <div class="btns-about-bottom asl-link">
                    <button class="btn btn-bg btn-asl">
                        <img src={loadFile(state.player_settings?.button_asl_icon)}>
                        { (state.player_settings?.button_asl_text) }
                    </button>
                    <a target="_blank" href={state.player_settings.developed_by_url} class="btn btn-border btn-tamkin">
                        <img width="40" style="{applyFilters()}"
                            src={loadFile(state.player_settings.button_tamkin_icon)}>
                        { (state.player_settings?.button_tamkin_text) }
                    </a>
                </div>
            </div>
            <div class="about-card animated" id="tutorial-content" style="display: none;">
                <img style="{applyFilters()}" width="25" src="{window.tamkin_src_base+'/images/close.svg'}"
                    class="icon-close" id="icon-close-tutorial">
                <div class="icon">
                    <img style="{applyFilters()}" src={loadFile(state.player_settings?.tutorial_top_icon)}
                        class="tamkin-logo">
                </div>
                <h3>{ (state.player_settings?.tutorial_title) }</h3>
                <p style="margin-bottom: 40px;">{ (state.player_settings?.tutorial_description) }</p>
                <div class="btns-about-bottom menu-trans">
                    <button class="btn btn-bg" id="btn-tutorial-menu">
                        <img src={loadFile(state.player_settings?.button_menu_icon)}> {
                        (state.player_settings?.button_menu_text) }
                    </button>
                    <button class="btn btn-bg" id="btn-tutorial-translation">
                        <img src={loadFile(state.player_settings?.button_translation_icon)}> {
                        (state.player_settings?.button_translation_text) }
                    </button>

                </div>
            </div>
            <div class="about-card animated" id="rate-translate-content" style="display: none;">
                <img style="{applyFilters()}" width="25" src="{window.tamkin_src_base+'/images/close.svg'}"
                    class="icon-close" id="icon-close-rate">
                <div class="icon">
                    <img style="{applyFilters()}" src={loadFile(state.player_settings?.rating_top_icon)}
                        class="tamkin-logo">
                </div>
                <h3>{ (state.player_settings?.rating_title) }</h3>
                <p>{ (state.player_settings?.rating_description) }</p>
                <div class="rate-translate">
                    <div class="check-rate">
                        <div class="item">
                            <input type="radio" class="btn-check" name="options" id="option1" autocomplete="off">
                            <label class="btn" for="option1">
                                <img class="rate-un" src={loadFile(state.player_settings?.poor_grey_icon)}>
                                <img style="{applyFilters()}" class="rate-hover"
                                    src={loadFile(state.player_settings?.poor_icon)}>
                                <p>{ (state.player_settings?.poor_text) }</p>
                            </label>
                        </div>
                        <div class="item">
                            <input type="radio" class="btn-check" name="options" id="option2" autocomplete="off">
                            <label class="btn" for="option2">
                                <img class="rate-un" src={loadFile(state.player_settings?.good_grey_icon)}>
                                <img style="{applyFilters()}" class="rate-hover"
                                    src={loadFile(state.player_settings?.good_icon)}>
                                <p>{ (state.player_settings?.good_text) }</p>
                            </label>
                        </div>

                    </div>
                    <button class="btn btn-bg mt-3" id="confirm-rate">
                        { (state.player_settings?.rating_button_text)}
                    </button>
                </div>

            </div>

            <div class="" id="finish-card">
                <div id="loader-finish-rate" class="">
                    <lottie-player src={createBlob(state.hearts)} background="transparent" speed="1" direction="1"
                        mode="normal" loop autoplay></lottie-player>
                </div>
                <div class="about-card" id="finish-rate">
                    <p>{ (state.player_settings?.rating_thanks_text) }</p>
                </div>
            </div>

            <side-menu message="{ state.player_settings }"></side-menu>
        </div>
        <!-- {state.accessibility_type}
        {state.enableWidegt} -->
        <accessibility-full-widget accessibility_icon="{ state.accessibility_icon}"
            sidebarPosition="{ state.sidebarPosition }"
            if="{state.enableWidegt && state.accessibility_type == 'full-widget'}"
            accessibility_settings="{ state.player_settings }" activeLanguage="{state.defaultLang}">
        </accessibility-full-widget>
        <accessibility-mini-widget accessibility_icon="{state.accessibility_icon}"
            sidebarPosition="{ state.sidebarPosition }"
            if="{state.enableWidegt && state.accessibility_type == 'mini-widget'}"
            accessibility_settings="{ state.player_settings }">
        </accessibility-mini-widget>

        <accessibility-minuscule-widget accessibility_icon="{state.accessibility_icon}"
            sidebarPosition="{ state.sidebarPosition }"
            if="{state.enableWidegt && state.accessibility_type == 'minuscule-widget'}"
            accessibility_settings="{ state.player_settings }">
        </accessibility-minuscule-widget>

        <accessibility-round-widget accessibility_icon="{state.accessibility_icon}"
            sidebarPosition="{ state.sidebarPosition }"
            if="{state.enableWidegt && state.accessibility_type == 'round-widget'}"
            accessibility_settings="{ state.player_settings }">
        </accessibility-round-widget>

        <language-widget mainlanguages="{state.languages}" widgetType="{state.accessibility_type}"
            sidebarPosition="{ state.sidebarPosition }" langModeIcon="{state.langModeIcon}"
            activeLanguage="{state.defaultLang}" isshowLogo="{state.showLogo}" isshowSupport="{state.showSupport}"
            if="{ state.showLang == true && state.sidebarPosition !== 'hide' && state.defaultLang}"
            accessibility_settings="{ state.player_settings }">
        </language-widget>

    </div>

    <script>
        import "/src/style.css";
        import "/src/player_files/talk-plugin.css";
        import "/src/player_files/keyboard.css";
        // jQuery is now available in all project see webpack.config.js:126
        //import $ from 'jquery';
        //window.jQuery = window.$ = $;
        //jQuery.noConflict();
        window.tamkin_src_base = "";
        const importAllImages = (requireContext) => requireContext.keys().map(requireContext);
        const images = importAllImages(require.context('/src/player_files/accessibility/img', false, /\.(png|jpe?g|svg)$/));
        import fileAudio from "/src/player_files/audio/open_audio.mp3";
        const sound = window.tamkin_src_base + fileAudio;
       
        if (!customElements.get('lottie-player')) {
            import("@lottiefiles/lottie-player");
        }
        import Cookies from 'js-cookie'
        import * as riot from "riot";
        import loader from "./player_files/images/h.svg"
        import checkOnIcon from './player_files/images/check_on.svg'
        import SideMenu from './Side-Menu.riot'
        import handload from './player_files/json/handload.json';
        import hearts from './player_files/json/hearts.json';
        import loading from './player_files/json/loading.json';
        import twohand from './player_files/json/twohand.json';
        import AccessibilityFullWidget from '/src/accessibility/full_widget/accessibility.riot';
        import AccessibilityMiniWidget from '/src/accessibility/mini_widget/accessibility.riot';
        import AccessibilityMinusculeWidget from '/src/accessibility/minuscule_widget/accessibility.riot';
        import AccessibilityRoundWidget from '/src/accessibility/round_widget/accessibility.riot';
        import LanguageWidget from '/src/accessibility/language_widget/lang.riot';
        import tinycolor from "tinycolor2";
        import { Color, Solver } from '/src/player_files/accessibility/image_color.js';

        import TaminSDK from './tamkin-sdk.riot';
        const componentElement = document.querySelector('tamkin-player-sdk');
        let version;
        if (componentElement) {
            version = componentElement.getAttribute('with-player');
        }

        export default {
            components: {
                'tamkin-sdk': TaminSDK,
                'Side-Menu': SideMenu,
                'accessibility-full-widget': AccessibilityFullWidget,
                'accessibility-mini-widget': AccessibilityMiniWidget,
                'accessibility-minuscule-widget': AccessibilityMinusculeWidget,
                'accessibility-round-widget': AccessibilityRoundWidget,
                'language-widget': LanguageWidget,
            },
            state: {
                player_settings: {},
                with_player: version,
                handload: handload,
                hearts: hearts,
                loading: loading,
                twohand: twohand,
                accessibility_type: '',
                showLang: false,
                languageFeatures: {},
                enabledFeatures: [],
                sidebarPosition: '',
                langModeIcon: '',
                accessibility_icon: `./player_files/accessibility/img/accessibility.svg`,
                enableWidegt: false,
                enablePlayer: false,
                enableColor2: false,
                enablePlayerColor2: false,
                languages: [],
                playerModeIconRight: null,
                playerModeIconLeft: null,
                playerBackground: null,
                playerBackgroundIcon: null,
                playerContrastIconInit: '',
                playerContrastIconReplaced: '',
                playerContrast: null,
                playerHighlight: false,
                enableKeyboard: false,
                enableBackground: false,
                enableContrast: false,
                enablePosition: false,
                defaultLang: null,
                showlLogo: false,
                showSupport: false,
                currentLanguage: null,
                charachterType: null,
                loading_settings : false
            },
            loadFile(path) {
                return 'https://tamkin.app' + path;
            },

            createBlob(json) {
                const blob = new Blob([JSON.stringify(json)], { type: 'application/json' });
                // Create a URL object from the Blob
                const url = URL.createObjectURL(blob);
                return url;
            },

            async onMounted(props, state) {
                var $this = this;
                window.fnGoogleDisable = this.fnGoogleDisable.bind(this)
                window.fnTrans = this.fnTrans.bind(this)
                this.update({
                    currentLanguage: await $this.getCurrentLang(),
                })
                await this.getPlayerCharacterType()
                await Promise.race([
                    this.getPlayerSettings(),
                    this.getAccData(),
                ]);

                this.update()

                window.tmk_trans_slow = ("Slow");
                window.tmk_trans_normal = ("Normal");
                window.tmk_trans_fast = ("Fast");
                window.tmk_trans_access_link = ("Access Link");

                function loadFile(path) {
                    return 'https://tamkin.app' + path;
                }

                window.Cookies = Cookies;
                // $this.fnGoogleDisable();
            },

            onUpdated() {
                if (this.state.enableColor2) {
                    this.makeGradiant();
                }
                if (this.state.enablePlayerColor2) {
                    document.querySelector(".tp-hero-talk").style.background = "linear-gradient(180deg,var(--tp-player-primary) 0%,var(--tp-player-primary-grad) 100%)"
                    document.querySelector(".tp-hero-talk-text").style.background = "linear-gradient(180deg,var(--tp-player-primary) 0%,var(--tp-player-primary-grad) 100%)"
                }

                const imageUrl = "/src/player_files/images/check_on.svg"; // Replace with your image path
                // Create a style element if it doesn't exist
                if (!$("#dynamicStyles").length) {
                    $("head").append("<style id='dynamicStyles'></style>");
                }
                // Add or update the CSS rule for the :before pseudo-element
                $("#dynamicStyles").html(`
                    .accessibility-icon-container-widget1 .tp-accessibility.active:before {
                        background-image: url('${imageUrl}');
                    }
                `);

            },

            fnGoogleDisable() {
                Cookies.remove('googtrans'); 
                document.getElementsByTagName("body")[0].classList.add("notranslate");
                try {
                    document.getElementById(':2.container').contentWindow.document.getElementById(':2.restore').click();
                }
                catch (e) { }

                try {
                    document.getElementById(':2.container').contentWindow.document.getElementById(':2.restore').click();
                }
                catch (e) { }
                try {
                    document.getElementById('google_translate_element').remove();
                }
                catch (e) { }

                if ($('html').hasClass('translated-rtl')) { 
                    $('#tamkinPlayerApp #tp-accessibility-sidebar').addClass('rtl');
                    $('#tamkinPlayerApp #pageStructureModal').addClass('rtl');
                    $('#tamkinPlayerApp #DictionaryModal').addClass('rtl');
                    $('#tp-accessibility-lang').addClass('rtl');
                }else {
                    $('#tamkinPlayerApp #tp-accessibility-sidebar').removeClass('rtl');
                    $('#tp-accessibility-lang').removeClass('rtl'); 
                    $('#tamkinPlayerApp #pageStructureModal').removeClass('rtl');
                    $('#tamkinPlayerApp #DictionaryModal').removeClass('rtl');
                }

            },

            fnTrans(lan) {
                try {
                    window.googleTranslateElementInit = function () {
                        var google_translate_element = document.createElement('div');
                        google_translate_element.setAttribute('id', "google_translate_element");
                        document.body.prepend(google_translate_element);

                        new google.translate.TranslateElement({
                            layout: google.translate.TranslateElement.InlineLayout.SIMPLE
                        }, 'google_translate_element');
                    };

                    this.fnGoogleDisable();
                    document.getElementsByTagName("body")[0].classList.remove("notranslate");

                    var google_translate = document.createElement('script');
                    google_translate.onload = function () { };
                    google_translate.setAttribute('type', "text/javascript");
                    google_translate.setAttribute('src', "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit");
                    document.body.appendChild(google_translate);

                    function setCookie(key, value, expiry) {
                        var expires = new Date();
                        expires.setTime(expires.getTime() + (expiry * 24 * 60 * 60 * 1000));
                        document.cookie = key + '=' + value + ';expires=' + expires.toUTCString();
                    }
                  
                    setCookie('googtrans', '/xx/' + lan, 1);

                    setTimeout(() => {
                        if ($('html').hasClass('translated-rtl') || lan == 'ar') {                         
                            $('#tamkinPlayerApp #tp-accessibility-sidebar').addClass('rtl');
                            $('#tp-accessibility-lang').addClass('rtl');
                            $('#tamkinPlayerApp #pageStructureModal').addClass('rtl');
                            $('#tamkinPlayerApp #DictionaryModal').addClass('rtl');
                        }else {
                            $('#tamkinPlayerApp #tp-accessibility-sidebar').removeClass('rtl');
                            $('#tp-accessibility-lang').removeClass('rtl'); 
                            $('#tamkinPlayerApp #pageStructureModal').removeClass('rtl');
                            $('#tamkinPlayerApp #DictionaryModal').removeClass('rtl');
                        }
                    }, 1000);
                    
                }
                catch (e) {
                    console.log("errrr", e);
                }

            },

            async handleAccLanguage(payload) {
                var $this = this;
                var currentLang = $this.state.currentLanguage
                
                localStorage.setItem('initialLang', currentLang)

                const checkDefault = payload.features.find(feature => feature.name === "acc-customize-translations-button")
                    .features.find((el) => el.name === "acc-customize-translations-button-translation-button-as-default-button");
                const checkAbove = payload.features.find(feature => feature.name === "acc-customize-translations-button")
                    .features.find((feature) => feature.name === "acc-customize-translations-button-position-translation-button-above");
                const isLiveTranslationActive = payload.features.find(feature => feature.name === "acc-customize-translations-button")
                    .features.find((feature) => feature.name === 'acc-customize-translations-button-enable-live-site-translations-button');
                const isWidgetEnable = payload.features.find(feature => feature.name === "acc-setting-general-settings")
                    .features.find((feature) => feature.name === "acc-setting-general-settings-widget-enabled-on-this-site");
                if (isWidgetEnable?.value == 1  && isWidgetEnable?.active ==1  && 
                isLiveTranslationActive?.value == 1 && isLiveTranslationActive?.active == 1 
                && (checkDefault?.is_selected == 1 ||checkAbove?.is_selected == 1 )) {
                    
                    if (localStorage.getItem('selectedlangbyuser')) {
                        const lang = JSON.parse(localStorage.getItem('selectedlangbyuser'))
                    
                        if (currentLang !== lang.language_code) {
                            $this.fnTrans(lang.language_code)
                        } 
                        localStorage.setItem("lang", lang.language_code || 'en')
                        $this.update({ defaultLang: lang })
                    } else {
                        const detectLang = payload.features.find(feature => feature.name === 'acc-customize-language')?.features
                            .find(el => el.name === 'acc-customize-language-list-of-languages');

                        if (detectLang.active == 1 && detectLang.value == "auto detect language") {
                            const filteredLang = payload.languages.find((el) => el.language_code === currentLang)
                            $this.fnGoogleDisable()
                            localStorage.setItem("lang", currentLang)
                            $this.update({ defaultLang: filteredLang })
                            localStorage.setItem('langToReset',JSON.stringify(filteredLang))
                        }
                        else if (detectLang.active == 1 && detectLang.value !== 'auto detect language') {
                            const filteredLang = payload.languages.find((el) => el.language_code === detectLang.value);
                            if (filteredLang.language_code !== currentLang) {
                                $this.fnTrans(filteredLang.language_code)
                                localStorage.setItem("lang", filteredLang.language_code)
                                $this.update({ defaultLang: filteredLang })
                                localStorage.setItem('langToReset',JSON.stringify(filteredLang))
                            } else {
                                $this.fnGoogleDisable()
                                localStorage.setItem("lang", filteredLang.language_code)
                                $this.update({ defaultLang: filteredLang })
                                localStorage.setItem('langToReset',JSON.stringify(filteredLang))
                            }
                        }
                        
                    }
                    
                    setTimeout(() => {

                        if ($('html').hasClass('translated-rtl')) {
                            $('#tamkinPlayerApp #tp-accessibility-sidebar').addClass('rtl');
                            $('#tp-accessibility-lang').addClass('rtl');
                            $('#tamkinPlayerApp #pageStructureModal').addClass('rtl');
                            $('#tamkinPlayerApp #DictionaryModal').addClass('rtl');
                        }else {
                            $('#tamkinPlayerApp #tp-accessibility-sidebar').removeClass('rtl');
                            $('#tp-accessibility-lang').removeClass('rtl'); 
                            $('#tamkinPlayerApp #pageStructureModal').removeClass('rtl');
                            $('#tamkinPlayerApp #DictionaryModal').removeClass('rtl');
                        }
                    }, 3000);
                }
            },


            async getCurrentLang() {
                let lang = 'en'
                await fetch('https://api.tamkin.app/v1/api/Widget/LanguageDetector', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        text: document.title || document.querySelector('meta[name="description"]')?.content ||
                            document.querySelector('h1')?.textContent || document.querySelector('h2')?.textContent ||
                            document.querySelector('h3')?.textContent || document.querySelector('p')?.textContent
                    })
                })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error(`HTTP error! Status: ${response.status}`);
                        }
                        return response.json();
                    })
                    .then(data => {
                        if (data.data) {
                            localStorage.setItem("lang", data.data)
                            lang = data.data
                        } else {
                            const langdetect = document.documentElement.getAttribute('lang').split('-')[0] || 'en'
                            console.log("langdetect",langdetect);
                            
                            localStorage.setItem("lang", lang)
                            lang = langdetect
                        }
                    })
                    .catch(error => {
                        console.error('Fetch error:', error);
                    });

                return lang
            },

            async getAccData() {
                var $this = this;
                const tamkinPlayerApp = document.getElementById('tamkinPlayerApp');
                try {
                    await fetch('https://api.tamkin.app/v1/api/Widget/GetAccessibility')
                        .then(response => {
                            if (!response.ok) {
                                throw new Error(`HTTP error! Status: ${response.status}`);
                            }
                            return response.json();
                        })
                        .then(async(data) => {
                            this.update({
                                languages: data.data.languages,
                                sidebarPosition: data.data.features.find(feature => feature.name === 'acc-customize-accessibility-mode')?.features[0]?.value,
                                enabledFeatures: data.data.features,
                                languageFeatures: data.data.features.find(feature => feature.name === 'acc-customize-translations-button')?.features,
                                accessibility_icon: this.loadFile(data.data.features.find(feature => feature.name === 'acc-customize-button-type').features.
                                    find(feature => feature.name === "acc-customize-button-type-button-shape").tamkin_option_item_values
                                    .find(el => el.value == data.data.features.find(feature => feature.name === 'acc-customize-button-type').features.
                                        find(feature => feature.name === "acc-customize-button-type-button-shape").value).icon),
                                accessibility_type: data.data.features.find(feature => feature.name === 'acc-customize-widget-type')?.features.
                                    find(feature => feature.name === "acc-customize-widget-type-widget-style").value,
                            });
                            

                       

                            const isShowLogo = this.state.enabledFeatures.
                                find(feature => feature.name === "acc-customize-menu-customization")
                                ?.features.find(el => el.name == "acc-customize-menu-customization-hide-logo")
                            if(isShowLogo.value == 1 && isShowLogo.active == 1){
                                $this.state.showLogo = true
                            }
                            const isShowSupport = this.state.enabledFeatures.
                                find(feature => feature.name === "acc-customize-menu-customization")
                                ?.features.find(el => el.name == "acc-customize-menu-customization-hide-support")
                            if(isShowSupport.value == 1 && isShowSupport.active == 1){
                                $this.state.showSupport = true
                            }
                            // hadle lang icon 
                            if (this.state.languageFeatures.length) {
                                const inOrout = this.state.languageFeatures.find((feature) => feature.name === 'acc-customize-translations-button-position-translation-button-above');
                                const activeor = this.state.languageFeatures.find((feature) => feature.name === 'acc-customize-translations-button-enable-live-site-translations-button');
                                if (activeor.value == 1 && activeor.active == 1 && inOrout?.is_selected == '1') {
                                    this.update({
                                        showLang: true,
                                        langModeIcon: inOrout?.value
                                    });
                                }
                            }
                       
                            if (!localStorage.getItem("tpSidebarPosition")) {
                                localStorage.setItem("tpSidebarPosition", data.data.features.find(feature => feature.name === 'acc-customize-accessibility-mode')?.features
                                .find(el => el.name === "acc-customize-accessibility-mode-move-/-hide-accessibility")?.value)
                                localStorage.setItem("inittpSidebarPosition", data.data.features.find(feature => feature.name === 'acc-customize-accessibility-mode')?.features
                                .find(el => el.name === "acc-customize-accessibility-mode-move-/-hide-accessibility")?.value)
                            }
                            // set colors 
                            const color1 = data.data.features.find(feature => feature.name === 'acc-customize-button-color')?.features[0].value.split(',')[0];
                            const color2 = data.data.features.find(feature => feature.name === 'acc-customize-button-color')?.features[0].value.split(',')[1];
                            tamkinPlayerApp.style.setProperty('--tp-primary', color1);

                            if (color2) {
                                if (color1 === color2) {
                                    let lighterColor = tinycolor(color1).setAlpha(0.2).toString();
                                    tamkinPlayerApp.style.setProperty('--tp-primary-grad', lighterColor);
                                    tamkinPlayerApp.style.setProperty('--tp-primary-light', lighterColor);
                                    $(".highlight").css("backgroundColor", lighterColor)
                                    this.update({ enableColor2: true })
                                } else {
                                    tamkinPlayerApp.style.setProperty('--tp-primary-grad', color2);
                                    tamkinPlayerApp.style.setProperty('--tp-primary-light', color2);
                                    $(".highlight").css("backgroundColor", color2)
                                    this.update({ enableColor2: true })
                                }
                            } else {
                                let lighterColor = tinycolor(color1).setAlpha(0.2).toString();
                                tamkinPlayerApp.style.setProperty('--tp-primary-grad', lighterColor);
                                tamkinPlayerApp.style.setProperty('--tp-primary-light', lighterColor);
                                $(".highlight").css("backgroundColor", lighterColor);
                            }
                            // hadle size of widget 
                            const size = data.data.features.find(feature => feature.name === 'acc-customize-button-type')?.features.find(el => el.name == 'acc-customize-button-type-button-size').value;
                            tamkinPlayerApp.style.setProperty('--tp-accessibility-icon-width', `${size}px`);
                            tamkinPlayerApp.style.setProperty('--tp-accessibility-icon-height', `${size}px`);
                            tamkinPlayerApp.style.setProperty('--tp-accessibility2-icon-width', `${size}px`);
                            tamkinPlayerApp.style.setProperty('--tp-accessibility2-icon-height', `${size}px`);
                            tamkinPlayerApp.style.setProperty('--tp-accessibility3-icon-width', `${size}px`);
                            tamkinPlayerApp.style.setProperty('--tp-accessibility3-icon-height', `${size}px`);
                            tamkinPlayerApp.style.setProperty('--tp-accessibility4-icon-width', `${size}px`);
                            tamkinPlayerApp.style.setProperty('--tp-accessibility4-icon-height', `${size}px`);
                            $(".iconCode").css("fontSize", `${size / 2}px`)


                            if (window.innerWidth > 500) {
                                const desktopSetting = data.data.features.find(feature => feature.name === 'acc-setting-general-settings')?.features
                                    .find(el => el.name === 'acc-setting-general-settings-widget-enabled-on-this-site');
                                if (desktopSetting?.active == 1 && desktopSetting?.value == 1 && this.state.sidebarPosition !== 'hide') {
                                    this.update({ enableWidegt: true })
                                }
                            } else {
                                const mobileSetting = data.data.features.find(feature => feature.name === 'acc-setting-general-settings')?.features
                                    .find(el => el.name === 'acc-setting-general-settings-widget-enabled-on-mobile');
                                if (mobileSetting?.active == 1 && mobileSetting?.value == 1 && this.state.sidebarPosition !== 'hide') {
                                    this.update({ enableWidegt: true })
                                }
                            }   
                            await $this.handleAccLanguage(data.data)                         
                            // widget location
                            if (window.innerWidth > 500) {
                                const obj = $this.state.enabledFeatures.
                                    find(feature => feature.name === "acc-customize-button-location")
                                    ?.features.find(el => el.name == "acc-customize-button-location-button-location-desktop");
                                const desktopLocation = obj.value
                                const tamkinPlayerApp = document.getElementById('tamkinPlayerApp');
                                
                                if (obj.active == 1) {   
                                    if (desktopLocation === 'postion1') {
                                        if (localStorage.getItem("tpSidebarPosition") === 'left') {
                                            $('#tamkinPlayerApp #tp-accessibility-sidebar').addClass('ltr');
                                            $('#tamkinPlayerApp .accessibility-icon-container-lang').addClass('ltr');
                                            $('#tamkinPlayerApp .tp-accessibility-lang').addClass('ltr');
                                            $('#tamkinPlayerApp .accessibility-icon-container-widget1').addClass('ltr');
                                            $('#tamkinPlayerApp .tp-lang-modal-icon').css('left', 0);
                                            $('#tamkinPlayerApp .accessibility-icon-container-widget1.ltr .tp-accessibility').css('left', 0);
                                        } else {
                                            $('#tamkinPlayerApp .tp-lang-modal-icon').css('right', 0);
                                            $('#tamkinPlayerApp .accessibility-icon-container-widget1 .tp-accessibility').css('right', 0);
                                        }

                                        tamkinPlayerApp.style.setProperty('--tp-accessibility-top', '20%');
                                    } else if (desktopLocation === 'postion2') {
                                        if (localStorage.getItem("tpSidebarPosition") === 'left') {

                                            $('#tamkinPlayerApp .accessibility-icon-container-lang').addClass('ltr');
                                            $('#tamkinPlayerApp .tp-accessibility-lang').addClass('ltr');
                                            $('#tamkinPlayerApp #tp-accessibility-sidebar').addClass('ltr');
                                            $('#tamkinPlayerApp .accessibility-icon-container-widget1').addClass('ltr');
                                            $('#tamkinPlayerApp .accessibility-icon-container-lang .tp-lang-modal-icon').css('left', 0);
                                            $('#tamkinPlayerApp .accessibility-icon-container-widget1.ltr .tp-accessibility').css('left', 0);
                                        }
                                        else {
                                            $('#tamkinPlayerApp .tp-lang-modal-icon').css('right', 0);
                                            $('#tamkinPlayerApp .accessibility-icon-container-widget1 .tp-accessibility').css('right', 0);
                                        }
                                        tamkinPlayerApp.style.setProperty('--tp-accessibility-top', '50%');
                                    } else if (desktopLocation === 'postion3') {
                                        if (localStorage.getItem("tpSidebarPosition") === 'left') {
                                            $('#tamkinPlayerApp .accessibility-icon-container-lang').addClass('ltr');
                                            $('#tamkinPlayerApp .tp-accessibility-lang').addClass('ltr');
                                            $('#tamkinPlayerApp #tp-accessibility-sidebar').addClass('ltr');
                                            $('#tamkinPlayerApp .accessibility-icon-container-widget1').addClass('ltr');
                                            $('#tamkinPlayerApp .tp-lang-modal-icon').css('left', 0);
                                            $('#tamkinPlayerApp .accessibility-icon-container-widget1.ltr .tp-accessibility').css('left', 0);
                                        } else {
                                            $('#tamkinPlayerApp .tp-lang-modal-icon').css('right', 0);
                                            $('#tamkinPlayerApp .accessibility-icon-container-widget1 .tp-accessibility').css('right', 0);
                                        }
                                        tamkinPlayerApp.style.setProperty('--tp-accessibility-top', '80%');
                                    } else if (desktopLocation === 'postion4') {
                                        $('#tamkinPlayerApp .accessibility-icon-container-widget1').removeClass('ltr');
                                        $('#tamkinPlayerApp #tp-accessibility-sidebar').removeClass('ltr');
                                        $('#tamkinPlayerApp .tp-lang-modal-icon').css('right', '48%');
                                        $("#tamkinPlayerApp .accessibility-icon-container-widget1 .tp-accessibility").css('right', '48%')
                                        $("#tamkinPlayerApp .accessibility-icon-container-widget1 .tp-accessibility-text").css('display', 'none')
                                        tamkinPlayerApp.style.setProperty('--tp-accessibility-top', '20%');
                                    } else if (desktopLocation === 'postion5') {
                                        $('#tamkinPlayerApp .accessibility-icon-container-widget1').removeClass('ltr');
                                        $('#tamkinPlayerApp #tp-accessibility-sidebar').removeClass('ltr');
                                        $('#tamkinPlayerApp .tp-lang-modal-icon').css('right', '48%');
                                        $("#tamkinPlayerApp .accessibility-icon-container-widget1 .tp-accessibility").css('right', '48%')
                                        $("#tamkinPlayerApp .accessibility-icon-container-widget1 .tp-accessibility-text").css('display', 'none')
                                        tamkinPlayerApp.style.setProperty('--tp-accessibility-top', '80%');
                                    } else if (desktopLocation === 'postion6') {
                                        if (localStorage.getItem("tpSidebarPosition") === 'right') {
                                            $('#tamkinPlayerApp .accessibility-icon-container-widget1').removeClass('ltr');
                                            $('#tamkinPlayerApp #tp-accessibility-sidebar').removeClass('ltr');
                                            $('#tamkinPlayerApp .tp-lang-modal-icon').css('right', 0);
                                            $('#tamkinPlayerApp .accessibility-icon-container-widget1 .tp-accessibility').css('right', 0);
                                        } else {
                                            $('#tamkinPlayerApp .accessibility-icon-container-lang').addClass('ltr');
                                            $('#tamkinPlayerApp .tp-accessibility-lang').addClass('ltr');
                                            $('#tamkinPlayerApp #tp-accessibility-sidebar').addClass('ltr');
                                            $('#tamkinPlayerApp .accessibility-icon-container-widget1').addClass('ltr');
                                            $('#tamkinPlayerApp .tp-lang-modal-icon').css('left', 0);
                                            $('#tamkinPlayerApp .accessibility-icon-container-widget1.ltr .tp-accessibility').css('left', 0);
                                        }
                                        tamkinPlayerApp.style.setProperty('--tp-accessibility-top', '20%');
                                    } else if (desktopLocation === 'postion7') {
                                        if (localStorage.getItem("tpSidebarPosition") === 'right') {
                                            $('#tamkinPlayerApp .accessibility-icon-container-lang').removeClass('ltr');
                                            $('#tamkinPlayerApp .tp-accessibility-lang').removeClass('ltr');
                                            $('#tamkinPlayerApp .accessibility-icon-container-widget1').removeClass('ltr');
                                            $('#tamkinPlayerApp #tp-accessibility-sidebar').removeClass('ltr');
                                            $('#tamkinPlayerApp .tp-lang-modal-icon').css('right', 0);
                                            $('#tamkinPlayerApp .accessibility-icon-container-widget1 .tp-accessibility').css('right', 0);
                                        } else {
                                            $('#tamkinPlayerApp .accessibility-icon-container-lang').addClass('ltr');
                                            $('#tamkinPlayerApp .tp-accessibility-lang').addClass('ltr');
                                            $('#tamkinPlayerApp #tp-accessibility-sidebar').addClass('ltr');
                                            $('#tamkinPlayerApp .accessibility-icon-container-widget1').addClass('ltr');
                                            $('#tamkinPlayerApp .tp-lang-modal-icon').css('left', 0);
                                            $('#tamkinPlayerApp .accessibility-icon-container-widget1.ltr .tp-accessibility').css('left', 0);
                                        }
                                        tamkinPlayerApp.style.setProperty('--tp-accessibility-top', '50%');
                                    } else if (desktopLocation === 'postion8') {
                                        if (localStorage.getItem("tpSidebarPosition") === 'right') {
                                            $('#tamkinPlayerApp .accessibility-icon-container-lang').removeClass('ltr');
                                            $('#tamkinPlayerApp .tp-accessibility-lang').removeClass('ltr');
                                            $('#tamkinPlayerApp .accessibility-icon-container-widget1').removeClass('ltr');
                                            $('#tamkinPlayerApp #tp-accessibility-sidebar').removeClass('ltr');
                                            $('#tamkinPlayerApp .tp-lang-modal-icon').css('right', 0);
                                            $('#tamkinPlayerApp .accessibility-icon-container-widget1 .tp-accessibility').css('right', 0);
                                        } else {
                                            $('#tamkinPlayerApp .accessibility-icon-container-lang').addClass('ltr');
                                            $('#tamkinPlayerApp .tp-accessibility-lang').addClass('ltr');
                                            $('#tamkinPlayerApp #tp-accessibility-sidebar').addClass('ltr');
                                            $('#tamkinPlayerApp .accessibility-icon-container-widget1').addClass('ltr');
                                            $('#tamkinPlayerApp .tp-lang-modal-icon').css('left', 0);
                                            $('#tamkinPlayerApp .accessibility-icon-container-widget1.ltr .tp-accessibility').css('left', 0);
                                        }
                                        tamkinPlayerApp.style.setProperty('--tp-accessibility-top', '80%');
                                    }
                                }
                            } 
                            else {
                                    const obj = $this.state.enabledFeatures.
                                        find(feature => feature.name === "acc-customize-button-location")
                                        ?.features.find(el => el.name == "acc-customize-button-location-button-location-mobile");
                                    const mobileLocation = obj.value;

                                    const tamkinPlayerApp = document.getElementById('tamkinPlayerApp');
                                    if (obj.active == 1) {
                                        if (mobileLocation === 'postion1') {
                                            if (localStorage.getItem("tpSidebarPosition") === 'left') {
                                                $('#tamkinPlayerApp .accessibility-icon-container-lang').addClass('ltr');
                                                $('#tamkinPlayerApp .tp-accessibility-lang').addClass('ltr');
                                                $('#tamkinPlayerApp #tp-accessibility-sidebar').addClass('ltr');
                                                $('#tamkinPlayerApp .accessibility-icon-container-widget1').addClass('ltr');
                                                $('#tamkinPlayerApp .tp-lang-modal-icon').css('left', 0);
                                                $('#tamkinPlayerApp .accessibility-icon-container-widget1.ltr .tp-accessibility').css('left', 0);
                                            } else {
                                                $('#tamkinPlayerApp .tp-lang-modal-icon').css('right', 0);
                                                $('#tamkinPlayerApp .accessibility-icon-container-widget1 .tp-accessibility').css('right', 0);
                                            }
                                            tamkinPlayerApp.style.setProperty('--tp-accessibility-top', '20%');
                                        } else if (mobileLocation === 'postion2') {
                                            if (localStorage.getItem("tpSidebarPosition") === 'left') {
                                                $('#tamkinPlayerApp .accessibility-icon-container-lang').addClass('ltr');
                                                $('#tamkinPlayerApp .tp-accessibility-lang').addClass('ltr');
                                                $('#tamkinPlayerApp #tp-accessibility-sidebar').addClass('ltr');
                                                $('#tamkinPlayerApp .accessibility-icon-container-widget1').addClass('ltr');
                                                $('#tamkinPlayerApp .tp-lang-modal-icon').css('left', 0);
                                                $('#tamkinPlayerApp .accessibility-icon-container-widget1.ltr .tp-accessibility').css('left', 0);
                                            } else {
                                                $('#tamkinPlayerApp .tp-lang-modal-icon').css('right', 0);
                                                $('#tamkinPlayerApp .accessibility-icon-container-widget1 .tp-accessibility').css('right', 0);
                                            }
                                            tamkinPlayerApp.style.setProperty('--tp-accessibility-top', '50%');
                                        } else if (mobileLocation === 'postion3') {
                                            if (localStorage.getItem("tpSidebarPosition") === 'left') {
                                                $('#tamkinPlayerApp .accessibility-icon-container-lang').addClass('ltr');
                                                $('#tamkinPlayerApp .tp-accessibility-lang').addClass('ltr');
                                                $('#tamkinPlayerApp #tp-accessibility-sidebar').addClass('ltr');
                                                $('#tamkinPlayerApp .accessibility-icon-container-widget1').addClass('ltr');
                                                $('#tamkinPlayerApp .tp-lang-modal-icon').css('left', 0);
                                                $('#tamkinPlayerApp .accessibility-icon-container-widget1.ltr .tp-accessibility').css('left', 0);
                                            } else {
                                                $('#tamkinPlayerApp .tp-lang-modal-icon').css('right', 0);
                                                $('#tamkinPlayerApp .accessibility-icon-container-widget1 .tp-accessibility').css('right', 0);
                                            }
                                            tamkinPlayerApp.style.setProperty('--tp-accessibility-top', '80%');
                                        } else if (mobileLocation === 'postion4') {
                                            if (localStorage.getItem("tpSidebarPosition") === 'right') {
                                                $('#tamkinPlayerApp .accessibility-icon-container-widget1').removeClass('ltr');
                                                $('#tamkinPlayerApp #tp-accessibility-sidebar').removeClass('ltr');
                                                $('#tamkinPlayerApp .tp-lang-modal-icon').css('right', 0);
                                                $('#tamkinPlayerApp .accessibility-icon-container-widget1 .tp-accessibility').css('right', 0);
                                            } else {
                                                $('#tamkinPlayerApp .accessibility-icon-container-lang').addClass('ltr');
                                                $('#tamkinPlayerApp .tp-accessibility-lang').addClass('ltr');
                                                $('#tamkinPlayerApp #tp-accessibility-sidebar').addClass('ltr');
                                                $('#tamkinPlayerApp .accessibility-icon-container-widget1').addClass('ltr');
                                                $('#tamkinPlayerApp .tp-lang-modal-icon').css('left', 0);
                                                $('#tamkinPlayerApp .accessibility-icon-container-widget1.ltr .tp-accessibility').css('left', 0);
                                            }
                                            tamkinPlayerApp.style.setProperty('--tp-accessibility-top', '20%');
                                        } else if (mobileLocation === 'postion5') {
                                            if (localStorage.getItem("tpSidebarPosition") === 'right') {
                                                $('#tamkinPlayerApp .accessibility-icon-container-widget1').removeClass('ltr');
                                                $('#tamkinPlayerApp #tp-accessibility-sidebar').removeClass('ltr');
                                                $('#tamkinPlayerApp .tp-lang-modal-icon').css('right', 0);
                                                $('#tamkinPlayerApp .accessibility-icon-container-widget1 .tp-accessibility').css('right', 0);
                                            } else {
                                                $('#tamkinPlayerApp .accessibility-icon-container-lang').addClass('ltr');
                                                $('#tamkinPlayerApp .tp-accessibility-lang').addClass('ltr');
                                                $('#tamkinPlayerApp #tp-accessibility-sidebar').addClass('ltr');
                                                $('#tamkinPlayerApp .accessibility-icon-container-widget1').addClass('ltr');
                                                $('#tamkinPlayerApp .tp-lang-modal-icon').css('left', 0);
                                                $('#tamkinPlayerApp .accessibility-icon-container-widget1.ltr .tp-accessibility').css('left', 0);
                                            }
                                            tamkinPlayerApp.style.setProperty('--tp-accessibility-top', '50%');
                                        } else if (mobileLocation === 'postion6') {
                                            if (localStorage.getItem("tpSidebarPosition") === 'right') {
                                                $('#tamkinPlayerApp .accessibility-icon-container-widget1').removeClass('ltr');
                                                $('#tamkinPlayerApp #tp-accessibility-sidebar').removeClass('ltr');
                                                $('#tamkinPlayerApp .tp-lang-modal-icon').css('right', 0);
                                                $('#tamkinPlayerApp .accessibility-icon-container-widget1 .tp-accessibility').css('right', 0);
                                            } else {
                                                $('#tamkinPlayerApp .accessibility-icon-container-lang').addClass('ltr');
                                                $('#tamkinPlayerApp .tp-accessibility-lang').addClass('ltr');
                                                $('#tamkinPlayerApp #tp-accessibility-sidebar').addClass('ltr');
                                                $('#tamkinPlayerApp .accessibility-icon-container-widget1').addClass('ltr');
                                                $('#tamkinPlayerApp .tp-lang-modal-icon').css('left', 0);
                                                $('#tamkinPlayerApp .accessibility-icon-container-widget1.ltr .tp-accessibility').css('left', 0);
                                            }
                                            tamkinPlayerApp.style.setProperty('--tp-accessibility-top', '80%');
                                        }
                                    }
                                }

                            // sound effect 
                            const enableSoundEffect = data.data.features.find(feature => feature.name === 'acc-setting-general-settings')?.features
                                .find(el => el.name === 'acc-setting-general-settings-sound-effects');
                            if (enableSoundEffect.active == 1 && enableSoundEffect.value == 1) {
                                localStorage.removeItem("enableSoundEffect")
                                localStorage.setItem('enableSoundEffect', 1)
                            } else {
                                localStorage.removeItem("enableSoundEffect")
                            }
                           

                            // handle tamkin player 
                            // button shape 
                            if (window.innerWidth > 500) {
                                // enable player or not
                                const desktopPlayerSetting = data.data.features.find(feature => feature.name === 'deaf-setting-general-settings')?.features
                                    .find(el => el.name === 'deaf-setting-general-settings-player-enabled-on-this-site');
                                if (desktopPlayerSetting?.active == 1 && desktopPlayerSetting?.value == 1) {
                                    this.update({ enablePlayer: true })
                                }
                                this.update();
                            } else {
                                // enable player or not
                                const mobilePlayerSetting = data.data.features.find(feature => feature.name === 'deaf-setting-general-settings')?.features
                                    .find(el => el.name === 'deaf-setting-general-settings-player-enabled-on-this-mobile');
                                if (mobilePlayerSetting?.active == 1 && mobilePlayerSetting?.value == 1) {
                                    this.update({ enablePlayer: true })
                                }
                            }
                            this.makePlayerOn();
                            document.getElementById('image_pricipal').src = this.loadFile(data.data.features.find(feature => feature.name === 'deaf-customize-button-type').features.
                                find(feature => feature.name === "deaf-customize-button-type-sign-language-button-shape").tamkin_option_item_values
                                .find(el => el.value === data.data.features.find(feature => feature.name === 'deaf-customize-button-type').features.
                                    find(feature => feature.name === "deaf-customize-button-type-sign-language-button-shape").value).icon);
                            // set colors 
                            const playercolor1 = data.data.features.find(feature => feature.name === 'deaf-customize-button-color')?.features.
                                find(el => el.name === "deaf-customize-button-color-sign-language-button-color").value.split(',')[0];
                            const playercolor2 = data.data.features.find(feature => feature.name === 'deaf-customize-button-color')?.features.
                                find(el => el.name === "deaf-customize-button-color-sign-language-button-color").value.split(',')[1];
                            tamkinPlayerApp.style.setProperty('--tp-player-primary', playercolor1);

                            if (playercolor2) {
                                if (playercolor1 === playercolor2) {
                                    let lighterColor = tinycolor(playercolor1).setAlpha(0.2).toString();
                                    tamkinPlayerApp.style.setProperty('--tp-player-primary-grad', lighterColor);
                                    this.update({ enablePlayerColor2: true })
                                } else {
                                    tamkinPlayerApp.style.setProperty('--tp-player-primary-grad', playercolor2);
                                    this.update({ enablePlayerColor2: true })
                                }
                            } else {
                                let lighterColor = tinycolor(playercolor1).setAlpha(0.2).toString();
                                tamkinPlayerApp.style.setProperty('--tp-player-primary-grad', lighterColor);
                            }

                            this.update();
                            // hadle size of img icon player 
                            const playersize = data.data.features.find(feature => feature.name === 'deaf-customize-button-type')?.features
                                .find(el => el.name == 'deaf-customize-button-type-sign-language-player-button-size').value;

                            tamkinPlayerApp.style.setProperty('--tp-player-icon-width', `${playersize}px`);
                            tamkinPlayerApp.style.setProperty('--tp-player-icon-height', `${playersize}px`);
                            this.update();

                            // sound effect in player 
                            const enableSoundEffectInPlayer = data.data.features.find(feature => feature.name === 'deaf-setting-general-settings')?.features
                                .find(el => el.name === 'deaf-setting-general-settings-player-sound-effects');
                            if (enableSoundEffectInPlayer.active == 1 && enableSoundEffectInPlayer.value == 1) {
                                localStorage.removeItem("enableSoundEffectInPlayer")
                                localStorage.setItem('enableSoundEffectInPlayer', 1)
                            } else {
                                localStorage.removeItem("enableSoundEffectInPlayer")
                            }

                            const checkplayerHighlight = data.data.features.find(feature => feature.name === "deaf-customize-sign-language-player-language")?.features
                                .find(el => el.name === "deaf-customize-sign-language-player-language-sign-language-show-language-selector-on-the-widget");

                            if (checkplayerHighlight.value == 1 && checkplayerHighlight.active == 1) {
                                this.state.playerHighlight = true;
                                this.update()
                            }

                            // handle mode
                            const playerValueMode = data.data.features.find(feature => feature.name === 'deaf-customize-sign-language-mode')?.features
                                .find(el => el.name == 'deaf-customize-sign-language-mode-move-/-hide-sign-language-player-button')
                            this.update({
                                enablePosition: playerValueMode.active == 1 && playerValueMode.value == 1 ? true : false
                            })
                            const playerMode = data.data.features.find(feature => feature.name === 'deaf-customize-sign-language-mode')?.features
                                .find(el => el.name == 'deaf-customize-sign-language-mode-move-/-hide-sign-language-player').value;

                            this.state.playerModeIconRight = data.data.features.find(feature => feature.name === 'deaf-customize-sign-language-mode')?.features
                                .find(el => el.name == 'deaf-customize-sign-language-mode-move-/-hide-sign-language-player').tamkin_option_item_values.find(el => el.value === 'right').icon

                            this.state.playerModeIconLeft = data.data.features.find(feature => feature.name === 'deaf-customize-sign-language-mode')?.features
                                .find(el => el.name == 'deaf-customize-sign-language-mode-move-/-hide-sign-language-player').tamkin_option_item_values.find(el => el.value === 'left').icon

                            if (playerMode === 'hide') {
                                this.update({ enablePlayer: false })
                            } else {
                                if (localStorage.getItem("player_position")) {
                                    if (localStorage.getItem("player_position") === 'left') {
                                        $("#player-card").addClass("left");
                                        $(".tp-hero-talk").addClass("left");
                                        $(".tp-hero-talk-text").addClass("left");
                                    } else {
                                        $("#player-card").removeClass("left");
                                        $(".tp-hero-talk").removeClass("left");
                                        $(".tp-hero-talk-text").removeClass("left");
                                    }
                                } else {
                                    if (playerMode === 'left') {
                                        $("#player-card").addClass("left");
                                        $(".tp-hero-talk").addClass("left");
                                        $(".tp-hero-talk-text").addClass("left");

                                        if (!localStorage.getItem("player_position")) {
                                            localStorage.setItem("player_position", "left");
                                        }
                                    } else {
                                        if (!localStorage.getItem("player_position")) {
                                            localStorage.setItem("player_position", "right");
                                        }
                                    }
                                }
                            }
                            
                            // set player Background 
                            const isBackgroundActive = data.data.features.find(feature => feature.name === 'deaf-customize-sign-language-player-background')?.features
                            .find(el => el.name == 'deaf-customize-sign-language-background-sign-language-background');


                            this.update({
                                enableBackground: isBackgroundActive.active == 1 && isBackgroundActive.value == 1 ? true : false
                            });
                            
                            this.update({
                                playerBackground: data.data.features.find(feature => feature.name === 'deaf-customize-sign-language-player-background')?.features
                                    .find(el => el.name == "deaf-customize-sign-language-player-background-sign-language-background-options").value
                            });

                            this.update({
                                playerBackgroundIcon: data.data.features.find(feature => feature.name === 'deaf-customize-sign-language-player-background')?.features
                                    .find(el => el.name == 'deaf-customize-sign-language-background-sign-language-background').icon
                            });

                            this.update({
                                enableKeyboard: data.data.features.find(feature => feature.name === "deaf-customize-sign-language-player-keyboard")?.features
                                    .find(el => el.name == "deaf-customize-sign-language-player-keyboard-sign-language-keyboard").active == 1 ? true : false
                            });

                            // set player contrast 
                            const isContrastActive = data.data.features.find(feature => feature.name === 'deaf-customize-sign-language-player-contrast')?.features
                            .find(el => el.name == 'deaf-customize-sign-language-player-contrast-sign-language-contrast');
                            this.update({
                                enableContrast: isContrastActive.active == 1 && isContrastActive.value == 1 ? true : false
                            })
                            
                            this.update({
                                playerContrast: data.data.features.find(feature => feature.name === 'deaf-customize-sign-language-player-contrast')?.features
                                    .find(el => el.name == "deaf-customize-sign-language-player-contrast-sign-language-contrast-option").value
                            })

                            if (localStorage.getItem('playerContrast')) {
                                if (localStorage.getItem('playerContrast') == 'dark') {
                                    this.update({
                                        playerContrastIconInit: data.data.features.find(feature => feature.name === 'deaf-customize-sign-language-player-contrast')?.features
                                            .find(el => el.name == "deaf-customize-sign-language-player-contrast-sign-language-contrast-option").tamkin_option_item_values.find((el) => el.value == 'dark').icon
                                    });
                                    this.update({
                                        playerContrastIconReplaced: data.data.features.find(feature => feature.name === 'deaf-customize-sign-language-player-contrast')?.features
                                            .find(el => el.name == "deaf-customize-sign-language-player-contrast-sign-language-contrast-option").tamkin_option_item_values.find((el) => el.value == 'light').icon
                                    });
                                } else {
                                    this.update({
                                        playerContrastIconInit: data.data.features.find(feature => feature.name === 'deaf-customize-sign-language-player-contrast')?.features
                                            .find(el => el.name == "deaf-customize-sign-language-player-contrast-sign-language-contrast-option").tamkin_option_item_values.find((el) => el.value == 'light').icon
                                    });
                                    this.update({
                                        playerContrastIconReplaced: data.data.features.find(feature => feature.name === 'deaf-customize-sign-language-player-contrast')?.features
                                            .find(el => el.name == "deaf-customize-sign-language-player-contrast-sign-language-contrast-option").tamkin_option_item_values.find((el) => el.value == 'dark').icon
                                    });
                                }

                            } else {
                                const initIcon = data.data.features.find(feature => feature.name === 'deaf-customize-sign-language-player-contrast')?.features
                                    .find(el => el.name == "deaf-customize-sign-language-player-contrast-sign-language-contrast-option").tamkin_option_item_values.find((el) => el.value == this.state.playerContrast)
                                this.update({
                                    playerContrastIconInit: initIcon ? initIcon.icon : data.data.features.find(feature => feature.name === 'deaf-customize-sign-language-player-contrast')?.features
                                        .find(el => el.name == "deaf-customize-sign-language-player-contrast-sign-language-contrast-option").tamkin_option_item_values[0].icon
                                });

                                this.update({
                                    playerContrastIconReplaced: data.data.features.find(feature => feature.name === 'deaf-customize-sign-language-player-contrast')?.features
                                        .find(el => el.name == "deaf-customize-sign-language-player-contrast-sign-language-contrast-option").tamkin_option_item_values.find(el => el.icon !== this.state.playerContrastIconInit).icon
                                });
                            }

                            const getPlayerLang = data.data.features.find(feature => feature.name === 'deaf-customize-sign-language-player-language')?.features
                                .find(el => el.name == 'deaf-customize-sign-language-list-list-sign-language').value;

                            if (getPlayerLang === 'auto detect language') {
                                fetch('https://api.tamkin.app/v1/api/Widget/LanguageDetector', {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                    },
                                    body: JSON.stringify({
                                        text: document.title || document.querySelector('meta[name="description"]')?.content ||
                                            document.querySelector('h1')?.textContent || document.querySelector('h2')?.textContent ||
                                            document.querySelector('div')?.textContent
                                    })
                                })
                                    .then(response => {
                                        if (!response.ok) {
                                            throw new Error(`HTTP error! Status: ${response.status}`);
                                        }
                                        return response.json();
                                    })
                                    .then(data => {
                                        if (data.data) {
                                            localStorage.setItem("player_lang", data.data || 'en')
                                        }
                                    })
                                    .catch(error => {
                                        console.error('Fetch error:', error);
                                    });
                            } else {
                                localStorage.setItem("player_lang", getPlayerLang || 'en')
                            }

                            // widget location 
                          
                            if (window.innerWidth > 500) {
                                const obj = data.data.features.
                                    find(feature => feature.name === "deaf-customize-button-location")
                                    ?.features.find(el => el.name == "deaf-customize-button-location-sign-language-button-location-desktop");

                                const desktopLocation = obj.value
                                const tamkinPlayerApp = document.getElementById('tamkinPlayerApp');
                                if (obj.active == 1) {
                                    if (desktopLocation === 'postion1') {
                                        if (localStorage.getItem("player_position") === 'left') {
                                            $("#player-card").addClass("left");
                                            $(".tp-hero-talk").addClass("left");
                                            $(".tp-hero-talk-text").addClass("left");
                                        } else {
                                            $("#player-card").removeClass("left");
                                            $(".tp-hero-talk").removeClass("left");
                                            $(".tp-hero-talk-text").removeClass("left");
                                        }
                                        tamkinPlayerApp.style.setProperty('--tp-player-icon-top', '30%');
                                    } else if (desktopLocation === 'postion2') {
                                        if (localStorage.getItem("player_position") === 'left') {
                                            $("#player-card").addClass("left");
                                            $(".tp-hero-talk").addClass("left");
                                            $(".tp-hero-talk-text").addClass("left");
                                        } else {
                                            $("#player-card").removeClass("left");
                                            $(".tp-hero-talk").removeClass("left");
                                            $(".tp-hero-talk-text").removeClass("left");
                                        }
                                        tamkinPlayerApp.style.setProperty('--tp-player-icon-top', '60%');
                                    } else if (desktopLocation === 'postion3') {
                                        if (localStorage.getItem("player_position") === 'left') {
                                            $("#player-card").addClass("left");
                                            $(".tp-hero-talk").addClass("left");
                                            $(".tp-hero-talk-text").addClass("left");
                                        } else {
                                            $("#player-card").removeClass("left");
                                            $(".tp-hero-talk").removeClass("left");
                                            $(".tp-hero-talk-text").removeClass("left");
                                        }
                                        tamkinPlayerApp.style.setProperty('--tp-player-icon-top', '90%');
                                    } else if (desktopLocation === 'postion4') {
                                        $("#player-card").removeClass("left");
                                        $(".tp-hero-talk").removeClass("left");
                                        $(".tp-hero-talk-text").removeClass("left");
                                        $("#tamkinPlayerApp  .tp-hero-talk").css('right', '48%')
                                        $("#tamkinPlayerApp .tp-hero-talk-text").css('display', 'none')
                                        tamkinPlayerApp.style.setProperty('--tp-player-icon-top', '30%');
                                    } else if (desktopLocation === 'postion5') {
                                        $("#player-card").removeClass("left");
                                        $(".tp-hero-talk").removeClass("left");
                                        $(".tp-hero-talk-text").removeClass("left");
                                        $("#tamkinPlayerApp  .tp-hero-talk").css('right', '48%')
                                        $("#tamkinPlayerApp .tp-hero-talk-text").css('display', 'none')
                                        tamkinPlayerApp.style.setProperty('--tp-player-icon-top', '90%');
                                    } else if (desktopLocation === 'postion6') {
                                        if (localStorage.getItem("player_position") === 'right') {
                                            $("#player-card").removeClass("left");
                                            $(".tp-hero-talk").removeClass("left");
                                            $(".tp-hero-talk-text").removeClass("left");
                                        } else {
                                            $("#player-card").addClass("left");
                                            $(".tp-hero-talk").addClass("left");
                                            $(".tp-hero-talk-text").addClass("left");
                                        }
                                        tamkinPlayerApp.style.setProperty('--tp-player-icon-top', '30%');
                                    } else if (desktopLocation === 'postion7') {
                                        if (localStorage.getItem("player_position") === 'right') {
                                            $("#player-card").removeClass("left");
                                            $(".tp-hero-talk").removeClass("left");
                                            $(".tp-hero-talk-text").removeClass("left");
                                        } else {
                                            $("#player-card").addClass("left");
                                            $(".tp-hero-talk").addClass("left");
                                            $(".tp-hero-talk-text").addClass("left");
                                        }
                                        tamkinPlayerApp.style.setProperty('--tp-player-icon-top', '60%');
                                    } else if (desktopLocation === 'postion8') {
                                        if (localStorage.getItem("player_position") === 'right') {
                                            $("#player-card").removeClass("left");
                                            $(".tp-hero-talk").removeClass("left");
                                            $(".tp-hero-talk-text").removeClass("left");
                                        } else {
                                            $("#player-card").addClass("left");
                                            $(".tp-hero-talk").addClass("left");
                                            $(".tp-hero-talk-text").addClass("left");
                                        }
                                        tamkinPlayerApp.style.setProperty('--tp-player-icon-top', '90%');
                                    }
                                }

                            } else {
                                const obj = data.data.features.
                                    find(feature => feature.name === "deaf-customize-button-location")
                                    ?.features.find(el => el.name == "deaf-customize-button-location-sign-language-button-location-mobile");
                                const mobileLocation = obj.value;

                                const tamkinPlayerApp = document.getElementById('tamkinPlayerApp');
                                if (obj.active == 1) {
                                    if (mobileLocation === 'postion1') {
                                        if (localStorage.getItem("player_position") === 'left') {
                                            $("#player-card").addClass("left");
                                            $(".tp-hero-talk").addClass("left");
                                            $(".tp-hero-talk-text").addClass("left");
                                        } else {
                                            $("#player-card").removeClass("left");
                                            $(".tp-hero-talk").removeClass("left");
                                            $(".tp-hero-talk-text").removeClass("left");
                                        }
                                        tamkinPlayerApp.style.setProperty('--tp-player-icon-top', '30%');
                                    } else if (mobileLocation === 'postion2') {
                                        if (localStorage.getItem("player_position") === 'left') {
                                            $("#player-card").addClass("left");
                                            $(".tp-hero-talk").addClass("left");
                                            $(".tp-hero-talk-text").addClass("left");
                                        } else {
                                            $("#player-card").removeClass("left");
                                            $(".tp-hero-talk").removeClass("left");
                                            $(".tp-hero-talk-text").removeClass("left");
                                        }
                                        tamkinPlayerApp.style.setProperty('--tp-player-icon-top', '60%');
                                    } else if (mobileLocation === 'postion3') {
                                        if (localStorage.getItem("player_position") === 'left') {
                                            $("#player-card").addClass("left");
                                            $(".tp-hero-talk").addClass("left");
                                            $(".tp-hero-talk-text").addClass("left");
                                        } else {
                                            $("#player-card").removeClass("left");
                                            $(".tp-hero-talk").removeClass("left");
                                            $(".tp-hero-talk-text").removeClass("left");
                                        }
                                        tamkinPlayerApp.style.setProperty('--tp-player-icon-top', '90%');
                                    } else if (mobileLocation === 'postion4') {
                                        if (localStorage.getItem("player_position") === 'right') {
                                            $("#player-card").removeClass("left");
                                            $(".tp-hero-talk").removeClass("left");
                                            $(".tp-hero-talk-text").removeClass("left");
                                        } else {
                                            $("#player-card").addClass("left");
                                            $(".tp-hero-talk").addClass("left");
                                            $(".tp-hero-talk-text").addClass("left");
                                        }
                                        tamkinPlayerApp.style.setProperty('--tp-player-icon-top', '30%');
                                    } else if (mobileLocation === 'postion5') {
                                        if (localStorage.getItem("player_position") === 'right') {
                                            $("#player-card").removeClass("left");
                                            $(".tp-hero-talk").removeClass("left");
                                            $(".tp-hero-talk-text").removeClass("left");
                                        } else {
                                            $("#player-card").addClass("left");
                                            $(".tp-hero-talk").addClass("left");
                                            $(".tp-hero-talk-text").addClass("left");
                                        }
                                        tamkinPlayerApp.style.setProperty('--tp-player-icon-top', '60%');
                                    } else if (mobileLocation === 'postion6') {
                                        if (localStorage.getItem("player_position") === 'right') {
                                            $("#player-card").removeClass("left");
                                            $(".tp-hero-talk").removeClass("left");
                                            $(".tp-hero-talk-text").removeClass("left");
                                        } else {
                                            $("#player-card").addClass("left");
                                            $(".tp-hero-talk").addClass("left");
                                            $(".tp-hero-talk-text").addClass("left");
                                        }
                                        tamkinPlayerApp.style.setProperty('--tp-player-icon-top', '90%');
                                    }
                                }
                            }

                            this.update()

                            

                        })
                        .catch(error => {
                        });
                } catch (error) {
                    console.log("error", error);

                }
            },

            hexToRgb(hex) {
                const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
                hex = hex.replace(shorthandRegex, (m, r, g, b) => {
                    return r + r + g + g + b + b;
                });

                const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
                return result
                    ? [
                        parseInt(result[1], 16),
                        parseInt(result[2], 16),
                        parseInt(result[3], 16),
                    ]
                    : null;
            },

            applyFilters() {                
                const hex = document.getElementById('tamkinPlayerApp').style.getPropertyValue('--tp-player-primary');
                const rgb = this.hexToRgb(hex);
                
                let fin;
                if (rgb && rgb.length === 3) {
                    const color = new Color(rgb[0], rgb[1], rgb[2]);
                    const solver = new Solver(color);
                    const result = solver.solve();
                    
                    fin = result.filter;
                }
                return fin;
            },

            makeGradiant() {
                const selectors = [
                    ".iconcodelang",
                    ".tp-accessibility-lang-header",
                    ".tp-accessibility-sidebar-header",
                    ".tp-accessibility2-sidebar-header",
                    ".tp-accessibility3-sidebar-header",
                    ".worldbg",
                    ".tp-accessibility-lang-close:hover",
                    ".pagination button.active",
                    "#tamkinPlayerApp .accessibility-icon-container-widget1 .tp-accessibility",
                    "#tamkinPlayerApp .accessibility-icon-container-widget1 .tp-accessibility-text",
                    ".iconCode",
                    ".iconWorld",
                    "#tamkinPlayerApp .accessibility-icon-container .tp-accessibility2",
                    "#tamkinPlayerApp .accessibility-icon-container .tp-accessibility2-text",
                    "#tamkinPlayerApp .tp-accessibility2-sidebar-close:hover",
                    "#tamkinPlayerApp .accessibility-icon-container .tp-accessibility3",
                    "#tamkinPlayerApp .accessibility-icon-container .tp-accessibility3-text",
                    "#tamkinPlayerApp .tp-accessibility3-sidebar-close:hover",
                    "#tamkinPlayerApp .accessibility-icon-container .tp-accessibility4",
                    "#tamkinPlayerApp .accessibility-icon-container .tp-accessibility4-text",
                    "#tamkinPlayerApp .tp-accessibility4-sidebar-close:hover"
                ];

                selectors.forEach(selector => {
                    const element = document.querySelector(selector);
                    if (element) {
                        element.style.background = "linear-gradient(180deg, var(--tp-primary) 0%, var(--tp-primary-grad) 100%) !important";
                    }
                });
            },
            alert(e) {
                alert(1)
            },

            async getPlayerCharacterType() {
                await fetch('https://api.tamkin.app/v1/api/Widget/GetPlayer', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error(`HTTP error! Status: ${response.status}`);
                        }
                        return response.json();
                    })
                    .then(data => {
                        if (data.data) {
                            console.log("data.data.charachter", data.data.charachter);
                            this.state.charachterType = data.data.charachter
                        } 
                    })
                    .catch(error => {
                        console.error('Fetch error:', error);
                    });
            },

            async getPlayerSettings() {
                this.update({loading_settings : true})
                const lang = localStorage.getItem('initialLang')
                fetch(`https://api.tamkin.app/v1/api/Widget/playerSettings?lang=${lang}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error(`HTTP error! Status: ${response.status}`);
                        }
                        return response.json();
                    })
                    .then(data => {
                        this.update({loading_settings : false})
                        this.state.player_settings = data.data;
                        this.update({ player_settings: data.data });
                    })
                    .catch(error => {
                        this.update({loading_settings : false})
                        console.error("Error fetching player settings:", error);
                    });
            },

            makePlayerOn() {
                $(".tp-hero-talk").on("click", window.initiatePlayer);
                $(".tp-hero-talk").hover(
                    function () {
                        $(".tp-hero-talk-text").addClass('active');
                    }, function () {
                        $(".tp-hero-talk-text").removeClass('active');
                    }
                );

                if (localStorage.getItem('tamkinPlayerOpen')) {
                    $(".tp-hero-talk").trigger("click"); // or initiatePlayer()ك
                }
            }
        }
    </script>
    <style scoped>
        #tamkinPlayerApp button,#tamkinPlayerApp .btn ,#tamkinPlayerApp a{
            min-width: auto !important;
        }
        #tamkinPlayerApp .btn::before,
        #tamkinPlayerApp button::before,
        #tamkinPlayerApp a::before {
            background-color: transparent !important;
        }

        #tamkinPlayerApp .btn:focus,
        #tamkinPlayerApp button:focus,
        #tamkinPlayerApp a:focus {
            box-shadow: none !important;
        }

        /* accessibility */
        /* :host #collapseLang .list-group-item.active::before {
            background-image: url('./images/check.svg');
        } */
        /* main.css code required for player */
    </style>

</main-component>