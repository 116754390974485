


import * as riot from "riot";


import characterRiot from "./src/character.riot";
riot.register("tamkin-sdk-web-character", characterRiot);
riot.mount("tamkin-sdk-web-character");

//import define from "@riotjs/custom-elements";

//define("tamkin-sdk-web-character", character);

// //
export  const character = characterRiot;
export  const character2 = characterRiot;
